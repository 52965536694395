<template>
  <!--eslint-disable-next-line-->
  <path d="M15.5,0a15.07,15.07,0,0,1,7.75,2.09,15.83,15.83,0,0,1,5.66,5.66A15.07,15.07,0,0,1,31,15.5a15.07,15.07,0,0,1-2.09,7.75,15.83,15.83,0,0,1-5.66,5.66A15.07,15.07,0,0,1,15.5,31a15.07,15.07,0,0,1-7.75-2.09,15.83,15.83,0,0,1-5.66-5.66A15.07,15.07,0,0,1,0,15.5,15.07,15.07,0,0,1,2.09,7.75,15.83,15.83,0,0,1,7.75,2.09,15.07,15.07,0,0,1,15.5,0ZM29,15.5a13.14,13.14,0,0,0-1.81-6.78,13.52,13.52,0,0,0-4.94-4.91A13.24,13.24,0,0,0,15.47,2,13.08,13.08,0,0,0,8.72,3.81,13.52,13.52,0,0,0,3.81,8.75,13.24,13.24,0,0,0,2,15.53a13.08,13.08,0,0,0,1.81,6.75,13.52,13.52,0,0,0,4.94,4.91A13.24,13.24,0,0,0,15.53,29a13.08,13.08,0,0,0,6.75-1.81,13.52,13.52,0,0,0,4.91-4.94A13.19,13.19,0,0,0,29,15.5ZM19.69,21a.79.79,0,0,0,.56.16.71.71,0,0,0,.5-.28l.5-.76a.7.7,0,0,0,.16-.53.69.69,0,0,0-.29-.47l-4.43-3.24V6.75a.73.73,0,0,0-.22-.53A.71.71,0,0,0,15.94,6h-.88a.71.71,0,0,0-.53.22.73.73,0,0,0-.22.53v10a.62.62,0,0,0,.31.56Z" :data-testid="componentID()"/>
</template>

<script>
import componentId from '@/mixins/componentId';

export default {
  name: 'clock-icon',
  setup() {
    const componentID = componentId;
    return { componentID };
  },
};
</script>
