<template>
  <!--eslint-disable-next-line-->
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.58 10.54" class="martech-chevron-solid" :class="chevronDirection" :data-testid="componentID()"><path d="M17.33 0a1.25 1.25 0 0 1 .88 2.13l-8 8a1.25 1.25 0 0 1-1.77 0l-8-8A1.25 1.25 0 0 1 1.25 0Z" fill="currentColor" data-name="Layer 1"/></svg>
</template>

<script>
import componentId from '@/mixins/componentId';

export default {
  name: 'chevron',
  props: {
    direction: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  computed: {
    chevronDirection() {
      return [ 'right', 'left', 'up', 'down' ].includes(this.direction) ? this.direction : 'down';
    },
  },
};
</script>

<style lang="scss" scoped>
.martech-chevron-solid {
  &.right {
    transform: rotate(-90deg);
  }

  &.up {
    transform: rotate(180deg);
  }

  &.left {
    transform: rotate(90deg);
  }
}
</style>
