<template>
  <!--eslint-disable-next-line-->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.38 5.94" class="martech-chevron" :class="chevronDirection" :data-testid="componentID()"><path d="M10.3 1 5.39 5.85a.28.28 0 0 1-.4 0L.08 1a.28.28 0 0 1 0-.4L.55.08a.28.28 0 0 1 .4 0l4.24 4.24L9.43.08a.28.28 0 0 1 .4 0l.47.47a.28.28 0 0 1 0 .45Z" fill="currentColor"/></svg>
</template>]

<script>
import componentId from '@/mixins/componentId';

export default {
  name: 'chevron',
  props: {
    direction: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  computed: {
    chevronDirection() {
      return [ 'right', 'left', 'up', 'down' ].includes(this.direction) ? this.direction : 'down';
    },
  },
};
</script>

<style lang="scss" scoped>
.martech-chevron {
  &.right {
    transform: rotate(-90deg);
  }

  &.up {
    transform: rotate(180deg);
  }

  &.left {
    transform: rotate(90deg);
  }
}
</style>
