<template>
  <div class="error-data" :data-testid="componentID()">
    We're still gathering pricing data.
  </div>
</template>

<script>
import componentId from '@/mixins/componentId';

export default {
  name: 'error-data',
  setup() {
    const componentID = componentId;
    return { componentID };
  },
};
</script>

<style lang="scss" scoped>

.error-data {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  background-color: rgba(255, 255, 255, 0.90);
  font-weight: $martech-weight-bold;
  font-family: $martech-display-inter;
}

</style>
