<template>
  <p class="mana-cost" :data-testid="componentID()">
    <!-- eslint-disable-next-line-->
    Mana Cost: <span v-html="mana" />
  </p>
</template>

<script>
import manaCost from '@/lib/manaCost';
import componentId from '@/mixins/componentId';

export default {
  name: 'mana-cost',
  props: {
    manaCost: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  computed: {
    mana() {
      return manaCost.getManaCostSymbols(this.manaCost);
    },
  },
};
</script>

<style lang="scss" scoped>
.mana-cost {
  font-weight: $martech-weight-semibold;
  margin-bottom: $martech-spacer-2;
  font-size: $martech-type-14;
  display: flex;
  align-items: center;
  line-height: $martech-type-18;

  span {
    font-weight: $martech-weight-normal;
    margin-left: $martech-spacer-2;
  }

  :deep(.mana) {
    width: 12px;
    height: 12px;
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 3px;
  }
}
</style>
