<template>
  <!--eslint-disable-next-line max-len-->
  <svg id="flip-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 31" :data-testid="componentID()"><path d="M31.23 18.39a15.5 15.5 0 0 1-28.15 5.68l.15 6.16a.75.75 0 0 1-.75.77H.75a.76.76 0 0 1-.75-.75v-12a.76.76 0 0 1 .75-.75h12a.76.76 0 0 1 .75.75V20a.75.75 0 0 1-.77.75l-7.9-.19A12.25 12.25 0 0 0 28 18.1a.76.76 0 0 1 .74-.6h1.78a.75.75 0 0 1 .71.89ZM32 .75v12a.76.76 0 0 1-.75.75h-12a.76.76 0 0 1-.75-.75V11a.75.75 0 0 1 .77-.75l7.9.19A12.25 12.25 0 0 0 4 12.9a.76.76 0 0 1-.74.6H1.51a.75.75 0 0 1-.74-.89 15.5 15.5 0 0 1 28.15-5.68L28.77.77a.75.75 0 0 1 .75-.77h1.73a.76.76 0 0 1 .75.75Z" fill="currentColor" data-name="Layer 1"/></svg>
</template>

<script>
import componentId from '@/mixins/componentId';

export default {
  name: 'flip-icon',
  setup() {
    const componentID = componentId;
    return { componentID };
  },
};
</script>

<style lang="scss" scoped>
#flip-icon {
    width: 16px;
    height: 16px;
}
</style>
