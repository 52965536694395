<template>
  <base-styles>
    <a
      v-if="isAbsoluteURL || newWindow"
      :href="analyticsURL"
      :target="urlTarget || undefined"
      :rel="linkRel || undefined"
      class="martech-base-link"
      :data-testid="testId || componentID()"
      :title="title || undefined"
      :tabindex="tabIndex">
      <slot/>
    </a>
    <router-link
      v-else
      :to="linkUrl"
      class="martech-base-link"
      :data-testid="testId || componentID()"
      :title="title || undefined"
      :tabindex="tabIndex"
      @click.stop="$emit('click')">
      <slot/>
    </router-link>
  </base-styles>
</template>

<script>
import delve from 'dlv';
import BaseStyles from '@/components/BaseStyles.vue';
import link from '@/lib/link';
import componentId from '@/mixins/componentId';
import { getSession } from '@tcgplayer/amplitude';
import { getCookie } from '@/lib/cookies';

export default {
  name: 'base-link',
  components: {
    BaseStyles,
  },
  props: {
    linkUrl: {
      type: [ Object, String ],
      required: true,
    },
    newWindow: {
      type: Boolean,
      default: false,
      required: false,
    },
    urlTarget: {
      type: [ String, Boolean ],
      default: undefined,
      required: false,
    },
    title: {
      type: [ String, Boolean ],
      default: undefined,
      required: false,
    },
    testId: {
      type: [ String, Boolean ],
      default: false,
      required: false,
    },
    tabIndex: {
      type: String,
      required: false,
      default: undefined,
      note: 'passing an empty string displays no tabindex on link by default'
    }
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  computed: {
    analyticsURL() {
      if (!link.isDifferentDomain(this.linkUrl) || !link.isTcgPlayerDomain(this.linkUrl)) {
        return this.linkUrl;
      }

      let finalURL = this.linkUrl;

      // Try to maintain the analytics session
      const session = getSession();
      if (session) {
        finalURL += finalURL.includes('?') ? '&' : '?';
        finalURL += `segses=${session.start}|${session.last}`;
      }

      const anonymousID = getCookie('ajs_anonymous_id');
      if (anonymousID) {
        finalURL += finalURL.includes('?') ? '&' : '?';
        finalURL += `ajs_aid=${anonymousID}`;
      }

      return finalURL;
    },
    isAbsoluteURL() {
      return link.isAbsoluteURL(this.linkUrl);
    },
    linkRel() {
      return link.linkRel(this.urlTarget);
    },
  },
};
</script>

<style lang="scss" scoped>
.martech-base-link {
  text-decoration: none;
  cursor: pointer;

  &:focus-visible {
    z-index: 1;
  }
}
</style>
