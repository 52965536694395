<template>
  <div tabindex="0" class="martech-accessibility-wrapper" :data-testid="componentID()" @keyup.enter="navigateOnEnter">
    <slot/>
  </div>
</template>

<script>
import componentId from '@/mixins/componentId';

export default {
  name: 'on-enter-navigate-card',
  props: {
    data: {
      type: Object,
      required: true,
    },
    externalLink: {
      type: Boolean,
      required: false,
    },
    isSyndication: {
      type: Boolean,
      default: false,
    },
    linkPath: {
      type: String,
      default: '',
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  methods: {
    navigateOnEnter(e) {
      const url = this.data?.canonicalURL || this.data?.url;
      e.stopPropagation();

      if (this.externalLink) {
        window.location = url;
      } else if (this.isSyndication) {
        window.open(`${this.linkPath}${url}`, '_blank', 'noopener noreferrer');
      } else {
        this.$router.push({ path: url, });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.martech-accessibility-wrapper {
  height: 100%;
  width: 100%;
}
</style>
