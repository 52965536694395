<template>
  <!--eslint-disable-next-line-->
  <svg v-if="standard" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 115.07 131.52" :data-testid="componentID()"><path d="m109.01 76.35-90.42 53.43c-8.14 4.83-18.6-.97-18.6-10.61V12.29C0 1.56 11.25-2.65 18.6 1.69l90.42 53.45c8.09 4.75 8.06 16.46 0 21.21Z" fill="currentColor"/></svg>
  <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="64" height="64" :data-testid="componentID()"><g data-name="Layer 2"><g data-name="Layer 1"><circle cx="32" cy="32" r="32" fill="#fff" opacity=".8"/><path d="M28.71 43c-1.2.79-2.71-.22-2.71-1.89V22.4c0-1.65 1.5-2.67 2.71-1.88l13.36 9.61a2.38 2.38 0 010 3.77z"/></g></g></svg>
</template>

<script>
import componentId from '@/mixins/componentId';

export default {
  name: 'video-play-icon',
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  props: {
    standard: {
      type: Boolean,
      default: false,
    }
  }
};
</script>
