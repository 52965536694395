<template>
  <base-styles>
    <div class="martech-tabs" :data-testid="componentID()" :class="{'large' : largeTabs}">
      <button v-for="(tab, index) in tabs"
              :key="index"
              role="tab"
              :id="tab.title"
              :aria-selected="activeTab === tab.title"
              class="martech-tab martech-text-capitalize"
              :class="{'is-active' : activeTab === tab.title}"
              @click="changeTabs(tab.title)">
        {{ tab.title }}
      </button>
    </div>
  </base-styles>
</template>

<script>
import BaseStyles from '@/components/BaseStyles.vue';
import componentId from '@/mixins/componentId';

export default {
  name: 'tabs',
  components: {
    BaseStyles,
  },
  props: {
    tabs: {
      type: Array,
      default: () => ([]),
      required: true,
    },
    defaultTab: {
      type: String,
      required: false,
      default: '',
    },
    onChange: {
      type: Function,
      required: true,
    },
    largeTabs: {
      type: Boolean,
      default: true,
    }
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  data() {
    return {
      activeTab: null,
    };
  },
  created() {
    this.activeTab = this.defaultTab;
  },
  methods: {
    changeTabs(active) {
      this.activeTab = active;
      this.onChange(active);
    },
  },
};
</script>

<style lang="scss" scoped>
.martech-tabs {
  width: 100%;
  border-bottom: 1px solid $martech-border;
  position: relative;
  z-index: 1;

  .martech-tab {
    background: transparent;
    color: $martech-text-subdued;
    border: 0;
    font-size: $martech-type-14;
    font-weight: $martech-weight-semibold;
    letter-spacing: 0.25px;
    padding: 12px $martech-spacer-2 12px $martech-spacer-2;
    cursor: pointer;
    top: 1px;
    position: relative;
    z-index: 2;
    margin-right: $martech-spacer-4;

    @include breakpoint(1024) {
      &.large {
        padding: $martech-spacer-4 $martech-spacer-2 $martech-spacer-4 $martech-spacer-2;
      }
    }

    &.is-active {
      color: $martech-text-primary;
      border-bottom: 2px solid $martech-blue;
    }
  }
}
</style>
