<template>
  <div class="export-select__text">
    <multi-option-button id="export-select" :options="options" btn-style="martech-white" button-label="Export"
      @option-clicked="onSelectedChange" />
    <span v-if="showToast" class="copy-toast">{{ toastMessage }}</span>
  </div>
</template>

<script>
import amplitudeEvent from '@tcgplayer/amplitude';
import MultiOptionButton from '@/components/elements/MultiOptionButton.vue';
import clipboard from '@/lib/clipboard';
import MagicHelpers from '@/lib/magic';
import YugiohHelpers from '@/lib/yugioh';
import PokemonHelpers from '@/lib/pokemon';
import LorcanaHelpers from '@/lib/lorcana';
import FleshAndBloodHelpers from '@/lib/fleshandblood';

export default {
  name: 'export-select',
  components: {
    MultiOptionButton,
  },
  props: {
    deck: {
      type: Object,
      required: true,
      default: () => { },
    },
  },
  data() {
    return {
      magicOptions: {
        cpArena: 'Copy Deck for MTG Arena',
        dlArena: 'Download Deck Text File',
      },
      defaultOptions: {
        cpText: 'Copy Deck to Clipboard',
        dlText: 'Download Deck Text File',
      },
      toastMessage: '',
      showToast: false,
    };
  },
  computed: {
    options() {
      return this.deck.game === 'magic' ? this.magicOptions : this.defaultOptions;
    },
  },
  methods: {
    onSelectedChange(key) {
      const text = this.toText(this.deck);

      switch (key) {
        case 'dlText':
        case 'dlArena':
          this.downloadFile(`${this.deck.name} by ${this.deck.playerName}.txt`, text);
          break;
        case 'cpText':
        case 'cpArena':
          clipboard.Copy(text);
          amplitudeEvent('infinite', 'infiniteDeckExport', {
            exportType: 'copy',
            format: this.deck.format,
            id: this.deck.id,
            player: this.deck.playerName || this.deck.playerHandle,
            productLine: this.deck.game,
            title: this.deck.name,
          });
          this.toast('Copied to Clipboard');
          break;
        default: break;
      }
    },
    toText(deck) {
      let newline = '\n';
      if (navigator.userAgent.match(/Win/g) != null) {
        newline = '\r\n';
      }

      const output = [];
      let sort;
      switch (deck.game) {
        case 'magic':
          sort = MagicHelpers.subDeckSort;
          break;
        case 'yugioh':
          sort = YugiohHelpers.subDeckSort;
          break;
        case 'pokemon':
          sort = PokemonHelpers.subDeckSort;
          break;
        case 'lorcana':
          sort = LorcanaHelpers.subDeckSort;
          break;
        case 'flesh and blood':
          sort = FleshAndBloodHelpers.subDeckSort;
          break;
      }

      let ordered = Object.keys(deck.subDecks);
      ordered.sort(sort);

      ordered.forEach((k) => {
        const lines = [];
        const subDeck = deck.subDecks[k];
        subDeck.forEach((card) => {
          lines.push(`${card.quantity} ${card.name}`);
        });

        output.push(lines.join(newline));
      });

      return output.join(`${newline}${newline}${newline}`);
    },
    toast(msg) {
      this.toastMessage = msg;
      this.showToast = true;

      setTimeout(() => {
        this.showToast = false;
      }, 2000);
    },
    downloadFile(name, data) {
      const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if (iOS) {
        const uriContent = `data:application/octet-stream,${encodeURIComponent(data)}`;
        window.open(uriContent, name);
      } else {
        const link = document.createElement('a');
        link.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(data)}`);
        link.setAttribute('download', name);
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.showToast = true;
      this.toastMessage = 'Downloaded Text File';

      amplitudeEvent('infinite', 'infiniteDeckExport', {
        exportType: 'download',
        format: this.deck.format,
        id: this.deck.id,
        player: this.deck.playerName || this.deck.playerHandle,
        productLine: this.deck.game,
        title: this.deck.name,
      });

      setTimeout(() => {
        this.showToast = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.export-select__text {
  position: relative;
  width: 100%;
}

:deep(.martech-button) {
  width: 100%;

  @include breakpoint(768) {
    width: 147px;
  }
}

.copy-toast {
  animation: toast-close 600ms forwards;
  animation-iteration-count: 1;
  animation-delay: 4s;
  color: $martech-text-primary;
  background: $martech-white;
  border-radius: $martech-radius-default;
  height: 32px;
  width: 150px;
  padding: $martech-spacer-2;
  position: absolute;
  font-size: $martech-type-12;
  z-index: 100;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08), 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  top: 50px;
  justify-content: center;

  &::before {
    content: "\A";
    position: absolute;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid $martech-white;
    left: 35px;
    top: -6px;
  }
}

@keyframes toast-close {
  0% {
    opacity: 1;
    height: 100%;
  }

  100% {
    opacity: 0;
    height: 0;
  }
}
</style>
