<template>
  <div class="chip-wrapper" :data-testid="componentID()">
    <div class="chip" @click="$emit('on-close')">
      <span class="chip__text">
        {{ chipText }}
      </span>
      <button v-if="closeable" class="chip__clear">
        <close-circle-icon/>
      </button>
    </div>
  </div>
</template>

<script>
import CloseCircleIcon from '@/components/iconography/CloseCircleIcon.vue';
import componentId from '@/mixins/componentId';

export default {
  name: 'chip',
  components: {
    CloseCircleIcon,
  },
  props: {
    closeable: {
      type: Boolean,
      required: false,
      default: false,
    },
    chipText: {
      type: String,
      required: true,
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
};
</script>

<style lang="scss" scoped>
.chip-wrapper {
  padding: $martech-spacer-1;
}
.chip {
  padding: 4px $martech-spacer-2;
  display: inline-flex;
  align-items: center;
  color: $martech-text-primary;
  background-color: $martech-surface;
  border-radius: 50px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  border: 1px solid $martech-border;

  &__text {
    margin: 0;
    font-size: $martech-type-12;
    letter-spacing: 0.4px;
    font-weight: $martech-weight-semibold;
    text-transform: capitalize;
  }

  &__clear {
    background: transparent;
    border: 0;
    line-height: 0.875px;
    margin-left: $martech-spacer-2;
    padding: 0;

    &:focus {
      outline: 0;
    }

    svg {
      width: 13px;
    }
  }

  &:hover {
    background-color: $martech-surface;
    border: 1px solid $martech-blue-hover;
  }
}
</style>
