<template>
  <on-enter-navigate-card :data="articleData">
    <div :data-testid="componentID(vertical)" class="large-square-full" tabindex="-1">
      <transition name="fade-card">
        <div v-show="showOverlay" class="hover-overlay overlay" />
      </transition>
      <base-link :link-url="articleData.canonicalURL || '#'" tabindex="-1">
        <div class="martech-card shadow">
          <pro-icon v-if="isPro && allowProIcon" />
          <right-text-overlay v-if="showNewTag" text="New" />
          <img v-if="hasRoot"
            :src="imageURL(articleData.imageURL || fallbackImage, { maxDimensions: 'autoxauto', quality: 75, })"
            :alt="articleData.title" class="martech-card-background" @error="errorImageFallback($event)">
          <div class="martech-card-content">
            <div v-if="format" class="format" :class="{ 'orange-brand': siteConfig('global.orangeBrand') }">
              <base-link :link-url="formatLink" class="martech-labels martech-white">
                {{ format }}
              </base-link>
            </div>
            <div class="author" v-if="showLargeAuthor">
              <author-picture-template :source="authorImage" :image-alt-name="authorName"
                size="medium" />
              <div class="group">
                <p class="author martech-text-sm" :class="{ 'orange-brand': siteConfig('global.orangeBrand') }">
                  <base-link :link-url="authorURL || '#'" class="link martech-text-sm" tabindex="-1">
                    {{ authorName }}
                  </base-link>
                </p>
              </div>
            </div>
            <h2
              class="martech-semi-condensed martech-text-bold martech-sub-heading martech-text-uppercase martech-two-lines">
              {{ articleData.title }}
            </h2>
            <p class="teaser" v-if="showTeaser">
              {{ articleDescription }}
            </p>
            <div class="group-logo">
              <p v-if="showLargeAuthor" class="date martech-text-sm">
                {{ articleDate }}
              </p>
              <div v-else class="author small">
                <author-picture-template :source="authorImage" :image-alt-name="authorName"
                  size="medium" />
                <div class="group">
                  <p class="author-small martech-text-sm martech-text-semibold">
                    <base-link :link-url="authorURL || '#'" class="link martech-text-sm" tabindex="-1">
                      {{ authorName }}
                    </base-link>
                  </p>
                  <p class="date martech-text-sm">
                    {{ articleDate }}
                  </p>
                </div>
              </div>
              <ultimate-guard-sponsor-logo v-if="isUltimateGuardSponsored" />
            </div>
          </div>
          <div class="martech-card-overlay" />
        </div>
      </base-link>
    </div>
  </on-enter-navigate-card>
</template>

<script setup>
import { computed } from 'vue';

import BaseLink from '@/components/elements/BaseLink.vue';
import AuthorPictureTemplate from '@/components/cards/card-parts/AuthorImage.vue';
import OnEnterNavigateCard from '@/components/cards/card-parts/OnEnterNavigateCard.vue';
import ProIcon from '@/components/cards/card-parts/ProIcon.vue';
import RightTextOverlay from '@/components/cards/card-parts/RightTextOverlay.vue';
import UltimateGuardSponsorLogo from '@/components/cards/card-parts/UltimateGuardSponsorLogo.vue';

import dates from '@/lib/dates';
import image from '@/lib/image';

import useCardHelpers from '@/use/card/helpers';
import useImageAlter from '@/use/image/alter';
import useComponentId from '@/use/useComponentId';
import useSiteConfig from '@/use/siteConfig';
import { get } from '@vueuse/core';

const props = defineProps({
  articleData: {
    type: Object,
    required: false,
    default: () => ({
      title: '',
      imageURL: '',
      date: '',
      canonicalURL: '',
      contentType: '',
      authorURL: '',
      authorName: '',
      authorImageURL: '',
      vertical: '',
      format: '',
      type: 'article',
      teaser: '',
      isPro: false,
    }),
  },
  showOverlay: {
    type: Boolean,
    required: false,
    default: false,
  },
  showTeaser: {
    type: Boolean,
    required: false,
    default: true,
  },
  showLargeAuthor: {
    type: Boolean,
    required: false,
    default: true,
  },
  allowProIcon: {
    type: Boolean,
    default: false,
  },
});

const { siteConfig } = useSiteConfig();
const { componentID } = useComponentId();

const {
  articleDate,
  contentType,
  authorImage,
  authorName,
  articleDescription,
  format,
  formatLink,
  vertical,
  imageAltName,
  overlayImage,
  taxonomyName,
  isPro,
  isUltimateGuardSponsored,
  articleTypeTag,
  errorImageFallback,
  fallbackImage,
  showNewTag,
  verticalLink } = useCardHelpers(props);

const { imageURL, hasRoot } = useImageAlter();
</script>

<style lang="scss" scoped>
.large-square-full {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: $martech-radius-medium;
  background-color: $martech-black;

  .martech-card {
    width: 100%;
    height: 100%;

    &-background {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
      background-color: $martech-black;
    }

    &-content {
      width: 100%;
      position: absolute;
      z-index: 20;
      bottom: 0;
      padding: $martech-spacer-3;
      text-shadow: 1px 1px 0 $martech-black, 0 0 16px $martech-black;

      .vertical {
        padding-bottom: $martech-spacer-1;
      }

      .format {
        margin-bottom: $martech-spacer-3;

        :deep(.martech-base-link) {
          color: $martech-white;
        }

        &.orange-brand {
          :deep(.martech-base-link) {
            background-color: #962220;
            padding: $martech-spacer-1 $martech-spacer-2;
            border-radius: $martech-radius-medium;
          }
        }
      }

      h2 {
        color: $martech-white;
        margin-bottom: $martech-spacer-2;
        letter-spacing: 1px;
      }

      .group-logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .author {
        display: flex;
        align-items: flex-end;
        margin-bottom: $martech-spacer-2;

        &-small {
          align-items: center;
        }

        .author-small {
          :deep(.martech-base-link) {
            font-size: $martech-type-13;
          }
        }

        span {
          line-height: 20px;
        }

        :deep(.martech-base-link) {
          font-size: $martech-type-32;
          color: $martech-white;
        }

        &.orange-brand {
          :deep(.martech-base-link) {
            color: $martech-cfb-orange-light;
          }
        }

        p {
          padding-left: $martech-spacer-2;
        }

        :deep(.medium) {
          width: 52px;
          height: 52px;
        }
      }

      .author,
      .link {
        color: $martech-white;
        font-weight: $martech-weight-bold;

        &:hover {
          text-decoration: none;
        }
      }

      .teaser {
        color: $martech-white;
        font-size: $martech-type-13;
        line-height: 20px;
        margin-bottom: $martech-spacer-3;
      }

      .date {
        color: $martech-white;
      }
    }

    &-overlay {
      position: absolute;
      height: 80%;
      background: linear-gradient(180deg,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.65),
          rgba(0, 0, 0, 0.8),
          rgba(0, 0, 0, 1));
      z-index: 10;
    }
  }
}

.hover-overlay {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: $martech-black;
  opacity: 0.5;
}

.fade-card-enter-active {
  transition: opacity 400ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

.fade-card-leave-active {
  transition: opacity 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

.fade-card-enter,
.fade-card-leave-to {
  opacity: 0;
}
</style>