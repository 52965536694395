<template>
  <div class="chart-container" :data-testid="componentID()">
    <canvas ref="canvas" @touchstart="onTouchStart" @touchend="onTouchEnd" @mouseleave="handleMouseLeave">
      <table role="region" aria-live="polite">
        <thead>
          <tr>
            <th>Date</th>
            <th v-for="variant in variants">{{ variant }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in fallbackData">
            <td v-for="col in row">
              {{ col }}
            </td>
          </tr>
        </tbody>
      </table>
    </canvas>
    <div ref="tooltipRef" class="tooltip">
      <div class="title">
        {{ tooltipTitle }}
      </div>
      <div class="marketPrice">
        {{ tooltipMarketPrice }}
      </div>
      <div class="quantity">
        {{ tooltipQuantity }} items sold
      </div>
      <div v-if="tooltipLow > 0 && tooltipHigh > 0" class="lowHigh">
        ${{ tooltipLow }} - ${{ tooltipHigh }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { get, set, useDebounceFn, onClickOutside } from '@vueuse/core';
import {
  Chart,
  LineElement, PointElement, LineController,
  BarController, BarElement,
  CategoryScale, LinearScale, Tooltip,
} from 'chart.js';
import useComponentId from '@/use/useComponentId';
import useDeviceType from '@/use/deviceType.ts';
import prices from '@/lib/prices';

const emit = defineEmits(['touchend', 'hover'])

const props = defineProps({
  header: {
    type: Object,
    default: () => ({}),
  },
  datasets: {
    type: Array,
    default: () => [],
  },
  labels: {
    type: Array,
    default: () => [],
  },
  firstValidData: {
    type: Number,
    default: -1,
  },
  timeFrame: {
    type: String,
    required: true,
  },
  originalDates: {
    type: Array,
    required: true,
  },
  displayVolume: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Object,
    default: () => {},
  },
});

const { componentID } = useComponentId();
const { isMobile } = useDeviceType();

const dateFormatter = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric', });

let sticky = false;
let clickOccurred = false;

const tooltipRef = ref(null);
const canvas = ref(null);
const tooltipTitle = ref('');
const tooltipMarketPrice = ref('');
const tooltipQuantity = ref('');
const tooltipHigh = ref('');
const tooltipLow = ref('');

let lastHover = 0;
let allowTooltip = true;

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const hoverEvent = (_, items) => {
  if (!clickOccurred && !isMobile.value && sticky) {
    return;
  }

  let shouldEmit = true;
  const it = items.map((item) => {
    if (item.index === lastHover) shouldEmit = false;
    return item.index;
  });

  if (shouldEmit) {
    [ lastHover ] = it;
    emit('hover', it, items?.[0]?.element?.x);
  }
};

const clickEvent = (_, items) => {
  clickOccurred = true;
  hoverEvent(_, items);
  sticky = true;
};

const mouseLeave = () => {
  if (sticky || !tooltipRef?.value?.style) return;
  tooltipRef.value.style.opacity = 0;
};
const handleMouseLeave = useDebounceFn(mouseLeave, 100);

onClickOutside(canvas, () => {
  sticky = false;
  if (typeof document === 'undefined') return;
  // Re-enable scrolling when done touching
  document.documentElement.style.overflow = 'auto';
  handleMouseLeave();
});

let touchStart;
const onTouchStart = () => {
  sticky = false;
  touchStart = Date.now();
  if (typeof document === 'undefined') return;
  // Keep page from scrolling while touching the chart looking at prices
  document.documentElement.style.overflow = 'hidden';
};

const onTouchEnd = () => {
  if (Date.now() - touchStart < 100) { // Consider this a click rather than a drag
    sticky = true;
  }

  allowTooltip = false;
  emit('touchend');
  myChart.update();

  if (typeof document === 'undefined') return;
  // Re-enable scrolling when done touching
  document.documentElement.style.overflow = 'auto';

  if (!sticky) {
    handleMouseLeave();
  }
};

const formatDate = (date) => {
  if (props.timeFrame === 'month') return dateFormatter.format(new Date(date));

  let range;

  switch (props.timeFrame) {
    case 'annual':
    case 'semi-annual':
      range = 6;
      break;
    case 'quarter':
      range = 2;
      break;
    default:
      return date;
  }

  const endDate = new Date(date);
  const startDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24 * range);

  return `${dateFormatter.format(startDate)} - ${dateFormatter.format(endDate)}`;
}

const tooltipHandler = (context) => {
  if (!clickOccurred && sticky) return;

  clickOccurred = false;

  const { chart, tooltip } = context;

  const date = props.originalDates[tooltip.dataPoints[0].dataIndex] || '';

  tooltipTitle.value = formatDate(date);
  tooltipMarketPrice.value = formatter.format(tooltip?.dataPoints?.[0]?.raw) || '';
  tooltipQuantity.value = tooltip?.dataPoints?.[1]?.raw || '';

  const data = props?.data[tooltip?.dataPoints?.[0]?.dataIndex] || {};

  set(tooltipLow, parseFloat(data?.variants?.[0]?.lowSalePrice).toFixed(2));
  set(tooltipHigh, parseFloat(data?.variants?.[0]?.highSalePrice).toFixed(2));

  let left = tooltip.dataPoints[0].element.x + 15;
  let top = tooltip.dataPoints[0].element.y - tooltip.height / 2;

  if (left > (chart.width / 2)) {
    left -= (tooltip.width * 2) + 30;
  }

  const tipHeight = get(tooltipRef).clientHeight;
  const chartHeight = get(chart).chartArea.bottom;

  if (top < 0) {
    // Keep it at the top of chart if it goes off
    top = 0;
  } else if (top + tipHeight > chartHeight) {
    // Keep it at the bottom of the chart if it goes off
    top = chartHeight - tipHeight;
  }

  if (left) {
    tooltipRef.value.style.opacity = 1;
  }

  tooltipRef.value.style.left = `${left}px`;
  tooltipRef.value.style.top = `${top}px`;
};

// If it's not enabled we don't get feedback on the location, but we don't want it to show
// so make everything transparent.
const tooltip = {
  enabled: true,
  mode: 'index',
  intersect: false,
  displayColors: false,
  backgroundColor: 'transparent',
  titleColor: 'transparent',
  bodyColor: 'transparent',
  footerColor: 'transparent',
  borderColor: 'transparent',
};

if (props.displayVolume) {
  tooltip.external = tooltipHandler;
}

const options = {
  backgroundColor: 'transparent',
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    line: {
      tension: 0.3,
    },
  },
  layout: {
    padding: 0,
  },
  scales: {
    y: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        display: true,
        beginAtZero: true,
        autoSkipPadding: 30,
        padding: 0,
        min: 0,
        font: {
          size: 13,
        },
        callback(value) {
          return `${prices.marketPrice(value, true)}`;
        },

      },
    },
    x: {
      grid: {
        display: false,
        drawBorder: false,
        offsetGridLines: false,
      },
      ticks: {
        maxRotation: 0,
        autoSkipPadding: 30,
        beginAtZero: true,
        padding: 8,
        font: {
          size: 13,
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip,
  },
  hover: {
    mode: 'index',
    intersect: false,
  },
  onHover: hoverEvent,
  onClick: clickEvent,
};

if (props.displayVolume) {
  options.scales.y1 = {
    position: 'right',
    grid: {
      display: false,
      drawBorder: false,
    },
    ticks: {
      display: true,
      beginAtZero: true,
      autoSkipPadding: 10,
      padding: 0,
      min: 0,
      font: {
        size: 13,
      },
      precision: 0,
    },
  };
}

const verticalLinePlugin = {
  id: 'verticalLine',
  afterDraw(chart) {
    if (!allowTooltip && chart?.tooltip?._active?.length) {
      chart.tooltip._active = [];
      allowTooltip = true;
    }
    // Using tooltip active instead allows the line to only move along the width of the active lines on the graph.
    else if (chart?.tooltip?._active?.length) {
      const activePoint = chart?.tooltip?._active;
      const { x, } = activePoint[0].element;
      const yAxis = chart.scales.y;
      const { ctx, } = chart;
      ctx.save();
      if (props.displayVolume) {
        ctx.setLineDash([ 3, 4 ]);
      }
      ctx.beginPath();
      ctx.moveTo(x, yAxis.top);
      ctx.lineTo(x, yAxis.bottom);
      ctx.lineWidth = 1.5;
      ctx.strokeStyle = '#121212';
      ctx.lineCap = 'round';
      ctx.stroke();
      ctx.restore();
    }
  },
};

Chart.register(
  LineElement, PointElement, LineController,
  CategoryScale, LinearScale, Tooltip,
  BarController, BarElement,
  verticalLinePlugin
);

const localDatasets = ref([]);
let myChart;

onMounted(() => {
  if (!get(localDatasets).length) set(localDatasets, [ ...props.datasets ]);

  if (get(localDatasets).length) {
    setDefaults();

    myChart = new Chart(get(canvas), {
      type: 'line',
      data: {
        labels: props.labels,
        datasets: get(localDatasets),
      },
      options,
      firstValid: props.firstValidData,
    });
  }
});

const variants = computed(() => Object.keys(props.header));

const fallbackData = computed(() => {
  const data = [];
  if (!get(localDatasets).length) return data;

  for (let i = 0; i < get(localDatasets)[0].data.length; i++) {
    let dt = props.originalDates[i] || props.labels[i];
    let range;

    switch (props.timeFrame) {
      case 'annual':
      case 'semi-annual':
        range = 6;
        break;
      case 'quarter':
        range = 2;
        break;
      default:
        range = 1;
    }

    if (range > 1) {
      const endDate = new Date(dt);
      const startDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24 * range);


      dt = `${startDate.getMonth() + 1}/${startDate.getDate()} to ${endDate.getMonth() + 1}/${endDate.getDate()}`;
    }

    const row = [ dt ];
    for (let j = 0; j < get(localDatasets).length; j++) {
      row.push(formatter.format(get(localDatasets)[j].data[i]));
    }
    data.push(row);
  }

  return data;
});

const setValue = (position, name, value) => {
  if (typeof get(localDatasets)?.[position]?.[name] === 'undefined') {
    get(localDatasets)[position][name] = value;
  }
}

const setDefaults = () => {
  for (let i = 0; i < get(localDatasets).length; i++) {
    setValue(i, 'backgroundColor', '#ffffff');
    setValue(i, 'borderWidth', 2);
    setValue(i, 'pointRadius', 0);
    setValue(i, 'pointHoverRadius', 0);
    setValue(i, 'fill', false);
  }
};
</script>

<style lang="scss" scoped>
.chart-container {
  position: relative;
  height: 100%;
  user-select: none; // Prevent text selection because it will lock scrolling until the selection is cleared

  .tooltip {
    display: flex;
    flex-direction: column;
    gap: 2px;
    position: absolute;
    opacity: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 6px;
    pointer-events: none; // Required so we continue to get tooltipHandler updates when hovering over the tooltip
    transition: ease-out all 80ms;

    .title {
      font-weight: $martech-weight-bold;
      border-bottom: 1px solid $martech-gray-20;
      margin-bottom: $martech-spacer-1;
      padding-bottom: 2px;
      font-size: $martech-type-14;
    }

    .marketPrice {
      font-weight: $martech-weight-bold;
      color: $martech-blue;
      margin-bottom: $martech-spacer-1;
      font-size: $martech-type-16;
    }

    .quantity, .lowHigh {
      font-size: $martech-type-14;
      margin-bottom: $martech-spacer-1;
    }
  }
}
</style>
