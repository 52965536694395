<template>
  <!--eslint-disable-next-line max-len-->
  <svg id="asterisk-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.66 32" :data-testid="componentID()"><path d="m28.56 23.35-.75 1.3a.74.74 0 0 1-1 .27l-11-6.32v12.65a.75.75 0 0 1-.75.75h-1.5a.76.76 0 0 1-.75-.75V18.6l-11 6.32a.74.74 0 0 1-1-.27l-.71-1.3a.75.75 0 0 1 .27-1l11-6.33-11-6.35a.75.75 0 0 1-.27-1l.75-1.3a.74.74 0 0 1 1-.27l11 6.32V.75a.76.76 0 0 1 .73-.75h1.5a.75.75 0 0 1 .75.75V13.4l11-6.32a.74.74 0 0 1 1 .27l.75 1.3a.74.74 0 0 1-.27 1L17.33 16l11 6.33a.74.74 0 0 1 .23 1.02Z" fill="currentColor" data-name="Layer 1"/></svg>
</template>

<script>
import componentId from '@/mixins/componentId';

export default {
  name: 'asterisk-icon',
  setup() {
    const componentID = componentId;
    return { componentID };
  },
};
</script>
