<template>
  <!--eslint-disable-next-line max-len-->
  <svg v-for="i in pitch" :key="i" xmlns="http://www.w3.org/2000/svg" data-name="Layer 2" viewBox="0 0 31.5 31.5" id="card-pitch" :data-testid="componentID()">
    <defs>
      <linearGradient :id="pipColor(pitch)" x1="1.4" x2="23.74" y1="32.6" y2="10.26" gradientTransform="matrix(1 0 0 -1 0 34)" gradientUnits="userSpaceOnUse">
        <stop offset=".55" :stop-color="pipStartColor(pitch)"/>
        <stop offset="1" :stop-color="pipStopColor(pitch)"/>
      </linearGradient>
    </defs>
    <g data-name="Layer 2">
      <circle cx="15.75" cy="15.75" r="15.75"/>
      <circle cx="15.75" cy="15.75" r="12.54" :fill="`url(#${pipColor(pitch)})`"/>
    </g>
  </svg>
</template>

<script setup>
import useComponentId from '@/use/useComponentId';

const { componentID } = useComponentId();

const props = defineProps({
  pitch: {
    type: Number,
    required: true,
  },
});

const pipColor = (i) => {
  const id = ['one', 'two', 'three'];
  return id[i-1] || '';
}

const pipStartColor = (i) => {
  const startColors = ['#aa0009', '#ffda3f', '#1593ff'];
  return startColors[i-1] || '';
}

const pipStopColor = (i) => {
  const stopColors = ['#6a0005', '#ffc000', '#004ed4'];
  return stopColors[i-1] || '';
}
</script>

<style lang="scss" scoped>
#card-pitch {
  height: 8px;

  &:nth-of-type(2),
  &:nth-of-type(3) {
    padding-left: 2px;
  }
}
</style>