<template>
  <base-styles>
    <div
      v-show="isActive"
      id="alert"
      class="martech-alert"
      :class="[{'is-rounded' : roundedCorners}, alertType, {'text-stacked' : textStacked}]"
      :data-testid="componentID()">
      <div class="martech-alert__body" :class="[alertType, {'text-stacked' : textStacked}, {'centered' : centeredText}]">
        <div class="icon-group">
          <template v-if="hasIcon">
            <warning-icon v-if="type === 'warning'"/>
            <danger-icon v-else-if="type === 'danger'"/>
            <success-icon v-else-if="type === 'success'"/>
            <info-icon v-else/>
          </template>
          <p v-if="alertTitle" class="martech-body alert-title">
            {{ alertTitle }}
          </p>
        </div>
        <p v-if="alertText" class="martech-detail alert-text">
          {{ alertText }}
          <base-link 
            v-if="button.buttonURL"
            class="alert-link martech-detail"
            :link-url="button.buttonURL"
            :url-target="button.newTab ? '_target' : false"
            :new-window="button.newTab">
            {{ button.buttonText }}
          </base-link>
        </p>
      </div>
      <button v-if="dismissable" class="close-toggle" aria-label="close alert" @click="closeAlert">
        <close-icon aria-label="close alert icon"/>
      </button>
    </div>
  </base-styles>
</template>

<script>
import CloseIcon from '@/components/iconography/CloseIcon.vue';
import InfoIcon from '@/components/iconography/alert-icons/InfoIcon.vue';
import WarningIcon from '@/components/iconography/alert-icons/WarningIcon.vue';
import DangerIcon from '@/components/iconography/alert-icons/DangerIcon.vue';
import SuccessIcon from '@/components/iconography/alert-icons/SuccessIcon.vue';
import BaseStyles from '@/components/BaseStyles.vue';
import componentId from '@/mixins/componentId';
import BaseLink from '@/components/elements/BaseLink.vue';

export default {
  name: 'alert',
  components: {
    CloseIcon,
    InfoIcon,
    WarningIcon,
    DangerIcon,
    SuccessIcon,
    BaseStyles,
    BaseLink,
  },
  props: {
    roundedCorners: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'default',
    },
    alertTitle: {
      type: String,
      default: '',
    },
    alertText: {
      type: String,
      default: '',
    },
    dismissable: {
      type: Boolean,
      default: false,
    },
    textStacked: {
      type: Boolean,
      default: false,
    },
    button: {
      type: Object,
      default: () => ({
        buttonURL: '',
        buttonText: '',
        newTab: false,
      }),
    },
    centeredText: {
      type: Boolean,
      default: true,
    },
    hasIcon: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  data() {
    return {
      isActive: true,
    };
  },
  computed: {
    alertType() {
      switch (this.type) {
        case 'danger':
        case 'warning':
        case 'success':
        case 'info':
          return this.type;
        default:
          return 'default';
      }
    },
  },
  methods: {
    closeAlert() {
      this.isActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.martech-alert {
  padding: $martech-spacer-2 $martech-spacer-3;
  display: flex;
  justify-content: space-between;
  font-family: $martech-display-inter;

  p {
    margin: 0;
  }

  .alert-title {
    font-weight: $martech-weight-bold;
  }

  &.is-rounded {
    border-radius: $martech-radius-default;

    &.default {
      border: 1px solid $martech-border;
    }

    &.danger {
      border: 1px solid $martech-system-danger-border;
    }

    &.info {
      border: 1px solid $martech-system-info-border;
    }

    &.warning {
      border: 1px solid $martech-system-warning-border;
    }

    &.success {
      border: 1px solid $martech-system-success-border;
    }
  }

  &.text-stacked {
    padding: $martech-spacer-3;

    .close-toggle {
      align-self: flex-start;
    }
  }

  &.default {
    background-color: $martech-surface;
  }

  &.danger {
    background-color: $martech-system-danger-subdued;
  }

  &.info {
    background-color: $martech-system-info-subdued;
  }

  &.warning {
    background-color: $martech-system-warning-subdued;
  }

  &.success {
    background-color: $martech-system-success-subdued;
  }

  &__body {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    @include breakpoint(768) {
      flex-direction: row;
    }

    &.centered {
      justify-content: center;
      text-align: center;
    }

    .icon-group {
      display: flex;
      align-items: center;

      :deep(.martech-danger),
      :deep(.martech-info),
      :deep(.martech-warning),
      :deep(.martech-success) {
        width: 16px;
        margin-right: $martech-spacer-2;
      }
    }

    .alert-link {
      margin-left: $martech-spacer-1;
      margin-bottom: 0;
      text-decoration: underline;
      cursor: pointer;
      color: $martech-text-primary;
    }

    &.text-stacked {
      display: block;

      .alert-text,
      .martech-action-btn {
        margin-left: $martech-spacer-4;
      }

      .martech-action-btn {
        margin-top: $martech-spacer-2;
      }
    }

    .alert-title {
      padding-right: $martech-spacer-2;
    }

    &.default {
      color: $martech-text-primary;
    }

    &.info {
      .alert-title {
        color: $martech-system-info-title;
      }

      .martech-info {
        color: $martech-system-info-tint;
      }
    }

    &.danger {
      .alert-title {
        color: $martech-system-danger-title;
      }

      .martech-danger {
        color: $martech-system-danger-tint;
      }
    }

    &.warning {
      .alert-title {
        color: $martech-system-warning-title;
      }

      .martech-warning {
        color: $martech-system-warning-tint;
      }
    }

    &.success {
      .alert-title {
        color: $martech-system-success-title;
      }

      .martech-success {
        color: $martech-system-success-tint;
      }
    }
  }

  .close-toggle {
    align-self: center;
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    margin-left: $martech-spacer-4;
    color: $martech-text-primary;

    #close-icon {
      height: 10px;
      width: 10px;
    }
  }
}
</style>
