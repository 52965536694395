<template>
  <on-enter-navigate-card :data="articleData" :is-syndication="isSyndication" :link-path="linkPath"
    :data-testid="componentID()">
    <base-link :link-url="articleCardLink || '#'" :url-target="urlTarget" @click="onClick(articleData)" tab-index="-1">
      <base-styles>
        <div ref="root" class="martech-card martech-shadow martech-card--is-link" tabindex="-1">
          <div class="martech-card-hero">
            <pro-icon v-if="isPro && allowProIcon"/>
            <right-text-overlay v-if="showNewTag" text="New" />
            <figure class="martech-image__wrapper">
              <img class="martech-image__item" :src="source || fallbackImage" alt=""
                :class="{ 'minimal-details': !detailed }" @error="errorImageFallback($event)"
                :style="{ height: heroHeight }"
                :data-testid="componentID('hero-image')">
              <template v-if="showOverlay && overlayImage">
                <img v-if="taxonomyName === 'budget'" :src="overlayImage" :alt="taxonomyName"
                  :data-testid="componentID('taxonomy-logo')" class="budget-icon" :class="{ 'with-pro': isPro && allowProIcon }">
                <img v-else class="martech-overlay" :src="overlayImage" :alt="taxonomyName"
                  :data-testid="componentID('taxonomy-logo')">
              </template>
              <div v-if="contentType === 'Video'" class="martech-image__play-icon">
                <video-play-icon />
              </div>
            </figure>
            <div v-if="contentType === 'Podcast'" class="martech-podcast">
              <img :src="podcast" alt="podcast" :data-testid="componentID('podcast-icon')">
            </div>
            <format-tag read-time :format="format" :article-read-time="articleReadTime" :content-type="contentType"
              :overlay-size="overlaySize" />
          </div>
          <mana-bars v-if="showManaBars" :color="internalTags"/>
          <div class="martech-card__content"
            :class="[{ 'has-vertical': showVerticalLink }, { 'uniform-height': uniformHeight }, { 'uniform-height-no-description': uniformHeight && !detailed }]">
            <div v-if="showVerticalLink && vertical && vertical.toLowerCase() !== 'announcements'"
              class="martech-vertical" :data-testid="componentID('vertical')">
              <span v-if="isSyndication || !allowLinks" class="martech-labels martech-blue">
                {{ articleTypeTag }}
              </span>
              <base-link v-else
                :link-url="verticalLink"
                class="martech-labels martech-blue"
                tabindex="-1"
                :url-target="urlTarget"
                @keyup.enter.stop>
                {{ articleTypeTag }}
              </base-link>
            </div>
            <h2 class="martech-heading-sm martech-two-lines" :class="{ 'minimal-details': !detailed }"
              :data-testid="componentID('title')">
              {{ title }}
            </h2>
            <p v-if="detailed" class="martech-text-sm martech-two-lines" :data-testid="componentID('description')">
              <span v-if="articleDescription"> {{ articleDescription }}</span>
              <span v-else>&nbsp;</span>
            </p>
          </div>
          <div class="martech-card__footer">
            <div class="martech-author">
              <div v-if="isSyndication || !allowLinks || !allowAuthorLink" class="martech-text-sm"
                :data-testid="componentID('author')">
                <author-image v-if="detailed" :source="authorImage" />
                <template v-else>
                  By
                </template>
                {{ authorName }}
              </div>
              <base-link v-else
                tabindex="-1"
                :link-url="authorUrl"
                class="martech-text-sm is-link"
                :url-target="urlTarget" :data-testid="componentID('author')"
                @keyup.enter.stop>
                <author-image v-if="detailed" :source="authorImage" :image-alt-name="authorName" />
                <template v-else>
                  By
                </template>
                {{ authorName }}
              </base-link>
            </div>
            <p v-if="showDate" class="martech-text-sm date" :data-testid="componentID('date')">
              {{ articleDate }}
            </p>
          </div>
        </div>
      </base-styles>
    </base-link>
  </on-enter-navigate-card>
</template>

<script setup>
import { computed } from 'vue';
import useComponentId from '@/use/useComponentId';
import useCardHelpers from '@/use/card/helpers';

import BaseLink from '@/components/elements/BaseLink.vue';
import BaseStyles from '@/components/BaseStyles.vue';
import FormatTag from '@/components/cards/card-parts/FormatTag.vue';
import AuthorImage from '@/components/cards/card-parts/AuthorImage.vue';
import RightTextOverlay from '@/components/cards/card-parts/RightTextOverlay.vue';
import OnEnterNavigateCard from '@/components/cards/card-parts/OnEnterNavigateCard.vue';
import ProIcon from '@/components/cards/card-parts/ProIcon.vue';
import VideoPlayIcon from '@/components/iconography/VideoPlayIcon.vue';
import ManaBars from '@/components/cards/card-parts/ManaBars.vue';

const props = defineProps({
  articleCardLink: {
    type: [Object, String],
    required: true,
  },
  articleData: {
    type: Object,
    required: true,
    default: null,
  },
  authorUrl: {
    type: [Object, String],
    required: false,
    default: null,
  },
  showVerticalLink: {
    type: Boolean,
    required: false,
    default: false,
  },
  showDate: {
    type: Boolean,
    required: false,
    default: true,
  },
  source: {
    type: String,
    required: true,
    default: '',
  },
  title: {
    type: String,
    required: true,
  },
  showOverlay: {
    type: Boolean,
    required: false,
    default: true,
  },
  onClick: {
    type: Function,
    required: false,
    default: (article) => { },
  },
  urlTarget: {
    type: [String, Boolean],
    default: false,
    required: false,
  },
  isSyndication: {
    type: Boolean,
    default: false,
  },
  allowAuthorLink: {
    type: Boolean,
    default: true,
  },
  allowLinks: {
    type: Boolean,
    default: true,
  },
  linkPath: {
    type: String,
    default: '',
  },
  uniformHeight: {
    type: Boolean,
    default: false,
  },
  detailed: {
    type: Boolean,
    default: true,
  },
  allowProIcon: {
    type: Boolean,
    default: false,
  },
  showManaBars: {
    type: Boolean,
    default: false,
  },
  heroHeight: {
    type: String,
    default: undefined,
  },
});

const { componentID } = useComponentId();

const {
  articleDate,
  contentType,
  authorImage,
  authorName,
  articleDescription,
  articleReadTime,
  format,
  vertical,
  overlayImage,
  taxonomyName,
  isPro,
  articleTypeTag,
  errorImageFallback,
  fallbackImage,
  showNewTag,
  verticalLink,
  internalTags } = useCardHelpers(props);

const podcast = 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/podcast-icon2.svg';
const overlaySize = '';
</script>

<style lang="scss" scoped>
:deep(.martech-base-link) {
  span {
    width: 100%;
  }
}

.martech-card {
  max-width: 100%;
  position: relative;
  z-index: 1;
  border-radius: $martech-radius-medium;
  overflow: hidden;
  color: $martech-text-primary;
  background: $martech-white;

  .martech-card-hero {
    position: relative;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $martech-black;

    figure {
      margin: 0;
    }

    .budget-icon {
      width: 40px;
      position: absolute;
      z-index: 2;
      left: $martech-spacer-2;
      top: $martech-spacer-2;
      filter: drop-shadow(0 0 2.5px $martech-black);

      &.with-pro {
        top: $martech-spacer-5;
      }
    }

    .martech-overlay {
      width: 60%;
      position: absolute;
    }

    .martech-image {
      &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__item {
        width: 100%;
        transition: all 0.15s ease-in-out;
        object-fit: cover;
        object-position: 25% 20%;
        height: 128px;

        &.minimal-details {
          height: 100px;
        }
      }

      &__play-icon {
        position: absolute;
      }
    }
  }

  .martech-podcast {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 64px;
    }
  }

  &__content {
    padding: $martech-spacer-3 $martech-spacer-3 $martech-spacer-2 $martech-spacer-3;

    &.uniform-height {
      &.has-vertical {
        min-height: 133px;
      }

      min-height: 115px;
    }

    &.uniform-height-no-description {
      min-height: 72px;
    }

    @include breakpoint(768) {
      &.has-vertical {
        min-height: 133px;
      }

      min-height: 115px;
    }

    .martech-vertical {
      line-height: 14px;
      padding-bottom: $martech-spacer-1;
    }

    h2 {
      margin-top: 0;
      margin-bottom: $martech-spacer-1;
      font-family: $martech-display-barlow;

      &.minimal-details {
        font-size: $martech-type-18;
      }
    }

    p {
      font-family: $martech-display-inter;
      line-height: $martech-type-16;
      margin: 0;
      word-break: break-word;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $martech-spacer-3 $martech-spacer-3 $martech-spacer-3;
    font-family: $martech-display-inter;

    .date {
      white-space: nowrap;
    }

    .martech-author {
      .martech-image__wrapper {
        margin-right: $martech-spacer-2;
      }

      span.martech-text-sm {
        :deep(a) {
          display: flex;
          align-items: center;
          color: $martech-text-primary;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .martech-text-sm {
        display: flex;
        align-items: center;
        color: $martech-text-primary;

        &.is-link:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
