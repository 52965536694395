<template>
  <base-styles>
    <div class="martech-deck-embed">
      <div class="martech-card martech-light">
        <div class="martech-card__header">
          <deck-embed-header :data="deckData" :vertical-name="verticalName" export-buttons :page-type="pageType"
            :analytics-data="analyticsData" @buy-deck-event="$emit('buy-deck-event')" />
        </div>
        <!--deck embed body-->
        <div class="martech-card__body">
          <div :id="deckId" class="martech-deck-embed-body">
            <div class="martech-deck-embed-body__list-column">
              <div class="header">
                <div class="count">
                  <p>{{mainDeckName}}, {{ maindeckQuantity }} cards</p>
                </div>
                <div class="sort-group">
                  <div class="sort">
                    <p class="martech-text-sm martech-text-semibold">
                      Sort
                    </p>
                    <default-select id="sort-filter"
                      v-model="sort" :options="sortOptions" :value="sort" :initial="sort" hide-label
                      :map-label="sortOptions" label="sort deck" :on-change="onChange"
                      :gtm="{ category: 'Article Page', label: 'Deck Embed', action: 'Sort Clicked' }" />
                  </div>
                  <base-button v-if="deviceType === 'mobile'" class="collapse-button" btn-size="martech-medium"
                    btn-style="martech-white" @clicked="toggleSubDeck">
                    {{ collapsed ? 'Expand All' : 'Collapse All' }}
                  </base-button>
                </div>
              </div>
              <template
                v-for="(type, index) in ['skillcard', 'commandzone', 'maindeck', 'sideboard', 'extradeck', 'sidedeck']">
                <deck-embed-sub-deck v-if="deckData.subDecks[type]" :key="`${type}-${index}`" :data="subDeck(type)"
                  :typed="type === 'maindeck'" :companion-id="deckData.companionID"
                  :set-active-card-callback="setActiveCardCallback" :set-active-modal="setActiveModal"
                  :active-card-url="activeCardUrl" :sort="sort" :collapse-all="collapsed"
                  @card-buy-click-event="$emit('card-buy-click-event')" />
              </template>
            </div>
            <div class="martech-deck-embed-body__details-column">
              <desktop-card-details v-show="activeCard" :deck-id="deckId" :page-type="pageType"
                :product-url="activeCardUrl" :card-data="activeCard" :dashboard="dashboard"
                @clicked-desktop="$emit('card-buy-click-event')" @magnify="$emit('magnify')" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-styles>
</template>

<script>
import BaseStyles from '@/components/BaseStyles.vue';
import BaseButton from '@/components/elements/BaseButton.vue';
import DeckEmbedHeader from '@/components/embeds/deck-parts/DeckEmbedHeader.vue';
import DefaultSelect from '@/components/form-elements/DefaultSelect.vue';
import DesktopCardDetails from '@/components/embeds/deck-parts/DesktopCardDetails.vue';
import DeckEmbedSubDeck from '@/components/embeds/deck-parts/DeckEmbedSubDeck.vue';
import deviceType from '@/mixins/deviceType';
import MagicHelpers from '@/lib/magic';
import YugiohHelpers from '@/lib/yugioh';
import PokemonHelpers from '@/lib/pokemon';
import LorcanaHelpers from '@/lib/lorcana';
import FleshAndBloodHelpers from '@/lib/fleshandblood';

export default {
  name: 'deck-embed',
  components: {
    DeckEmbedHeader,
    BaseStyles,
    BaseButton,
    DefaultSelect,
    DesktopCardDetails,
    DeckEmbedSubDeck,
  },
  mixins: [deviceType],
  props: {
    deckId: {
      type: String,
      required: true,
      default: '',
    },
    verticalName: {
      type: String,
      required: true,
      default: 'Magic',
    },
    onChange: {
      type: Function,
      required: true,
      default: () => { },
    },
    deckData: {
      type: Object,
      required: true,
      default: () => { },
    },
    pageType: {
      type: String,
      required: false,
      default: '',
    },
    analyticsData: {
      type: Object,
      required: false,
      // eslint-disable-next-line
      default: () => { return { analyticsCampaign: '', analyticsSource: '', analyticsMedium: '' }; }
    },
    setActiveCardCallback: {
      type: Function,
      required: false,
      default: () => { },
    },
    setActiveModal: {
      type: Function,
      required: false,
      default: () => { },
    },
    activeCardUrl: {
      type: String,
      required: false,
      default: '',
    },
    sortDeck: {
      type: String,
      required: false,
      default: 'overview',
    },
    abbreviationManaColorToName: {
      type: Function,
      required: false,
      default: () => { },
    },
    maindeckQuantity: {
      type: Number,
      required: false,
      default: null,
    },
    activeCard: {
      type: Object,
      required: false,
      default: () => { },
    },
    dashboard: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      sort: 'overview',
      collapsed: false,
    };
  },
  beforeUpdate() {
    this.sort = this.sortDeck;
  },
  computed: {
    mainDeckName() {
      switch (this.verticalName.toLowerCase()) {
        case 'magic': return 'Maindeck';
        case 'yugioh': return 'Main Deck';
        default: return 'Decklist';
      }
    },
    sortOptions() {
      switch (this.verticalName.toLowerCase()) {
        case 'magic': return MagicHelpers.getDeckSortOptions();
        case 'yugioh': return YugiohHelpers.getDeckSortOptions();
        case 'pokemon': return PokemonHelpers.getDeckSortOptions();
        case 'lorcana': return LorcanaHelpers.getDeckSortOptions();
        case 'flesh and blood': return FleshAndBloodHelpers.getDeckSortOptions();
        default: return [];
      }
    },
  },
  methods: {
    toggleSubDeck() {
      this.collapsed = !this.collapsed;
    },
    subDeck(name) {
      return {
        deckID: this.deckData.id,
        game: this.deckData.game,
        format: this.deckData.format,
        name,
        cards: this.deckData.subDecks[name],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.martech-deck-embed {
  width: 100%;
  padding: $martech-spacer-3 0;
  display: block;
  margin: 0 auto;
  position: relative;
  z-index: 0;
  font-family: $martech-display-inter;

  .martech-card {
    overflow: visible;

    @include breakpoint(1024) {
      border-radius: $martech-radius-large;
      border: 1px solid $martech-border;
    }

    &__body {
      overflow: hidden;
      padding: $martech-spacer-4 0;

      @include breakpoint(1024) {
        min-height: 630px;
        padding: $martech-spacer-4;
      }
    }
  }

  &-list {
    list-style: none;
    padding-bottom: $martech-spacer-3;
  }

  &-body {
    position: relative;
    display: flex;

    .header {
      padding: 0 0 $martech-spacer-3 0;
      display: flex;
      align-items: center;
      flex-direction: column;

      @include breakpoint(1024) {
        flex-direction: row;
        justify-content: space-between;
      }

      .count {
        padding-right: $martech-spacer-2;
        width: 100%;

        @include breakpoint(1024) {
          padding-right: 0;
        }

        p {
          font-family: $martech-display-inter;
          color: $martech-text-primary;
          font-size: $martech-type-16;
          line-height: $martech-type-22;
          letter-spacing: 0.2px;
          font-weight: $martech-weight-semibold;
          display: flex;
          align-items: center;

          .dot {
            font-size: $martech-type-30;
            line-height: 24px;
            padding: 0 $martech-spacer-1;
          }
        }
      }

      .sort-group {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-top: $martech-spacer-3;
        width: 100%;

        @include breakpoint(768) {
          margin-top: 0;
        }
      }

      .sort {
        margin-right: $martech-spacer-3;

        @include breakpoint(768) {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-right: 0;
        }

        p {
          white-space: nowrap;
          margin-right: $martech-spacer-2;
          color: $martech-text-primary;
        }
      }

      :deep(.martech-button) {
        width: 136px;
      }
    }

    &__details-column {
      position: relative;
      z-index: 1;

      @include breakpoint(1024) {
        width: 320px;
      }
    }

    &__list-column {
      width: 100%;

      @include breakpoint(1024) {
        margin-right: $martech-spacer-4;
      }
    }

    .sideboard,
    .sidedeck {
      :deep(.list) {
        padding-bottom: 0;
      }
    }
  }
}
</style>
