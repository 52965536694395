<template>
  <base-styles>
    <div class="martech-new-tag" :data-testid="componentID()"
      :class="{ 'orange-brand': siteConfig('global.orangeBrand') }">
      <p class="martech-text-sm martech-text-uppercase">
        {{ text }}
      </p>
    </div>
  </base-styles>
</template>

<script>
import BaseStyles from '@/components/BaseStyles.vue';
import componentId from '@/mixins/componentId';
import useSiteConfig from '@/use/siteConfig';

export default {
  name: 'new-tag',
  components: {
    BaseStyles,
  },
  props: {
    text: {
      type: String,
      default: 'New',
      required: true,
    },
  },
  setup() {
    const componentID = componentId;
    const { siteConfig } = useSiteConfig();
    return { componentID, siteConfig };
  },
};
</script>

<style lang="scss" scoped>
.martech-new-tag {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  background-color: $martech-pink;
  height: 1.5rem;
  width: 4rem;
  border-top-right-radius: $martech-radius-medium;
  border-bottom-left-radius: $martech-radius-default;
  display: flex;
  justify-content: center;
  align-items: center;

  &.orange-brand {
    background: linear-gradient(180deg, #F36D21 0%, #FAA01A 100%);

    p {
      color: $martech-text-primary;
    }
  }

  p {
    margin: 0;
    text-align: center;
    color: $martech-white;
    font-weight: $martech-weight-bold;
  }
}
</style>
