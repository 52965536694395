<template>
  <span><slot/></span>
</template>
<script>
// This component is used to make our global scss available to our components
// without bloating every component by injecting duplicate styles into every
// component.
// Changed from rendering just a slot to adding a span because v-show can't apply to
// it when it is just a slot in Vue 3.
export default {
  name: 'base-styles',
  created() {
    this.$emit('martech-scope-id', this.$options.__scopeId);
  },
};
</script>

<style lang="scss">
@import "../../node_modules/@tcgplayer/martech-styles/martech-3rd-party.scss";
</style>
