<template>
  <base-styles>
    <div class="martech-close-button" :class="{ dark, background }" :data-testid="componentID()">
      <button class="close-button" type="button" :aria-label="`Close${name ? ` ${name}` : ''}`">
        <close-icon/>
      </button>
    </div>
  </base-styles>
</template>

<script setup>
import useComponentId from '@/use/useComponentId';

import CloseIcon from '@/components/iconography/CloseIcon.vue';
import BaseStyles from '@/components/BaseStyles.vue';

const { componentID } = useComponentId();

defineProps({
  name: {
    type: String,
    default: '',
  },
  dark: {
    type: Boolean,
    default: false,
  },
  background: {
    type: Boolean,
    default: false,
  }
});
</script>

<style lang="scss" scoped>
.martech-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;

  :deep(.close-button) {
    background-color: transparent;
  }

  &.background {
    :deep(.close-button) {
      background-color: #fff;
    }
  }

  &.dark {
    &.background {
      :deep(.close-button) {
        background-color: #000;
      }
    }

    :deep(.close-button) {
      color: #fff;
    }
  }
}
</style>
