<template>
  <!--eslint-disable-next-line max-len-->
  <svg id="star-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.44 32" :data-testid="componentID()"><g data-name="Layer 2"><path d="M18.51 1.11l4.08 8.28 9.13 1.33a2 2 0 011.11 3.41l-6.61 6.44 1.57 9.09a2 2 0 01-2.9 2.11l-8.17-4.29-8.17 4.29a2 2 0 01-2.9-2.11l1.56-9.09-6.6-6.44a2 2 0 011.1-3.41l9.13-1.33 4.08-8.28a2 2 0 013.59 0z" data-name="Layer 1" fill="currentColor"/></g></svg>
</template>

<script>
import componentId from '@/mixins/componentId';

export default {
  name: 'event-star',
  setup() {
    const componentID = componentId;
    return { componentID };
  },
};
</script>

<style lang="scss" scoped>
 #star-icon {
     height: 20px;
     width: 20px;
     filter: drop-shadow( 1px 1px 0px $martech-black);
 }
</style>
