<template>
  <!--eslint-disable-next-line-->
  <svg id="danger-outline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31" :data-testid="componentID()"><path d="M31 15.5A15.5 15.5 0 1 1 15.5 0 15.5 15.5 0 0 1 31 15.5Zm-3 0A12.5 12.5 0 1 0 15.5 28 12.49 12.49 0 0 0 28 15.5Zm-12.5 8.62a2.62 2.62 0 1 1 2.62-2.62 2.62 2.62 0 0 1-2.62 2.62ZM13.79 7.5h3.42a.75.75 0 0 1 .75.79l-.42 8.5a.75.75 0 0 1-.75.71h-2.58a.75.75 0 0 1-.75-.71L13 8.29a.75.75 0 0 1 .79-.79Z" fill="currentColor" data-name="Layer 1"/></svg>
</template>]

<script>
import componentId from '@/mixins/componentId';

export default {
  name: 'danger-outline-icon',
  setup() {
    const componentID = componentId;
    return { componentID };
  },
};
</script>
