<template>
  <base-styles>
    <div class="martech-default-input" :data-testid="componentID()">
      <label v-if="hideLabel" :for="id" class="sr-only">{{ label }}</label>
      <div v-if="!hideLabel && label || required" class="martech-input-label" :class="{'is-hovered' : hovered}">
        <label :for="id" class="martech-detail" :class="{'label--is-error' : error}">{{ label }}</label>
        <span class="asterisk">
          <asterisk-icon v-if="required" class="required-marker"/>
        </span>
      </div>
      <div class="martech-default-input--wrapper" :class="[{'left' : button.position === 'left'}, {'right' : button.position === 'right'}]">
        <div class="t-input"
             :class="[{ 'input--is-focused': focused },
                      { 'input--is-success': success },
                      {'input--is-error' : error || inputValidationError || inputIsRequired},
                      {'input--button' : showButton('left') || showButton('right')},
                      {'input--checkbox' : type === 'checkbox' }]"
             @mouseenter="hovered = true"
             @mouseleave="hovered = false">
          <div class="input-control">
            <div class="input-slot">
              <div class="input-wrap" :class="inputSize">
                <input
                  :id="id"
                  ref="input"
                  :class="{ 'input--is-active': modelValue !== '', hasData: hasData(),
                            'input--is-required': inputIsRequired,
                            'input--is-error' : inputMaxNumberError || inputValidationError}"
                  class="input"
                  :data-testid="componentID(id)"
                  :value="modelValue"
                  :placeholder="placeholder"
                  :checked="type === 'checkbox' ? !!modelValue : null"
                  :type="type"
                  :name="label || id"
                  :maxlength="maxLength"
                  :min="minNumber"
                  :max="maxNumber"
                  @focus="setFocus()"
                  @blur="onBlur"
                  @input="onInput"
                  @keyup.enter.stop.prevent="submit">
                {{ text }}
                <img v-show="inputIcon" :src="inputIcon" alt="input icon" class="martech-input-icon">
              </div>
            </div>
          </div>
        </div>
        <base-button v-if="showButton('right') || showButton('left')"
                     class="martech-default-input__button"
                     :class="[{'left' : button.position === 'left'}, {'right' : button.position === 'right'}]"
                     :btn-style="button.style"
                     :btn-size="button.size"
                     @clicked="submit">
          <img v-if="button.icon" :src="button.icon" :alt="button.alt" class="martech-default-input__button--icon"> {{ button.label }}
        </base-button>
      </div>
      <transition name="show-alert">
        <div v-show="message || error || inputMaxNumberError || inputValidationError" class="input-message">
          <p v-if="error || inputMaxNumberError || inputValidationError" class="is-error" :class="inputTheme" role="alert">
            <danger-outline-icon class="is-error__icon"/>{{ errorMessage || validationMessage || inputMaxNumberErrorMessage }}
          </p>
          <p v-else>
            {{ message }}
          </p>
        </div>
      </transition>
    </div>
  </base-styles>
</template>

<script>
import BaseStyles from '@/components/BaseStyles.vue';
import AsteriskIcon from '@/components/iconography/AsteriskIcon.vue';
import BaseButton from '@/components/elements/BaseButton.vue';
import DangerOutlineIcon from '@/components/iconography/alert-icons/DangerOutlineIcon.vue';
import componentId from '@/mixins/componentId';

export default {
  name: 'default-input',
  components: {
    BaseStyles,
    AsteriskIcon,
    BaseButton,
    DangerOutlineIcon,
  },
  props: {
    modelValue: {
      type: [ String, Number, Boolean ],
      default: '',
    },
    id: {
      type: String,
      default: 'input',
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    size: {
      type: String,
      default: 'martech-medium',
    },
    label: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: String,
      default: '',
      note: 'validation should be a regular expression that returns a non-empty array when calling String.prototype.match() on the input value',
    },
    validationMessage: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
      note: 'use to add text to a checkbox or something similar',
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
    button: {
      type: Object,
      default: () => ({
        icon: '',
        label: '',
        position: 'right',
        size: 'medium',
        style: 'martech-primary',
      }),
    },
    maxLength: {
      type: Number,
      default: 1000,
    },
    minNumber: {
      type: Number,
      default: 1,
    },
    maxNumber: {
      type: Number,
      default: 1000,
    },
    inputIcon: {
      type: String,
      default: '',
      note: 'Will add an icon to the inside the right side of an input.',
    },
    placeholder: {
      type: String,
      default: '',
      note: 'Pass a placeholder to the input',
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    themeColor: {
      type: String,
      default: 'default',
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  data() {
    return {
      focused: false,
      inputIsRequired: false,
      inputMaxNumberError: false,
      inputValidationError: false,
      inputMaxNumberErrorMessage: '',
      hovered: false,
    };
  },
  computed: {
    inputSize() {
      switch (this.size) {
        case 'martech-large':
        case 'martech-small':
          return this.size;
        default:
          return 'martech-medium';
      }
    },
    inputTheme() {
      switch (this.themeColor) {
        case 'dark':
        case 'mid':
          return this.themeColor;
        default:
          return 'default';
      }
    },
  },
  methods: {
    onInput(ev) {
      this.$emit('change', ev.target.value, ev);
      this.$emit('update:modelValue', ev.target.value);
      this.validate();
    },
    setFocus() {
      this.$refs.input.focus();
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
      this.$emit('blur');
      this.validate();
    },
    showButton(position) {
      return this.button.label && position === this.button.position;
    },
    submit() {
      return this.onSubmit(this.$refs.input.value) || this.$emit('submit');
    },
    hasData() {
      // Clears required if user has added text into input
      if (this.modelValue) {
        this.inputIsRequired = false;
      }

      if (this.inputValidationError) {
        this.inputValidationError = !this.modelValue.match(this.validation);
      }

      return !!this.modelValue;
    },
    validate() {
      const empty = !this.modelValue || this.modelValue.length <= 0;

      // If input is empty on blur & required, highlight input
      if (this.required && empty) {
        this.inputIsRequired = true;
        this.$emit('is-valid', false);
        return;
      }

      if (empty) {
        this.$emit('is-valid', true);
        return;
      }

      this.inputIsRequired = false;
      this.inputValidationError = false;
      this.inputMaxNumberError = false;
      this.inputMaxNumberErrorMessage = '';

      if (this.type === 'number') {
        const val = parseInt(this.modelValue, 10);
        if (val < this.minNumber || val > this.maxNumber) {
          this.inputMaxNumberError = true;
          this.inputMaxNumberErrorMessage = `Number must be in the range between ${this.minNumber} and ${this.maxNumber}.`;
          this.$emit('is-valid', false);
          return;
        }
      }

      if (this.validation) {
        this.inputValidationError = !this.modelValue.match(this.validation);
        this.$emit('validation-error', this.inputValidationError);
        this.$emit('is-valid', !this.inputValidationError);
        return;
      }

      this.$emit('is-valid', true);
    },
  },
};
</script>

<style lang="scss" scoped>
.martech-default-input {
  width: 100%;

  .input-message {
    padding: $martech-spacer-2 0 0;
    margin-bottom: 0;

    p {
      color: $martech-text-subdued;
      letter-spacing: 0.5px;
      margin: 0;
      font-family: $martech-display-inter;
      display: flex;
      align-items: center;
      font-size: $martech-detail;

      &.is-error {
        color: $martech-system-danger-title;

        &.dark {
          color: $martech-system-danger-border;
        }
      }

      .is-error__icon {
        height: 12px;
        width: 12px;
        margin-right: $martech-spacer-2;
      }
    }
  }

  .martech-input-label {
    color: $martech-text-subdued;
    font-family: $martech-display-inter;
    font-weight: $martech-weight-semibold;
    transition: color 300ms ease-in-out;

    &.is-hovered {
      color: $martech-text-primary;
    }

    .label--is-error {
      color: $martech-system-danger-title;
    }

    .asterisk {
      color: $martech-system-danger;
      padding-left: $martech-spacer-1;
    }

    .required-marker {
      width: 12px;
      height: 12px;
    }
  }

  .martech-input-icon {
    height: 21px;
    margin-right: $martech-spacer-2;
  }

  &__button {
    &.right {
      :deep(button) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: $martech-radius-medium;
        border-bottom-right-radius: $martech-radius-medium;
      }
    }

    &.left {
      :deep(button) {
        border-top-left-radius: $martech-radius-medium;
        border-bottom-left-radius: $martech-radius-medium;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &--icon {
      width: 12px;
      height: 12px;
      margin-right: $martech-spacer-2;
    }
  }

  &--wrapper {
    display: flex;
    margin-top: $martech-spacer-1;

    &.left {
      flex-direction: row-reverse;
      .input--button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-right: 0;
      }
    }

    &.right {
      .input--button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
      }
    }
  }

  .t-input {
    border: 1px solid $martech-border;
    font-size: 1rem;
    text-align: left;
    position: relative;
    transition: border 100ms ease-in;
    border-radius: 8px;
    display: flex;
    flex: 1 1 auto;
    background-color: $martech-surface;
    color: $martech-text-primary;

    &:hover {
      box-shadow: 0 0 2px 3px #eff0f1;
    }

    &.input--checkbox {
      display: flex;
      position: relative;
      height: 100%;
      align-items: center;
      cursor: pointer;
      font-size: 1rem;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: 0;

      &.input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &--is-focused {
          border: 0;
          box-shadow: none;
        }
      }

      .input-slot {
        padding: 0;

        .input-wrap {
          max-height: 24px;
          align-items: center;

          .input {
            min-width: 24px;
            max-width: 24px;
            padding: 0;
            margin: 0 1rem 0 0;
          }
        }
      }
    }

    &.input {
      &--is-focused {
        border: 1px solid $martech-blue-focus;
        box-shadow: none;
      }

      &--is-success {
        border: 1px solid $martech-system-success-border;
      }

      &--is-error,
      &--is-required {
        border: 1px solid $martech-system-danger-border;
      }
    }
  }
  .input-control {
    position: relative;
    color: inherit;
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: wrap;
    min-width: 0;
    width: 100%;

    .input-slot {
      cursor: text;
      background: transparent;
      align-items: stretch;
      border-radius: inherit;
      display: flex;
      color: inherit;
      position: relative;
      width: 100%;
    }

    .input-wrap {
      position: relative;
      display: flex;
      flex: 1 1 auto;
      align-items: center;

      &.martech-large {
        height: 48px;
      }

      &.martech-medium {
        height: 40px;
      }

      &.martech-small {
        height: 32px;
      }

      .wrapper {
        width: 100%;
      }

      input {
        background: transparent;
        flex: 1 1;
        position: relative;
        pointer-events: inherit;
        padding: 8px;
        line-height: 20px;
        border-radius: 0;
        border: transparent;
        width: 90%;
        box-shadow: none;
      }

      .input:focus-visible {
        outline: 0px;
      }

      .input:focus:before {
        width: 0%;
        content: "";
      }

      .icon {
        height: 21px;
        width: 12px;
      }
    }
  }
}

// Error/Success Messages
.show-alert-enter-active,
.show-alert-leave-active {
  transition: all 200ms ease-in;
}

.show-alert-enter,
.show-alert-leave-to {
  opacity: 0;
}
</style>
