<template>
  <base-styles>
    <div class="martech-video-player-embed"  :data-testid="componentID()">
      <video
        ref="video"
        controls
        playsinline
        :poster="poster"
        preload="metadata"
        :autoplay="autoplay"
        controlsList="nodownload"
        disablepictureinpicture
        onloadstart="this.volume=0.5">
          <source :src="mp4Url" type="video/mp4">
          <source :src="webmUrl" type="video/webm">
      </video>
    </div>
  </base-styles>
</template>

<script setup>
import { ref, onMounted, defineEmits } from 'vue';
import useComponentId from '@/use/useComponentId';
import BaseStyles from '@/components/BaseStyles.vue';

defineProps({
  mp4Url: {
      type: String,
      required: false,
      default: '',
    },
    webmUrl: {
      type: String,
      required: false,
      default: '',
    },
    poster: {
      type: String,
      required: false,
      default: '',
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false,
    }
});

const { componentID } = useComponentId();
const video = ref(null);

const emit = defineEmits([ 'ended' ]);

onMounted(() => {
  video.value.onended = () => {
    emit('ended', {
      currentTime: video.value?.currentTime || 0,
      duration: video.value?.duration || 0,
    });
  };
  video.value.onplay = () => {
    emit('play', {
      currentTime: video.value?.currentTime || 0,
      duration: video.value?.duration || 0,
    });
  };
  video.value.onprogress = () => {
    emit('progress', {
      currentTime: video.value?.currentTime || 0,
      duration: video.value?.duration || 0,
    });
  };
});
</script>

<style lang="scss" scoped>
.martech-video-player-embed {
  display: block;
  font-family: $martech-display-inter;
  height: 100%;
  margin: 0 auto;
  position: relative;
  text-align: center;
  z-index: 0;

  > video {
    height: 100%;
    width: 100%;
    object-fit: fill;
  }
}
</style>
