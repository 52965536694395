<template>
  <base-styles>
    <div
      class="martech-format-tag"
      :class="{
        'square-small': overlaySizes === 'square-small',
        'square-large': overlaySizes === 'square-large',
        'orange-brand': siteConfig('global.orangeBrand')
      }"
      :data-testid="componentID()">
      <p v-if="format" class="martech-detail martech-text-uppercase martech-format" :data-testid="componentID('format')">
        {{ format }}
      </p>
      <span v-if="readTime && contentType">
        <p v-if="articleReadTime" class="martech-text-sm read-time martech-text-capitalize" :data-testid="componentID('read-time')">
          <icon-base width="12" height="12" icon-name="read time">
            <clock-icon :data-testid="componentID('clock-icon')"/>
          </icon-base>{{ articleReadTime + ' ' + timePhrase }}
        </p>
      </span>
      <span v-if="isDeck">
        <p class="martech-text-sm decks martech-capitalize" :data-testid="componentID('deck-icon')">
          <icon-base width="16" height="16" icon-name="deck icon">
            <deck-icon/>
          </icon-base> Deck
        </p>
      </span>
    </div>
  </base-styles>
</template>

<script>
import IconBase from '@/components/iconography/IconBase.vue';
import ClockIcon from '@/components/iconography/ClockIcon.vue';
import DeckIcon from '@/components/iconography/DeckIcon.vue';
import BaseStyles from '@/components/BaseStyles.vue';
import componentId from '@/mixins/componentId';
import useSiteConfig from '@/use/siteConfig';

export default {
  name: 'format-tag',
  components: {
    BaseStyles,
    IconBase,
    ClockIcon,
    DeckIcon,
  },
  props: {
    format: {
      type: String,
      required: true,
      default: '',
    },
    articleReadTime: {
      type: Number,
      required: false,
      default: 0,
    },
    readTime: {
      type: Boolean,
      required: false,
    },
    isDeck: {
      type: Boolean,
      required: false,
    },
    overlaySize: {
      type: String,
      required: false,
      default: '',
    },
    contentType: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    const componentID = componentId;
    const { siteConfig } = useSiteConfig();
    return { componentID, siteConfig };
  },
  computed: {
    timePhrase() {
      switch (this.contentType) {
        case 'Article':
          return 'min read';
        case 'Video':
          return 'min watch';
        case 'Podcast':
          return 'min listen';
        default:
          return 'min read';
      }
    },
    overlaySizes() {
      switch (this.overlaySize) {
        case 'square-small':
          return 'square-small';
        default:
          return 'square-large';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.martech-format-tag {
  position: absolute;
  z-index: 15;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
  text-shadow: 1px 1px 2px #000000;
  height: 68px;
  width: 100%;
  font-family: $martech-display-inter;

  &.square-small {
    height: 48px;
  }

  &.orange-brand {
    .martech-format {
      background-color: #962220;
      padding: $martech-spacer-0 $martech-spacer-2;
      border-radius: $martech-radius-medium;
      font-size: $martech-type-10;
      line-height: 16px;
      font-weight: $martech-weight-semibold;
      letter-spacing: 1px;
      text-shadow: none;
      left: $martech-spacer-2;
      bottom: $martech-spacer-2;
    }
  }

  .martech-format {
    margin: 0;
    position: absolute;
    bottom: 0;
    font-weight: $martech-weight-extra-bold;
    padding-bottom: $martech-spacer-2;
    color: $martech-white;
    padding-left: $martech-spacer-3;
  }

  .read-time, .decks {
    margin: 0;
    color: $martech-white;
    font-weight: $martech-weight-thin;
    padding: 0 $martech-spacer-3 $martech-spacer-2 0;
    position: absolute;
    line-height: $martech-type-12;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;

    svg {
      margin-right: $martech-spacer-1;
    }
  }
}
</style>
