<template>
  <!--Full image event card w/ tournament tag for grid-->
  <base-styles>
    <on-enter-navigate-card :data="data" :is-syndication="isSyndication" :link-path="linkPath" :data-testid="componentID()">
      <base-link v-if="render" ref="root" :link-url="link || {path: canonical || '#'}" :url-target="urlTarget" class="event-link" tab-index="-1" @click="onClick(data)">
        <div class="event martech-shadow martech-card--is-link" :style="{minHeight: minCardHeight}" >
          <div v-if="level" class="event-level">
            <event-star v-for="(l, i) in eventLevelStars" :key="i"/>
          </div>
          <right-text-overlay v-if="showCardTag" text="Latest"/>
          <div class="content-wrapper">
            <span v-if="showVerticalLink" class="martech-labels martech-white">
              {{ verticalDisplayName }}
            </span>
            <h3 class="martech-text-lg martech-text-bold martech-barlow martech-two-lines martech-text-capitalize martech-hyphens" :data-testid="componentID('title')">
              {{ title }}
            </h3>
            <p class="date martech-text-sm" :data-testid="componentID('event-date')">
              {{ date }} <span v-if="players">| {{ players }} Players</span>
            </p>
            <div class="group">
              <p class="martech-format-tag" :data-testid="componentID('format')">
                {{ format }}
              </p>
            </div>
          </div>
          <img v-if="hasRoot" :src="imageURL(imageUrl, { maxDimensions: '600x600', quality: 75 }) || fallbackImage" alt="" class="background" @error="errorImageFallback($event)" :data-testid="componentID('hero-image')">
          <div class="background-overlay"/>
        </div>
      </base-link>
    </on-enter-navigate-card>
  </base-styles>
</template>

<script>
import delve from 'dlv';
import BaseLink from '@/components/elements/BaseLink.vue';
import BaseStyles from '@/components/BaseStyles.vue';
import RightTextOverlay from '@/components/cards/card-parts/RightTextOverlay.vue';
import showNewTag from '@/mixins/showNewTag';
import EventsIcon from '@/components/iconography/EventsIcon.vue';
import EventStar from '@/components/iconography/EventsStar.vue';
import image from '@/lib/image';
import OnEnterNavigateCard from '@/components/cards/card-parts/OnEnterNavigateCard.vue';
import events from '@/lib/events';
import dates from '@/lib/dates';
import verts from '@/lib/verticals';
import useComponentId from '@/use/useComponentId';
import alterImage from '@/mixins/image/alter.ts';

export default {
  name: 'card-grid-event',
  components: {
    BaseLink,
    BaseStyles,
    RightTextOverlay,
    EventsIcon,
    EventStar,
    OnEnterNavigateCard,
  },
  mixins: [ showNewTag, alterImage ],
  props: {
    data: {
      type: Object,
      required: true,
      default: null,
    },
    onClick: {
      type: Function,
      required: false,
      default: (data) => {},
    },
    minCardHeight: {
      type: String,
      default: '193px',
      required: false,
    },
    isSyndication: {
      type: Boolean,
      default: false,
    },
    link: {
      type: [ Object, String ],
      required: false,
      default: null,
    },
    urlTarget: {
      type: [ String, Boolean ],
      default: false,
      required: false,
    },
    linkPath: {
      type: String,
      default: '',
    },
    showVerticalLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      format: '',
      date: '',
      render: false,
      level: '',
      players: null,
      title: '',
      vertical: '',
      imageUrl: '',
      hasRoot: false,
    };
  },
  setup() {
    const { componentID } = useComponentId();
    return { componentID };
  },
  computed: {
    canonical() {
      return delve(this.data, 'canonicalURL', '').replace(delve(this.data, 'title'), encodeURIComponent(delve(this.data, 'title')));
    },
    eventLevelStars() {
      return events.getEventLevelNumber(this.level) || 0;
    },
    fallbackImage() {
      return image.getFallbackImage(this.vertical, 'card');
    },
    showCardTag() {
      return this.showNewTag && !delve(this.$route, 'meta.searchPage');
    },
    verticalDisplayName() {
      return verts.displayName(this.vertical);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.hasRoot = true;
    });
  },
  async created() {
    if (this.data) {
      this.render = true;

      this.date = dates.format(delve(this.data, 'date')
        || delve(this.data, 'event.eventDate')
        || delve(this.data, 'eventDate', new Date().toDateString()));
      this.format = delve(this.data, 'format') || delve(this.data, 'deckData.format') || delve(this.data, 'eventData.format') || delve(this.data, 'event.format', '');
      this.title = delve(this.data, 'title') || delve(this.data, 'event.name', '');
      this.vertical = delve(this.data, 'game')
        || delve(this.data, 'deckData.game')
        || delve(this.data, 'eventData.game')
        || delve(this.data, 'event.game')
        || delve(this.data, 'vertical');
      this.imageUrl = delve(this.data, 'imageURL') || delve(this.data, 'event.imageURL') || delve(this.data, 'eventData.imageURL', '');

      this.level = delve(this.data, 'level')
        || delve(this.data, 'event.level')
        || delve(this.data, 'eventData.level')
        || delve(this.data, 'eventLevel', '');

      this.players = this.data?.players || this.data?.event?.players || this.data?.eventData?.players || this.data?.eventPlayers;
    }
  },
  methods: {
    errorImageFallback(event) {
      event.target.src = this.fallbackImage;
    },
  },
};
</script>

<style lang="scss" scoped>
.event {
  width: 100%;
  position: relative;
  display: flex;
  border-radius: $martech-radius-medium;
  background-color: $martech-black;

  a {
    display: flex;
    width: 100%;
  }

  .event-level {
    position: absolute;
    left: 16px;
    top: 8px;
    z-index: 20;
    color: $martech-white;
    > svg {
      padding-right: 5px;
      text-shadow:  1px 1px 0px $martech-black;
    }
  }

  .content-wrapper {
    position: relative;
    z-index: 15;
    padding: $martech-spacer-3;
    align-self: flex-end;
    width: 100%;
    text-shadow: 1px 1px $martech-black;

    h3 {
      color: $martech-white;
      margin-bottom: $martech-spacer-2;
      word-break: break-word;
    }

    .date {
      color: $martech-white;
    }
  }
  .group {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;

    .icon {
      color: $martech-white;
      display: flex;
      align-items: center;

      svg {
        margin-right: $martech-spacer-2;
        width: 16px;
        height: 16px;
      }
    }
  }

  .background {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    object-fit: cover;
    object-position: top center;
    border-radius: $martech-radius-medium;
    top: 0;
  }

  .background-overlay {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0.8, 0.8));
      width: 100%;
      height: 130px;
      position: absolute;
      z-index: 10;
      align-self: flex-end;
      border-bottom-left-radius: $martech-radius-medium;
      border-bottom-right-radius: $martech-radius-medium;
      bottom: 0;
  }
}
</style>
