<template>
  <base-styles>
    <div class="martech-tooltip" :data-testid="componentID()">
      <div class="martech-tooltip__label" :aria-describedby="id" data-tooltip-placeholder>
        <p><info-icon v-if="icon" outline /> {{ label }}</p>
      </div>
      <div class="martech-tooltip__dropdown" data-tooltip-dropdown>
        <div role="tooltip" :id="id" class="martech-tooltip__message">
          {{ message }}
        </div>
      </div>
    </div>
  </base-styles>
</template>

<script setup>
import InfoIcon from '@/components/iconography/alert-icons/InfoIcon.vue';
import BaseStyles from '@/components/BaseStyles.vue';
import useComponentId from '@/use/useComponentId';
import { onMounted } from 'vue';

defineProps({
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    required: true,
  },
  icon: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const { componentID } = useComponentId();

onMounted(() => {
  const placeholder = document.querySelector('[data-tooltip-placeholder]');
  const dropdown = document.querySelector('[data-tooltip-dropdown]');

  function open() {
    document.classList.add('martech-tooltip__open');
    handleDropdownPosition();
  };

  function close() {
    document.classList.remove('martech-tooltip__open');
  };

  function toggle() {
    if (document.classList.contains('martech-tooltip__open')) {
      close();
    } else {
      open();
    }
  };

  function handleDropdownPosition() {
    const screenPadding = 16;

    const placeholderRect = placeholder.getBoundingClientRect();
    const dropdownRect = dropdown.getBoundingClientRect();

    const dropdownRightX = dropdownRect.x + dropdownRect.width;
    const placeholderRightX = placeholderRect.x + placeholderRect.width;

    if (dropdownRect.x < 0) {
      dropdown.style.left = '0';
      dropdown.style.right = 'auto';
      dropdown.style.transform = `translateX(${-placeholderRect.x + screenPadding}px)`;
    } else if (dropdownRightX > window.outerWidth) {
      dropdown.style.left = 'auto';
      dropdown.style.right = '0';
      dropdown.style.transform = `translateX(${(window.outerWidth - placeholderRightX) - screenPadding}px)`;
    }
  }

  function dismissAllTooltips(event) {
    if (typeof event.target.closest !== 'function') return;
    const currentTooltip = event.target.closest('martech-tooltip');

    document.querySelectorAll('.martech-tooltip__open').forEach(tooltip => {
      if (tooltip === currentTooltip) return;

      tooltip.classList.remove('martech-tooltip__open');
    });
  }

  placeholder.addEventListener('mouseover', () => handleDropdownPosition());
  placeholder.addEventListener('touchstart', () => toggle());
  document.addEventListener('touchstart', e => dismissAllTooltips(e));

});
</script>

<style lang="scss" scoped>
.martech-tooltip {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &__open {
    .martech-tooltip__dropdown {
      animation: tooltipFadeIn 300ms ease-in-out;
      display: block;
    }
  }

  &:hover {
    .martech-tooltip__dropdown {
      animation: tooltipFadeIn 300ms ease-in-out;
      display: block;
    }
  }

  &__label {
    &:before {
      content: '';
      display: flex;
      cursor: help;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 3;
    }
  }

  &__dropdown {
    display: none;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    padding-top: $martech-spacer-4;
  }

  &__message {
    color: $martech-white;
    background-color: $martech-black;
    padding: $martech-spacer-2 $martech-spacer-3;
    border-radius: $martech-radius-default;
    width: 300px;
    text-align: left;
    line-height: 18px;
    font-family: $martech-display-inter;
    font-weight: $martech-weight-normal;
    font-size: $martech-type-13;
  }
}


@keyframes tooltipFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
