<template>
  <base-styles>
    <delay-load @visible="onVisible">
      <div class="placeholder" :style="{width, height}" :data-testid="componentID()">
        <div class="image" :class="[{ halfpage: type === 'halfpage' }, { 'medium-rect': type === 'mediumRectangle' }, { leaderboard: type === 'leaderboard' }, {'placeholder-shimmer' : visible }]">
          <div v-if="video" class="video">
            <video-play-icon/>
          </div>
        </div>
        <div v-if="includeText" class="text-long" :class="{'placeholder-shimmer' : visible }"/>
        <div v-if="includeText" class="text-short" :class="{'placeholder-shimmer' : visible }"/>
        <div v-if="author" class="author-group">
          <div class="photo" :class="{'placeholder-shimmer' : visible }"/>
          <div class="group">
            <div class="name" :class="{'placeholder-shimmer' : visible }"/>
            <div class="date" :class="{'placeholder-shimmer' : visible }"/>
          </div>
        </div>
        <div v-if="button" class="button" :class="{'placeholder-shimmer' : visible }"/>
      </div>
    </delay-load>
  </base-styles>
</template>

<script>
import VideoPlayIcon from '@/components/iconography/VideoPlayIcon.vue';
import DelayLoad from '@/components/DelayLoad.vue';
import BaseStyles from '@/components/BaseStyles.vue';
import componentId from '@/mixins/componentId';

export default {
  name: 'full-art-placeholder',
  components: {
    VideoPlayIcon,
    DelayLoad,
    BaseStyles,
  },
  props: {
    width: {
      type: String,
      required: false,
      default: '400px',
      note: 'Set the width of the component',
    },
    height: {
      type: String,
      required: false,
      default: '450px',
      note: 'Set the height of the component',
    },
    type: {
      type: String,
      required: false,
      default: '',
      note: 'The type of image to display \'halfpage\', \'mediumRectangle\', \'leaderboard\'',
    },
    video: {
      type: Boolean,
      required: false,
      default: false,
      note: 'This is used to display a video play button for a video embed',
    },
    author: {
      type: Boolean,
      required: false,
      default: false,
      note: 'this is used to display an author placeholder for content cards with authors',
    },
    includeText: {
      type: Boolean,
      required: false,
      default: true,
      note: 'This is used to hide or show "Text" placeholders depending on card type',
    },
    button: {
      type: Boolean,
      required: false,
      default: false,
      note: 'This is used to display a button placeholder',
    },
    instantlyVisible: {
      type: Boolean,
      required: false,
      default: true,
      note: 'This does not wait for onVisible to display',
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  data() {
    return {
      visible: false,
    };
  },
  created() {
    if (this.instantlyVisible) {
      this.visible = true;
    }
  },
  methods: {
    onVisible() {
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.placeholder {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background-color: $martech-surface;
  border-radius: $martech-radius-medium;

  .image {
    border-radius: $martech-radius-medium;
    width: 100%;
    height: 100%;
    min-height: 200px;
    position: relative;

    &.halfpage {
      height: 600px;
      width: 300px;
    }

    &.medium-rect {
      height: 300px;
      width: 250px;
    }

    &.leaderboard {
      height: 50px;
      width: 320px;
      @include breakpoint(1024) {
        height: 90px;
        width: 728px;
      }
    }

    .video {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .text-long,
  .text-short {
    border-radius: $martech-radius-default;
  }

  .text-long {
    width: 80%;
    height: 15px;
    margin-bottom: 0.75rem;
    margin-top: $martech-spacer-3;
  }

  .text-short {
    width: 50%;
    height: 10px;
  }

  .author-group {
    display: flex;
    padding-top: $martech-spacer-3;
    align-items: flex-end;
    .photo {
      width: 52px;
      height: 52px;
      border-radius: 50%;
    }

    .group {
      padding-left: $martech-spacer-2;
      padding-bottom: $martech-spacer-1;
    }

    .name {
      height: 10px;
      width: 100px;
      border-radius: 8px;
    }

    .date {
      height: 8px;
      width: 50px;
      border-radius: 8px;
      margin-top: $martech-spacer-2;
    }
  }
  .button {
    margin-top: $martech-spacer-3;
    width: 90%;
    border-radius: 8px;
    height: 40px;
  }
  // Placeholder Animation
  .placeholder-shimmer {
    background: linear-gradient(-45deg, $martech-gray-30, $martech-gray-5, $martech-gray-30);
    background-position: left;
    background-size: 400%;
    animation: placeholder-shimmer 3.25s cubic-bezier(.39,.15,.64,.86) infinite;
  }

  @keyframes placeholder-shimmer {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}
</style>
