<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 28">
    <!--eslint-disable-next-line max-len-->
    <path d="M20.5 28c-1.1 0-2-.9-2-2V3h-7v10.5c0 1.1-.9 2-2 2h-9c-.28 0-.5-.22-.5-.5v-2c0-.28.22-.5.5-.5h8V2c0-1.1.9-2 2-2h9c1.1 0 2 .9 2 2v23h7V14.5c0-1.1.9-2 2-2h9c.28 0 .5.22.5.5v2c0 .28-.22.5-.5.5h-8V26c0 1.1-.9 2-2 2h-9Z" fill="currentColor"/>
  </svg>
</template>
<script>
export default {
  name: 'graph-icon',
};
</script>