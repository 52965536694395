<template>
  <base-styles>
    <delay-load @visible="onVisible">
      <base-link
        v-if="loaded === true && promoData.imageURL"
        :link-url="promoData.destinationURL"
        :url-target="urlTarget"
        :new-window="newTab"
        :data-testid="componentID()"
        @click="promoClicked('Grid')">
        <div ref="promoContainer" class="martech-card light martech-shadow card--is-link">
          <div class="card-hero">
            <figure class="image__wrapper">
              <img class="image__item"
                   :src="promoData.imageURL || promoData.cardFallbackImage"
                   :alt="promoData.title"
                   :class="[{'has-teaser' : promoData.teaser}, {'has-all-text' : promoData.teaser && promoData.vendorLogoURL}]"
                   @error="errorImageFallback($event)">
            </figure>
            <p v-if="promoData.isSponsored" class="martech-text-sm sponsored-text">Sponsored</p>
            <div class="card-overlay"/>
          </div>
          <div class="martech-card__content">
            <h3 class="martech-two-lines">
              {{ promoData.title }}
            </h3>
            <p>
              {{ promoData.teaser }}
            </p>
          </div>
          <div v-if="promoData.vendorLogoURL" class="martech-card__footer">
            <img :src="promoData.vendorLogoURL" :alt="promoData.vendorName" class="logo">
            <p>
              {{ promoData.vendorName }}
            </p>
          </div>
          <close-button v-if="closeable" name="Promo" :dark="isDark" :background="hasBackground" @click.stop.prevent="close"/>
        </div>
      </base-link>
    </delay-load>
  </base-styles>
</template>

<script setup>
import { ref } from 'vue';

import useComponentId from '@/use/useComponentId';
import usePromoCore from '@/use/promo/core';
import usePromoCard, { promoCardProps } from '@/use/promo/card';

import BaseStyles from '@/components/BaseStyles.vue';
import BaseLink from '@/components/elements/BaseLink.vue';
import DelayLoad from '@/components/DelayLoad.vue';
import CloseButton from '@/components/elements/CloseButton.vue';

const props = defineProps(Object.assign({
  noPromoAvailable: {
    type: Function,
    default: () => {},
  },
  closeable: {
    type: String,
    default: '',
    validator(val) {
      return ['dark', 'light', 'dark-background', 'light-background', ''].includes(val);
    },
  },
}, promoCardProps));

const { componentID } = useComponentId();
const promoContainer = ref(null);
const emit = defineEmits(['no-data', 'loaded']);
const { loaded, promoData, close, isDark, hasBackground, fetchPromos } = usePromoCore(props, promoContainer, emit);
const { urlTarget, onVisible, promoClicked, errorImageFallback } = usePromoCard(props, promoData, fetchPromos, loaded);
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}

.martech-card {
  max-width: 100%;
  position: relative;
  z-index: 1;
  border-radius: $martech-radius-medium;
  overflow: hidden;
  will-change: box-shadow;
  transition: box-shadow 0.2s;
  background: #fff;

  &:hover {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  @include breakpoint(1024) {
    max-width: 400px;
  }

  .card-hero {
    position: relative;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    background-color: $martech-black;

    figure {
      margin: 0;
      background-color: $martech-black;
    }

    .image {
      &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__item {
        width: 100%;
        transition: all 0.15s ease-in-out;
        object-fit: cover;
        object-position: center;
        height: 200px;

        &.has-teaser {
          height: 217px;
        }

        &.has-all-text {
          height: 164px;
        }
      }
    }

    p {
      position: absolute;
      right: $martech-spacer-3;
      bottom: $martech-spacer-2;
      color: $martech-white;
      text-shadow: 1px 1px 0 $martech-black, 0 0 16px $martech-black;
      z-index: 5;
    }

    .card-overlay {
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 68px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.75)
      );
    }
  }

  &__content {
    color: $martech-text-primary;
    padding: $martech-spacer-3;
    font-family: $martech-display-inter;

    h3 {
      margin-bottom: $martech-spacer-1;
      font-size: $martech-body;
      letter-spacing: 0.6px;
      margin-top: 0;
    }

    p {
      font-size: $martech-type-12;
      margin: 0;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    padding: 0 $martech-spacer-3 $martech-spacer-3;

    .logo {
      width: auto;
      height: 15px;
    }

    p {
      margin: 0;
      font-size: 0.75rem; // 12px
      font-family: $martech-display-inter;
      line-height: 1rem; // 16px
      font-weight: $martech-weight-normal;
      color: $martech-gray-140;
      letter-spacing: 0.2px;
      padding-left: $martech-spacer-2;
    }
  }
}
</style>
