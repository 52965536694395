<template>
  <!--eslint-disable-next-line-->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 32" class="martech-warning" :data-testid="componentID()"><path d="M33 32H3a3 3 0 0 1-2.6-4.5l15-26a3 3 0 0 1 5.2 0l15 26A3 3 0 0 1 33 32Zm-17.9-7a2.9 2.9 0 1 0 2.9-2.9 2.9 2.9 0 0 0-2.9 2.9Zm.6-4.7a.8.8 0 0 0 .8.7h3a.8.8 0 0 0 .7-.7l.5-8.5a.8.8 0 0 0-.7-.8h-4a.8.8 0 0 0-.8.8Z" data-name="Layer 1" fill="currentColor"/>
  </svg>
</template>]

<script>
import componentId from '@/mixins/componentId';

export default {
  name: 'warning-icon',
  setup() {
    const componentID = componentId;
    return { componentID };
  },
};
</script>
