<template>
  <base-styles>
    <div class="charts-time-frame" :data-testid="componentID()">
      <button :tabindex="allowFocus" :class="{ active: selected === 'month', }" class="charts-item" @click="$emit('change', 'month')">
        1M
      </button>
      <button :tabindex="allowFocus" :class="{ active: selected === 'quarter', }" class="charts-item" @click="$emit('change', 'quarter')">
        3M
      </button>
      <button :tabindex="allowFocus" :class="{ active: selected === 'semi-annual', }" class="charts-item" @click="$emit('change', 'semi-annual')">
        6M
      </button>
      <button :tabindex="allowFocus" :class="{ active: selected === 'annual', }" class="charts-item" @click="$emit('change', 'annual')">
        1Y
      </button>
      <button v-if="allowSettings" ref="settingsBtn" :tabindex="allowFocus" class="charts-settings" aria-label="Settings" :data-testid="componentID('settings')" @click="$emit('settings')">
        <gear-icon/>
      </button>
    </div>
  </base-styles>
</template>

<script>
import BaseStyles from '@/components/BaseStyles.vue';
import GearIcon from '@/components/iconography/GearIcon.vue';
import componentId from '@/mixins/componentId';
import delve from 'dlv';

export default {
  name: 'time-frame-text',
  components: {
    BaseStyles,
    GearIcon,
  },
  props: {
    selected: {
      type: String,
      required: true,
    },
    allowSettings: {
      type: Boolean,
      required: false,
      default: true,
    },
    isHidden: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  watch: {
    // When the settings menu hides itself and we become visible again we should set focus to where we left off,
    // focused on the settings menu button
    isHidden(updated, old) {
      if (updated || !old || !delve(this, '$refs.settingsBtn')) return;
      this.$refs.settingsBtn.focus();
    },
  },
  computed: {
    allowFocus() {
      return this.isHidden ? -1 : undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.charts-time-frame {
  height: 100%;
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  font-family: $martech-display-inter;
  font-weight: $martech-weight-normal;
  user-select: none; // Prevent text selection because it will lock scrolling until the selection is cleared

  .charts-item {
    border-radius: 4px;
    color: #000;
    font-size: $martech-type-13;
    line-height: 24px;
    text-align: center;
    border: none;
    background-color: transparent;
    width: 32px;
    height: 32px;
    padding: 0 $martech-spacer-1;
    transition: background-color 100ms ease-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: $martech-weight-semibold;
    letter-spacing: 1px;

    &.active {
      color: #fff;
      background-color: #000;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .charts-settings {
    background-color: transparent;
    border: none;
    width: 40px;
    display: flex;
    justify-content: center;
    padding: 4px 0;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
