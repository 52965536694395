<template>
  <div class="martech-two-col-grid">
    <section class="martech-grid-section">
      <slot name="grid-section"/>
    </section>
    <aside class="martech-aside">
      <slot name="grid-aside"/>
    </aside>
  </div>
</template>

<script>
export default {
  name: 'two-column-grid',
};
</script>

<style lang="scss" scoped>
.martech-two-col-grid {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include breakpoint(1024) {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: $martech-spacer-4;
  }
}

.martech-grid-section {
  margin-bottom: $martech-spacer-4;

  @include breakpoint(1024) {
    margin-bottom: 0;
    grid-column: 1/5;
  }
}

.martech-aside {
  @include breakpoint(1024) {
    grid-column: 5/7;
  }
}
</style>