<template>
  <base-styles>
    <button class="martech-magnify-button" type="button" :aria-label="`Magnify ${cardName}`" :data-testid="componentID()" @click="magnify">
      <magnify-icon/>
    </button>
  </base-styles>
</template>

<script>
import BaseStyles from '@/components/BaseStyles.vue';
import MagnifyIcon from '@/components/iconography/MagnifyIcon.vue';
import componentId from '@/mixins/componentId';

export default {
  name: 'magnify-button',
  components: {
    MagnifyIcon,
    BaseStyles,
  },
  props: {
    cardName: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  methods: {
    magnify() {
      this.$emit('magnify');
    },
  },
};
</script>

<style lang="scss" scoped>
.martech-magnify-button {
  border: 1px solid $martech-gray-50;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  height: 48px;
  width: 48px;
  padding: 0;
  cursor: pointer;

  #magnify-icon {
    display: block;
    margin: auto;
    color: $martech-white;
  }

  &:focus {
    outline: none;
    box-shadow: 0px;
  }
}
</style>
