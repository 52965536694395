<template>
  <svg id="close-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" :data-testid="componentID()">
    <!--eslint-disable-next-line max-len-->
    <path d="M31.5 26.8a1.6 1.6 0 0 1 0 2.2L29 31.5a1.6 1.6 0 0 1-2.3 0L16 20.8 5.2 31.5a1.6 1.6 0 0 1-2.2 0L.5 29a1.6 1.6 0 0 1 0-2.3L11.2 16 .5 5.2A1.6 1.6 0 0 1 .5 3L3 .5a1.6 1.6 0 0 1 2.3 0L16 11.2 26.8.5a1.6 1.6 0 0 1 2.2 0L31.5 3a1.6 1.6 0 0 1 0 2.3L20.8 16Z" data-name="Layer 1" fill="currentColor"/>
  </svg>
</template>

<script setup>
import useComponentId from '@/use/useComponentId';
const { componentID } = useComponentId();
</script>

<style lang="scss" scoped>
#close-icon {
    width: 13px;
    height: 13px;
}
</style>
