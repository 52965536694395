<template>
  <base-styles>
    <div class="martech-flip-buttons" :data-testid="componentID()">
      <base-button v-if="!isIcon" btn-style="martech-text" btn-size="martech-medium" aria-label="Flip Card" class="flip-with-text" @clicked="$emit('flip')">
        <flip-icon/>
        Flip Card
      </base-button>
      <button v-else class="flip-button" type="button" aria-label="Flip Card" @click="$emit('flip')">
        <flip-icon/>
      </button>
    </div>
  </base-styles>
</template>

<script>
import BaseButton from '@/components/elements/BaseButton.vue';
import FlipIcon from '@/components/iconography/FlipIcon.vue';
import BaseStyles from '@/components/BaseStyles.vue';
import componentId from '@/mixins/componentId';

export default {
  name: 'flip-button',
  components: {
    BaseButton,
    FlipIcon,
    BaseStyles,
  },
  props: {
    isIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  methods: {
    flip() {
      this.$emit('flip');
    },
  },
};
</script>

<style lang="scss" scoped>
.martech-flip-buttons {
  display:flex;
  justify-content: center;

  .flip-with-text {
    #flip-icon {
      margin-right: $martech-spacer-2;
    }
  }

  .flip-button {
    border: 1px solid $martech-gray-50;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: -175px;
    right: 0;
    height: 48px;
    width: 48px;
    padding: 0;
    cursor: pointer;

  #flip-icon {
      display: block;
      margin: auto;
      color: $martech-white;
    }

    &:focus {
      outline: none;
      box-shadow: 0px;
    }
  }
}
</style>
