<template>
  <base-styles>
    <div class="martech-pagination" :data-testid="componentID()">
      <base-link
        v-show="pageNumber > 1"
        :link-url="pageNumberLink(pageNumber === 1)"
        class="martech-pagination-first"
        :class="{'disabled': 1 === pageNumber}"
        :disabled="1 === pageNumber"
        aria-label="Go To First Page"
        @click="$emit('click', 1)">
        <double-chevron aria-label="Go To First Page" direction="left"/>
      </base-link>
      <base-link
        v-show="pageNumber > 1"
        tag="button"
        :link-url="pageNumberLink(pageNumber - 1)"
        class="martech-pagination-back"
        :class="{'disabled': 1 === pageNumber}"
        :disabled="1 === pageNumber"
        aria-label="Go Back Page"
        @click="$emit('click', pageNumber - 1)">
        <chevron aria-label="Go Back Page" direction="left"/>
      </base-link>
      <base-link
        v-if="!isMobile"
        :link-url="pageNumberLink(1)"
        class="martech-page-numbers"
        :class="{'active': 1 === pageNumber}"
        @click="$emit('click', 1)">
        <span>1</span>
      </base-link>
      <span v-if="pageCount > 6 && pageNumber >= 5 && !isMobile" class="martech-dotted-spacer">...</span>
      <base-link
        v-for="p in pages"
        :key="p"
        :value="p"
        :link-url="pageNumberLink(p)"
        class="martech-page-numbers"
        :class="{'active': p === pageNumber}"
        @click="$emit('click', p)">
        <span>{{ p }}</span>
      </base-link>
      <span v-if="pageCount > 6 && pageNumber <= pageCount - 3 && !isMobile" class="martech-dotted-spacer">...</span>
      <base-link
        v-if="!isMobile"
        :link-url="pageNumberLink(pageCount)"
        class="martech-page-numbers"
        :class="{'active': pageCount === pageNumber}"
        @click="$emit('click', pageCount)">
        <span>{{ pageCount }}</span>
      </base-link>
      <base-link
        v-show="pageCount > pageNumber"
        tag="button"
        :link-url="pageNumberLink(pageNumber + 1)"
        class="martech-pagination-next"
        :class="{'disabled': pageCount === pageNumber}"
        :disabled="pageCount === pageNumber"
        aria-label="Next Page"
        @click="$emit('click', pageNumber + 1)">
        <chevron aria-label="Next Page" direction="right"/>
      </base-link>
      <base-link
        v-show="pageCount > pageNumber"
        :link-url="pageNumberLink(pageCount)"
        class="martech-pagination-last"
        :class="{'disabled': pageCount === pageNumber}"
        :disabled="pageCount === pageNumber"
        aria-label="Go To Last Page"
        @click="$emit('click', pageCount)">
        <double-chevron aria-label="Go To Last Page" direction="right"/>
      </base-link>
    </div>
  </base-styles>
</template>

<script>
import delve from 'dlv';
import Chevron from '@/components/iconography/Chevron.vue';
import DoubleChevron from '@/components/iconography/DoubleChevron.vue';
import deviceType from '@/mixins/deviceType';
import BaseStyles from '@/components/BaseStyles.vue';
import BaseLink from '@/components/elements/BaseLink.vue';
import componentId from '@/mixins/componentId';

export default {
  name: 'pagination',
  components: {
    BaseLink,
    BaseStyles,
    Chevron,
    DoubleChevron,
  },
  mixins: [ deviceType ],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    pageNumber: {
      type: Number,
      default: 0,
    },
    encodeUrl: {
      type: Boolean,
      default: true,
    }
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  computed: {
    isMobile() {
      return this.deviceType === 'mobile';
    },
    pages() {
      if (this.isMobile) {
        return [ this.pageNumber ];
      }
      let p = [];
      const pc = this.pageCount;
      const pn = this.pageNumber;
      if (pn < 5) {
        for (let i = 2; i <= 5 && i < pc; i++) {
          p.push(i);
        }
      } else if (pn > pc - 4) {
        p = p.concat([ pc - 4, pc - 3, pc - 2, pc - 1 ]);
      } else {
        p = p.concat([ pn - 1, pn, pn + 1 ]);
      }
      return p;
    },
  },
  methods: {
    pageNumberLink(n) {
      const q = { ...delve(this.$route, 'query', {}) };
      q.p = n;

      const path = `${delve(this.$route, 'path', '')}?${this.query(q)}`;
      return this.encodeUrl ? encodeURI(path) : path;
    },
    query(args) {
      if (typeof args !== 'object' || !args) {
        return '';
      }

      const queryValues = [];
      Object.keys(args).forEach((key) => {
        const value = args[key];
        // Note: We allow empty search term (q) and it must exist
        if (key === 'q' || (typeof value !== 'undefined' && (value || value === false))) {
          queryValues.push(`${key}=${value}`);
        }
      });

      return queryValues.join('&');
    },
  },
};
</script>

<style lang="scss" scoped>
.martech-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $martech-display-inter;

  &-next,
  &-back,
  &-first,
  &-last {
    width: 38px;
    height: 38px;
    background-color: transparent;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    :deep(a) {
      color: $martech-text-subdued;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &:hover {
        color: $martech-text-primary;
      }
    }
  }

  &-next,
  &-back {
    svg {
      width: 14px;
    }
  }

  &-first,
  &-last {
    svg {
      width: 14px;
      height: 16px;
    }
  }

  span {
    text-align: center;
    padding: 0 $martech-spacer-2;
  }

  .martech-dotted-spacer {
    color: $martech-text-subdued;
  }

  .martech-page-numbers {
    border-radius: $martech-radius-medium;
    display: inline-flex;
    text-align: center;
    background-color: transparent;
    min-width: 38px;
    height: 38px;
    align-items: center;
    justify-content: center;
    margin: 0 $martech-spacer-2;
    padding: 0;

    :deep(a) {
      text-decoration: none;
      color: $martech-text-subdued;
      transition: all 300ms ease-in;
      font-size: $martech-type-14;

      @include breakpoint(768) {
        font-size: $martech-type-16;
      }
    }
  }

  :deep(.active) {
    span {
      color: $martech-white;
    }

    background-color: $martech-blue;
    font-weight: $martech-weight-semibold;
    transition: all 300ms ease-in;

    &:hover {
      background-color: $martech-blue-hover;
    }

    &:focus {
      background-color: $martech-blue-focus;
    }
  }

  .disabled {
    background-color: transparent;
    color: $martech-text-subdued;
    opacity: 0.5;
  }
}
</style>
