<template>
  <base-styles>
    <div v-if="loaded && displayBanner && consentManagerAvailable" class="compliance-banner" :data-testid="componentID()">
      <p class="message martech-body">
        We use cookies to help you find relevant products and offers. <base-link :link-url="privacyPolicy" class="learn-more" new-window>Learn more <div class="martech-icon martech-external-link-icon martech-black"/></base-link>
      </p>
      <div class="buttons">
        <base-button class="manage-button" btn-style="martech-text-underline" btn-size="martech-medium" @clicked="openConsentManager">
          Preferences
        </base-button>
        <base-button class="allow-button" btn-size="martech-medium" @clicked="allowAll">
          Allow All
        </base-button>
      </div>
    </div>
  </base-styles>
</template>

<script>
import delve from 'dlv';
import BaseStyles from '@/components/BaseStyles.vue';
import BaseButton from '@/components/elements/BaseButton.vue';
import BaseLink from '@/components/elements/BaseLink.vue';
import componentId from '@/mixins/componentId';

// Figure out the current state of the compliance banner
const LOCAL_STORAGE_KEY = 'compliance-banner-display';

export default {
  name: 'compliance-banner',
  components: {
    BaseStyles,
    BaseButton,
    BaseLink,
  },
  props: {
    useParent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      consentManagerAvailable: false,
      loaded: false,
      displayBanner: false,
      privacyPolicy: 'https://help.tcgplayer.com/hc/en-us/articles/9709672995095-Cookies-and-Similar-Technologies-Policy',
    };
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  async created() {
    let res;
    try {
      if (this.useParent) {
        res = await this.parentMessage({ action: 'getStorage', key: LOCAL_STORAGE_KEY, });
      } else {
        res = localStorage.getItem(LOCAL_STORAGE_KEY);
      }
    } catch (err) {}

    this.$emit('banner-state', this.displayBanner);

    let maxTime = 5000;
    const interval = window.setInterval(async () => {
      maxTime -= 100;

      if (await this.consentManager() || maxTime <= 0) {
        if (await this.consentManager()) {
          // Only show the banner to those in the EU
          if (navigator.globalPrivacyControl || await this.inEU()) {
            this.loaded = true;
          } else {
            this.$emit('banner-state', false);
          }
        }

        window.clearInterval(interval);
        if (res === null) this.displayBanner = true;
      }
    }, 100);
  },
  methods: {
    parentMessage(message) {
      return new Promise((result, reject) => {
        const channel = new MessageChannel();

        channel.port1.onmessage = ({ data, }) => {
          channel.port1.close();
          if (data.error) {
            reject(data.error);
          } else {
            result(data.result);
          }
        };

        window.parent.postMessage(message, '*', [ channel.port2 ]);
      });
    },
    hideBanner() {
      this.displayBanner = false;
      try {
        if (this.useParent) {
          this.parentMessage({ action: 'saveStorage', key: LOCAL_STORAGE_KEY, });
        } else {
          localStorage.setItem(LOCAL_STORAGE_KEY, 0);
        }
      } catch (err) {}
      this.$emit('banner-state', false);
    },
    async consentManager() {
      if (this.useParent) {
        this.consentManagerAvailable = await this.parentMessage({ action: 'exists', });
      } else {
        this.consentManagerAvailable = !!delve(window, 'consentManager');
      }

      return this.consentManagerAvailable;
    },
    inEU() {
      if (this.useParent) {
        return this.parentMessage({ action: 'inEU', });
      }
      return Promise.resolve(window.consentManager.inEU());
    },
    openConsentManager() {
      if (this.useParent) {
        this.parentMessage({ action: 'open', });
      } else {
        window.consentManager.openConsentManager();
      }

      this.hideBanner();
    },
    async allowAll() {
      let prefs;
      if (this.useParent) {
        prefs = await this.parentMessage({ action: 'load', });
      } else {
        prefs = window.consentManager.preferences.loadPreferences();
      }

      // Loop across all of the custom and destination prefs that are set to false and mark them all true
      if (prefs.customPreferences) {
        Object.keys(prefs.customPreferences).forEach(k => prefs.customPreferences[k] = true);
      }
      if (prefs.destinationPreferences) {
        Object.keys(prefs.destinationPreferences).forEach(k => prefs.destinationPreferences[k] = true);
      }

      if (this.useParent) {
        this.parentMessage({ action: 'save', prefs, });
      } else {
        window.consentManager.preferences.savePreferences(prefs);
      }

      this.hideBanner();
    },
  },
};
</script>

<style lang="scss" scoped>
.compliance-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 192px;
  background: $martech-surface-alt;
  padding: $martech-spacer-2 $martech-spacer-3 $martech-spacer-3 $martech-spacer-3;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3147483641;
  border-top: 1px solid $martech-border;

  .message {
    text-align: center;
    margin-bottom: $martech-spacer-3;

    @include breakpoint(1024) {
      margin-bottom: 0;
      margin-right: $martech-spacer-3;
    }

    .learn-more {
      color: $martech-text-primary;
      text-decoration: underline;
      display: inline-flex;
      align-items: center;

      .martech-icon {
        display: inline-block;
        margin-left: $martech-spacer-2;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;

    span {
      width: 100%;

      :deep(button) {
        width: 100%;
        &:last-child {
          margin-bottom: $martech-spacer-3;
        }
      }
    }
  }

  @include breakpoint(1024) {
    height: 81px;
    flex-direction: row;
    padding: $martech-spacer-2;

    .buttons {
      flex-direction: row;
      justify-content: center;
      width: auto;

      span {
        margin-right: $martech-spacer-3;
        width: auto;

        :deep(button) {
          width: auto;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
