<template>
<div class="mana-bars" v-if="color" :data-testid="componentID()">
  <div class="bar" v-for="bar in sortColors" :key="bar" :class="barBackgroundColor(bar)">
      <mana-symbols :color="iconBarColor(bar)" hide-circle size="15"/>
  </div>
</div>
</template>

<script setup>
import { computed } from 'vue';
import useComponentId from '@/use/useComponentId';
import ManaSymbols from '@/components/iconography/ManaSymbols.vue';

const props = defineProps({
  color: {
    type: Array,
    required: false,
  },
});

const { componentID } = useComponentId();

const sortedColors = (a, b) => {
  if (a === 'Commander: Colorless') return 1;
  if (b === 'Commander: Colorless') return -1;

  return a.localeCompare(b);
}

const sortColors = computed(() => {
  return props.color.sort(sortedColors);
});

const iconBarColor = (barColor) => {
  switch(barColor.toLowerCase()){
    case 'commander: black':
      return 'B';
    case 'commander: blue':
      return 'U';
    case 'commander: green':
      return 'G';
    case 'commander: red':
      return 'R';
    case 'commander: white':
      return 'W';
    case 'commander: colorless':
      return 'C';
    default:
      return '';
  }
};

const barBackgroundColor = (barColor) => {
  switch(barColor.toLowerCase()){
    case 'commander: blue':
      return 'mana-blue';
    case 'commander: white':
      return 'mana-white';
    case 'commander: green':
      return 'mana-green';
    case 'commander: black':
      return 'mana-black';
    case 'commander: red':
      return 'mana-red';
    case 'commander: colorless':
      return 'colorless';
    default:
      return '';
  }
};
</script>

<style lang="scss" scoped>
.mana-bars {
  display: flex;
  width: 100%;

  .bar {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 4px 0;

    &.mana-blue {
      background-color: #C1D7E9;
    }

    &.mana-red {
      background-color: #F6A077;
    }

    &.mana-black {
      background-color: #C2B8AE;
    }

    &.mana-white {
      background-color: #F8F6D8;
    }

    &.mana-green {
      background-color: #A3C095;
    }

    &.colorless {
      background-color: #CBC1C0;
    }
  }
}
</style>