<template>
  <base-styles>
    <label class="martech-charts-checkbox" tabindex="0" :data-testid="componentID()" @keypress.enter.space.prevent="clickCheckbox">
      <input
        :id="id"
        :checked="checked"
        tabindex="-1"
        :value="checkboxValue"
        type="checkbox"
        @change="$emit('on-change', checkboxValue, id)">
      <span class="martech-charts-checkmark"/>
      <span class="martech-charts-label-group">
        <span v-if="color" :style="`background-color: ${color}`" class="martech-charts-color"/>
        <span>{{ label }}</span>
      </span>
    </label>
  </base-styles>
</template>

<script>
import BaseStyles from '@/components/BaseStyles.vue';
import componentId from '@/mixins/componentId';

export default {
  name: 'default-checkbox',
  components: {
    BaseStyles,
  },
  model: {
    event: 'change',
  },
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
    checkboxValue: {
      type: [ String, Boolean ],
      default: '',
    },
    checked: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    color: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  methods: {
    clickCheckbox() {
      const checkbox = document.getElementById(this.id);
      if (checkbox) {
        checkbox.click();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.martech-charts-checkbox {
  display: flex;
  position: relative;
  padding-left: 28px;
  align-items: center;
  cursor: pointer;
  font-size: $martech-type-14;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .martech-charts-label-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 1rem;
  }

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    border: 2px dashed $martech-blue;
  }

  .martech-charts-checkmark {
    position: absolute;
    left: 0;
    height: 12px;
    width: 12px;
    border-radius: 3px;
    background-color: $martech-white;
    transition: all 0.25s ease-in-out;
    box-sizing: revert;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 3.3px;
      top: 1.3px;
      width: 2.5px;
      height: 5px;
      border: solid $martech-white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  & input ~ .martech-charts-checkmark {
    background-color: $martech-white;
    border: 2px solid $martech-black;
  }

  & input:checked ~ .martech-charts-checkmark {
    background-color: $martech-black;
    border: 2px solid $martech-black;
  }

  & input:checked ~ .martech-charts-checkmark:after {
    display: block;
  }

  .martech-charts-color {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-right: 6px;
    border-radius: 2px;
  }
}
</style>
