<template>
  <!--eslint-disable-next-line-->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" :data-testid="componentID()"><g data-name="Layer 2"><path d="M31.07 17.43l-6.23 2.7 6.24 2.7a1.57 1.57 0 010 2.86L17 31.79a2.41 2.41 0 01-1 .21 2.78 2.78 0 01-1-.21L.92 25.68A1.54 1.54 0 010 24.26a1.56 1.56 0 01.92-1.43l6.24-2.7-6.24-2.71a1.56 1.56 0 010-2.84l6.24-2.71-6.24-2.7A1.56 1.56 0 010 7.74a1.53 1.53 0 01.93-1.42L15 .21a2.49 2.49 0 012 0l14.08 6.11A1.54 1.54 0 0132 7.74a1.55 1.55 0 01-.93 1.43l-6.23 2.7 6.24 2.71A1.54 1.54 0 0132 16a1.55 1.55 0 01-.93 1.43zm-15.24-4a.4.4 0 00.34 0l13-5.64-13-5.64a.45.45 0 00-.34 0l-13 5.64zM29.17 16l-6.92-3L17 15.28a2.4 2.4 0 01-1 .2 3 3 0 01-1-.2L9.75 13l-6.92 3 13 5.64a.4.4 0 00.34 0zm-6.92 5.25L17 23.54a2.57 2.57 0 01-1 .2 3 3 0 01-1-.2l-5.25-2.29-6.92 3 13 5.64a.4.4 0 00.34 0l13-5.64z" fill="currentColor" data-name="Layer 1"/></g></svg>
</template>

<script>
import componentId from '@/mixins/componentId';

export default {
  name: 'deck-icon',
  setup() {
    const componentID = componentId;
    return { componentID };
  },
};
</script>
