<template>
  <base-styles>
    <div class="deck-embed-header" :class="{ 'event-header': pageType === 'event' }">
      <div class="group title-price">
        <div class="left-side">
          <div class="group format-color">
            <base-link v-if="pageType !== 'event' && showLinks" :link-url="deckFormatLink"
              class="format martech-text-sm martech-text-semibold">
              {{ data.format }}
            </base-link>
            <span v-else-if="pageType !== 'event' && !showLinks" :link-url="deckFormatLink"
              class="format martech-text-sm martech-text-semibold">
              {{ data.format }}
            </span>
          </div>
          <div class="group title-mana">
            <base-link v-if="showLinks" :link-url="deckLink" class="deck-link">
              <h2 class="deck-title martech-inter martech-text-semibold">
                {{ data.name }}
              </h2>
            </base-link>
            <h2 v-else class="deck-title martech-inter martech-text-semibold">
                {{ data.name }}
              </h2>
            <lorcana-symbols v-if="data?.stats?.inks?.ink" :color="data?.stats?.inks?.ink" :size="23" />
            <mana-symbols v-if="showManaSymbols" :color="data.color" :size="20" />
          </div>
          <p class="created-by martech-text-sm">
            By&nbsp; <base-link v-if="showLinks" :link-url="deckPlayerLink" class="martech-text-sm player-link">
              {{ data.playerName }}
            </base-link>
            <span v-else class="martech-text-sm">
              {{ data.playerName }}
            </span>
            <template v-if="data.playerHandle && data.playerHandle != data.playerName">
              <span class="handle">{{ `(${data.playerHandle})` }}</span>
            </template>
          </p>
          <!--Event Name & Date-->
          <template v-if="pageType !== 'event'">
            <p v-if="data.eventName && data.date" class="event-name martech-text-sm">
              <template v-if="showEventRank">
                {{ eventRank }} |
              </template>
              <base-link v-if="showLinks" :link-url="eventLink" class="event-link martech-text-sm">
                <span>{{ data.eventName }}</span>
              </base-link>
              <span v-else class="martech-text-sm">
                {{ `${data.eventName}` }}
              </span>
              <span v-if="data.date">
                &nbsp;-&nbsp;
              </span>
              <template v-if="data.date">
                {{ formattedDate }}
              </template>
            </p>
            <!-- Just Date -->
            <p v-else class="event-name martech-text-sm">
              {{ formattedDate }}
            </p>
          </template>
        </div>
        <market-price-export-buy
          :deck="data"
          :export-buttons="exportButtons"
          :analytics-data="analyticsData"
          :title="data.name"
          :author="data.playerName"
          :vertical="vertical"
          :text-align="isMobile ? 'left' : 'right'"
          @buy-deck-event="buyEvent"/>
      </div>
    </div>
  </base-styles>
</template>

<script setup>
import { computed } from 'vue';
import { get } from '@vueuse/core';
import BaseStyles from '@/components/BaseStyles.vue';
import BaseLink from '@/components/elements/BaseLink.vue';
import ManaSymbols from '@/components/iconography/ManaSymbols.vue';
import LorcanaSymbols from '@/components/iconography/LorcanaInkColorCost.vue'
import MarketPriceExportBuy from '@/components/embeds/deck-parts/MarketPriceExportBuy.vue';
import verts from '@/lib/verticals';
import formats from '@/lib/formats';
import events from '@/lib/events';
import dates from '@/lib/dates';
import useDeviceType from '@/use/deviceType';

const emit = defineEmits(['buy-deck-event']);

const buyEvent = () => {
  emit('buy-deck-event');
}

const props = defineProps({
  verticalName: {
    type: String,
    required: true,
    default: 'magic',
  },
  data: {
    type: Object,
    default: () => ({
      color: '',
      deckName: '',
      eventName: '',
      eventRank: '',
      date: '',
      format: '',
      game: '',
      marketPriceHigh: 0.00,
      playerHandle: '',
      subDecks: {},
    }),
  },
  exportButtons: {
    type: Boolean,
  },
  pageType: {
    type: String,
    default: '',
  },
  analyticsData: {
    type: Object,
    default: () => ({ analyticsCampaign: '', analyticsSource: '', analyticsMedium: '' }),
  },
});

const { isMobile } = useDeviceType();

const vertical = computed(() => ((props.data.game || props.verticalName || 'Magic').toLowerCase()));

const eventLink = computed(() => {
  let ev = props.data.eventName;
  if (props.data.date) {
    ev += ` - ${props.data.date}`;
  }

  return `${verts.verticalLink(get(vertical))}/decks/event/${encodeURIComponent(ev)}`;
});

const deckLink = computed(() => {
  if (props.data.canonicalURL) {
    return `${props.data.canonicalURL}?external=${props.data.external}`;
  }

  const name = props.data.name.replaceAll(/\s/g, '-');
  return `${verts.verticalLink(get(vertical))}/deck/${name}/${props.data.id}?external=${props.data.external}`;
});

const deckPlayerLink = computed(() => (
  props.data.deckSource === 'affiliate' && props.data.playerName
    ? `/author/${props.data.playerName}/decks`
    : `${verts.verticalLink(get(vertical))}/decks/player/${props.data.playerName}`
));

const deckFormatLink = computed(() => (`${verts.verticalLink(get(vertical))}/decks/format/${formats.formatLink(props.data.format)}`));

const formattedDate = computed(() => (dates.formattedDateWithAbrvMonth(props.data.date)));

const eventRank = computed(() => (events.placementText(props?.data?.eventRank)));

const showEventRank = computed(() => (
  !(
    !get(eventRank)
    || get(eventRank) === ''
    || get(eventRank) === '0'
    || get(eventRank) === 0
    || get(eventRank) === '0-0'
    || get(eventRank) === '-'
  )
));

const showLinks =  computed(() => ([ 'magic', 'yugioh', 'lorcana', ].includes(props.data.game)));

const showManaSymbols = computed(() => (props.data.color && props.data.game === 'magic'));
</script>

<style lang="scss" scoped>
.deck-embed-header {
  background-color: $martech-surface-alt;
  padding: $martech-spacer-3;
  border-bottom: 1px solid $martech-border;
  font-family: $martech-display-inter;
  width: calc(100% + 32px);
  margin-left: -$martech-spacer-3;
  border-top: 1px solid $martech-border;

  @include breakpoint(768) {
    width: 100%;
    margin-left: 0;
    border-top-left-radius: $martech-radius-large;
    border-top-right-radius: $martech-radius-large;
    border-top: 0;
  }

  p {
    margin: 0;
  }

  .left-side {

    :deep(a) {
      color: $martech-text-primary;
      text-decoration: none;
    }

    padding-bottom: $martech-spacer-2;

    @include breakpoint(1024) {
      width: 60%;
      padding-bottom: 0;
      margin-right: $martech-spacer-3;
    }

    @include breakpoint(1200) {
      width: 65%;
    }
  }

  :deep(.ink-symbols) {
    margin-left: $martech-spacer-2;
  }

  :deep(.deck-link) {
    a {
      color: $martech-text-primary;
      text-decoration: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .deck-title {
    font-size: $martech-type-22;
    line-height: $martech-type-32;
  }

  .martech-text-sm {
    font-family: $martech-display-inter;
    font-size: $martech-type-13;
    line-height: $martech-type-22;
    font-weight: $martech-weight-normal;
    color: $martech-gray-170;
    letter-spacing: 0.5px;
    text-decoration: none;
  }

  .martech-labels {
    color: $martech-gray-170;
    font-size: $martech-type-12;
    line-height: $martech-type-16;
  }

  .group {
    display: flex;

    &.title-price {
      flex-direction: column;

      @include breakpoint(1024) {
        justify-content: space-between;
        flex-direction: row;
        align-items: flex-start;
      }
    }
  }

  .title-mana {
    align-items: center;
  }

  .deck-purchase {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: $martech-spacer-2;
  }

  .export-buttons {
    width: 100%;
    margin-right: $martech-spacer-2;

    @include breakpoint(1024) {
      width: auto;
    }
  }

  .price-buttons {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;

    @include breakpoint(1024) {
      display: block;
      width: auto;

      :deep(.deck-price) {
        .group {
          justify-content: flex-end;
        }
      }
    }
  }

  .format {
    color: $martech-text-primary;
    line-height: 20px;
    text-transform: capitalize;
  }

  .mana-symbols {
    margin-left: $martech-spacer-2;
  }

  .created-by {
    display: flex;
    align-items: center;

    .player-link {
      text-decoration: underline;

      :deep(a) {
        font-size: $martech-type-14;
        font-weight: $martech-weight-normal;
      }
    }
  }

  .handle {
    margin-left: $martech-spacer-2;
  }

  .event-link {
    text-decoration: underline;

    :deep(a) {
      font-size: $martech-type-12;
      font-weight: $martech-weight-normal;
    }
  }
}
</style>
