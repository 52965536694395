<template>
  <base-styles>
    <label class="checkbox" tabindex="0" :data-testid="componentID()" @keypress.enter.space.prevent.stop="clickCheckbox">
      <input
        :id="id"
        :checked="checked"
        tabindex="-1"
        :value="checkboxValue"
        type="checkbox"
        @change.passive="$emit('on-change', checkboxValue, id, $event)"
        :data-testid="componentID(label)">
      <span class="checkmark"/>
      <span v-if="label" class="label-group">
        <span>{{ label }}</span>
        <span class="count">{{ count }}</span>
      </span>
    </label>
  </base-styles>
</template>

<script>
import BaseStyles from '@/components/BaseStyles.vue';
import componentId from '@/mixins/componentId';

export default {
  name: 'default-checkbox',
  components: {
    BaseStyles,
  },
  model: {
    event: 'change',
  },
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
    checkboxValue: {
      type: [ String, Boolean ],
      default: '',
    },
    checked: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    count: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  methods: {
    clickCheckbox() {
      const checkbox = document.getElementById(this.id);

      if (checkbox) {
        checkbox.click();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: $martech-spacer-4;
  cursor: pointer;
  font-size: $martech-detail;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: auto;

  .label-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $martech-display-inter;

    .count {
      color: $martech-text-subdued;
      font-size: $martech-sm-detail;
    }
  }

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px dashed $martech-blue-focus;
  }

  .checkmark {
    position: absolute;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 3px;
    background-color: $martech-white;
    border: 2px solid $martech-black;
    transition: all 0.25s ease-in-out;

    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 0;
      left: 3px;
      width: 3.5px;
      height: 8px;
      border: solid $martech-white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  & input ~ .checkmark {
    background-color: $martech-surface;
    border: 2px solid $martech-black;
  }

  & input:checked ~ .checkmark {
    background-color: $martech-blue;
    border: 2px solid $martech-blue;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }
}
</style>
