<template>
  <delay-load @visible="onVisible">
    <base-styles v-if="loaded === true">
      <base-button v-if="promoData.size === 'button'" ref="promoContainer" class="manage-button" :btn-style="btnStyle" btn-size="martech-medium" @clicked="buttonClicked(promoData.destinationURL, promoData.newTab)">
          {{ promoData.title }}
      </base-button>
      <div v-else ref="promoContainer" class="martech-promos-banner" :class="[{ listView: placement === 'Decks' }, wrapperClasses]" :data-testid="componentID()">
        <base-link
          v-if="promoData.destinationURL"
          :link-url="promoData.destinationURL"
          :url-target="urlTarget"
          :new-window="promoData.newTab"
          class="promo-container"
          :class="wrapperClasses"
          @click="promoClicked">
          <div class="sponsored-wrap" :class="wrapperClasses">
            <div class="martech-promos-wrapper" :class="[wrapperClasses, domains]">
              <img class="martech-promos-image" :src="promoImageURL || cardFallbackImage" :alt="promoData.title" @error="errorImageFallback($event)">
            </div>
            <p v-if="promoData.isSponsored" class="martech-text-sm sponsored-text">
              Sponsored
            </p>
            <close-button v-if="closeable" name="Promo" :dark="closeable === 'dark'"  @click.stop.prevent="close"/>
          </div>
        </base-link>
        <div v-else class="promo-container">
          <div class="sponsored-wrap">
            <div class="martech-promos-wrapper" :class="wrapperClasses">
              <img class="martech-promos-image" :src="promoImageURL || cardFallbackImage" :alt="promoData.title" @error="errorImageFallback($event)">
            </div>
            <p v-if="promoData.isSponsored" class="martech-text-sm sponsored-text">
              Sponsored
            </p>
            <close-button v-if="closeable" name="Promo" :dark="isDark" :background="hasBackground"  @click.stop.prevent="close"/>
          </div>
        </div>
      </div>
    </base-styles>
    <base-styles v-else-if="loaded === false">
      <slot/>
    </base-styles>
  </delay-load>
</template>

<script setup>
import { ref, computed } from 'vue';
import { get, set, useThrottleFn } from '@vueuse/core';
import BaseButton from '@/components/elements/BaseButton.vue';

import amplitudeEvent from '@tcgplayer/amplitude';
import pageUtil from '@/lib/page';

import { useRoute } from 'vue-router';
import useDeviceType from '@/use/deviceType';
import useComponentId from '@/use/useComponentId';
import usePromoCore from '@/use/promo/core';

import DelayLoad from '@/components/DelayLoad.vue';
import BaseStyles from '@/components/BaseStyles.vue';
import BaseLink from '@/components/elements/BaseLink.vue';
import CloseButton from '@/components/elements/CloseButton.vue';


const props = defineProps({
  domains: {
    type: String,
    default: 'infinite',
  },
  vertical: {
    type: String,
    default: '',
  },
  placement: {
    type: String,
    default: 'Article Page',
  },
  promoSize: {
    type: String,
    default: '',
  },
  promoReach: {
    type: String,
    default: '',
  },
  contentTypes: {
    type: String,
    default: '',
  },
  setPromoCallback: {
    type: Function,
    default: () => {},
  },
  cardFallbackImage: {
    type: String,
    default: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/card-fallback-image%402x.jpg',
  },
  offset: {
    type: Number,
    default: 0,
  },
  data: {
    type: String,
    default: '',
  },
  noPromoAvailable: {
    type: Function,
    default: () => {},
  },
   btnStyle: {
    type: String,
    default: 'martech-primary',
  },
  closeable: {
    type: String,
    default: '',
    validator(val) {
      return ['dark', 'light', 'dark-background', 'light-background', ''].includes(val);
    },
  },
});

const { componentID } = useComponentId();
const { deviceType } = useDeviceType();
const route = useRoute();
const promoContainer = ref(null); 
const emit = defineEmits(['no-data', 'loaded',]);
const { loaded, promoData, close, isDark, hasBackground, fetchPromos } = usePromoCore(props, promoContainer, emit);

const promoReach = computed(() => props.vertical || props.promoReach);

const promoSizes = computed(() => {
  switch(get(promoData)?.size || props.promoSize) {
    case 'halfpage':
      return 'half-page';
    case 'billboard':
      return 'billboard';
    case 'leaderboard':
      return 'leaderboard';
    case 'native full width':
      return 'native-full-width';
    case 'square':
      return 'square-promo';
    case 'hero banner':
      return 'hero-banner-promo';
    case 'extra large rectangle':
      return 'extra-large-rectangle';
    case 'button':
      return 'button';
    default:
      return 'medium-rectangle';
  }
});

const wrapperClasses = computed(() => {
  let classes = get(promoSizes);

  if (classes === 'leaderboard' && [ 'decks', 'cards' ].includes(props.placement)) {
    classes += ' listView';
  }

  return classes;
});

const promoImageURL = computed(() => {
  if ([
    'halfpage', 'billboard', 'leaderboard', 'native full width', 'hero banner',
    'extra large rectangle' ].includes(get(promoData)?.size || props.promoSize)) {
    return get(deviceType) === 'mobile' ? get(promoData).mobileImageURL : get(promoData).imageURL;
  }

  return get(promoData)?.imageURL;
});

const urlTarget = computed(() => get(promoData)?.newTab ? '_blank' : '');

function onVisible() {
  const data = props.data ? decodeURIComponent(props.data) : null;
  if (data && Object.keys(data).length) {
    set(promoData, JSON.parse(data));
    set(loaded, true);
    return;
  }

  fetchPromos({
    domains: props.domains,
    promoReach: `all|${get(promoReach)}`,
    promoSize: props.promoSize,
    offset: props.offset,
  });
}

const promoClicked = useThrottleFn(() => {
  amplitudeEvent('general', 'promoClick', {
    domain: props.domains,
    location: props.domains,
    name: get(promoData)?.title,
    style: get(promoData)?.style,
    display: get(deviceType),
    vertical: pageUtil.getVertical(),
    pageType: pageUtil.getCategory(),
    page: route?.path,
    size: get(promoData)?.size || props.promoSize,
  });
}, 1000)

function buttonClicked(link, newTab) {
  if(newTab) {
    return window.open(link, '_blank', 'noopener noreferrer');
  }

  window.location = link;
}
</script>

<style lang="scss" scoped>
.martech-promos {
  &-banner {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .promo-container {
      position: relative;
    }

    &.hero-banner-promo,
    &.native-full-width {
      display: block;
    }

    &.listView {
      border-bottom: 1px solid #ccc;
      padding-bottom: $martech-spacer-3;
    }

    a {
      &.medium-rectangle {
        margin: auto auto $martech-spacer-4 auto;
        width: 300px;
      }

      &.square-promo {
        width: 250px;
      }

      &.leaderboard {
        width: 290px;
        margin: $martech-spacer-4 auto auto auto;

        @media only screen and (min-width: 325px) {
          width: 320px;
        }

        @media only screen and (min-width: 768px) {
          width: 728px;
        }
      }

      &.billboard {
        margin: auto;
        width: 300px;

        @media only screen and (min-width: 768px) {
          width: 970px;
        }
      }

      &.extra-large-rectangle{
        width: 300px;
        margin: auto auto $martech-spacer-4 auto;

        @media only screen and (min-width: 1024px) {
          width: 750px;
        }
      }

      &.half-page {
        width: 300px;
        margin: auto auto $martech-spacer-4 auto;
      }

      &.native-full-width {
        width: 100%;
        margin: auto;

        @media only screen and (min-width: 768px) {
          max-width: 1300px;
        }
      }
    }

    .sponsored-wrap {
      display: flex;
      justify-content: center;
      flex-direction: column;

      &.medium-rectangle {
        margin: auto auto $martech-spacer-4 auto;
        width: 300px;
      }

      &.leaderboard {
        width: 290px;
        margin: $martech-spacer-4 auto auto auto;

        @media only screen and (min-width: 325px) {
          width: 320px;
        }

        @media only screen and (min-width: 768px) {
          width: 728px;
        }
      }

      &.billboard {
        margin: auto;
        width: 300px;

        @media only screen and (min-width: 768px) {
          width: 970px;
        }
      }
      

      &.extra-large-rectangle {
        width: 300px;
        margin: auto auto $martech-spacer-4 auto;

        @media only screen and (min-width: 1024px) {
          width: 750px;
        }
      }

      &.square-promo {
        width: 250px;
        height: 250px;
      }

      &.half-page {
        width: 300px;
        margin: auto auto $martech-spacer-4 auto;
      }

      &.native-full-width {
        width: 100%;
        margin: auto;

        @media only screen and (min-width: 768px) {
          max-width: 1300px;
        }
      }

      .sponsored-text {
        color: $martech-text-subdued;
        margin-top: $martech-spacer-1;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
    }
  }

  &-wrapper {
    background-color: $martech-gray-50;
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: $martech-radius-medium;
    overflow: hidden;

    &.medium-rectangle {
      width: 300px;
      height: 250px;
    }

    &.native-full-width {
      width: 100%;
      height: 120px;
      border-radius: $martech-radius-medium;
      overflow: hidden;

      @media only screen and (min-width: 768px) {
        height: 180px;
        max-width: 1300px;
      }
    }

    &.billboard {
      width: 100%;
      height: 120px;

      @media only screen and (min-width: 768px) {
        height: 250px;
        width: 970px;
      }
    }

    &.hero-banner-promo {
      width: 100%;
      height: 188px;

      @include breakpoint(768) {
        height: 231px;
      }

      &.infinite {
        height: 130px;
        border-radius: 0px;

        @include breakpoint(768) {
          height: 200px;
        }
      }
    }

    &.half-page {
      width: 300px;
      height: 250px;

      @include breakpoint(1200) {
        height: 600px;
      }
    }

    &.extra-large-rectangle {
      width: 300px;
      height: 250px;
      margin: auto auto $martech-spacer-4 auto;

      @media only screen and (min-width: 1024px) {
        width: 750px;
        height: 300px;
      }
    }

    &.leaderboard {
      width: 290px;
      height: 50px;

      &.listView {
        margin-top: $martech-spacer-3;
      }

      @media only screen and (min-width: 325px) {
        width: 320px;
        height: 50px;
      }

      @media only screen and (min-width: 768px) {
        width: 728px;
        height: 90px;
      }

      .promos-image {
        object-fit: fill;

        @media only screen and (min-width: 768px) {
          object-fit: cover;
        }
      }
    }
  }

  &-image {
    position: absolute;
    object-fit: cover;
    object-position: top center;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &-tag {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: $martech-weight-medium;
    line-height: $martech-type-16;
    color: $martech-gray-140;
    text-align: right;
    padding-top: $martech-spacer-1;
  }
}
</style>
