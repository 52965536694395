<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31" :data-testid="componentID()">
    <!--eslint-disable-next-line max-len-->
    <path d="M31 15.5A15.5 15.5 0 1 1 15.5 0 15.5 15.5 0 0 1 31 15.5Zm-3 0A12.5 12.5 0 1 0 15.5 28 12.49 12.49 0 0 0 28 15.5Zm-10 0 3.88 3.89a.74.74 0 0 1 0 1.06l-1.41 1.41a.74.74 0 0 1-1.06 0L15.5 18l-3.89 3.88a.74.74 0 0 1-1.06 0l-1.41-1.43a.74.74 0 0 1 0-1.06L13 15.5l-3.86-3.89a.74.74 0 0 1 0-1.06l1.41-1.41a.74.74 0 0 1 1.06 0L15.5 13l3.89-3.88a.74.74 0 0 1 1.06 0l1.41 1.41a.74.74 0 0 1 0 1.06Z" fill="currentColor" data-name="Layer 1"/>
  </svg>
</template>
<script>
import componentId from '@/mixins/componentId';

export default {
  name: 'close-circle-icon',
  setup() {
    const componentID = componentId;
    return { componentID };
  },
};
</script>
