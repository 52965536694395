<template>
  <base-styles>
    <div v-show="allowPrices" class="market-price" :data-testid="componentID()">
      <div v-if="newStyle" class="market-price__new-style">
        <p class="martech-text-sm">
        {{ formattedPrice }}
        </p>
      </div>
      <div v-else class="market-price__old-style" :class="textAlignmentClass">
        <p v-show="hasTitle" class="title">
          Market Price:
          <span class="price">
            {{ formattedPrice }}
          </span>
        </p>
      </div>
    </div>
  </base-styles>
</template>

<script>
import BaseStyles from '@/components/BaseStyles.vue';
import prices from '@/lib/prices';
import componentId from '@/mixins/componentId';

export default {
  name: 'market-price-template',
  components: {
    BaseStyles,
  },
  props: {
    alignText: {
      type: String,
      required: false,
      default: 'align-center',
    },
    large: {
      type: Boolean,
      required: false,
    },
    hasTitle: {
      type: Boolean,
      default: true,
    },
    newStyle: {
      type: Boolean,
      default: false,
    },
    price: {
      type: Number,
      required: false,
      default: null,
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  data() {
    return {
      marketPrice: 'n/a',
      allowPrices: true,
    };
  },
  computed: {
    formattedPrice() {
        return prices.marketPrice(this.price);
    },
    textAlignmentClass() {
      switch (this.alignText) {
        case 'left':
          return 'align-left';
        default:
          return 'align-center';
      }
    },
  },
  created() {
    this.allowPrices = !prices.disallowPrices();
  },
};
</script>

<style lang="scss" scoped>
.market-price {
  font-family: $martech-display-inter;

  &__new-style {
    p {
      color: $martech-text-primary;
      line-height: 13px;
    }
  }

  &__old-style {
    display: flex;
    align-items: center;
    flex-direction: row;

    &.align-center {
      justify-content: center;
    }

    &.align-left {
      justify-content: left;
    }

    .title {
      color: $martech-black;
      font-size: $martech-type-14;
      letter-spacing: 0.2px;

      .price {
        color: $martech-black;
        font-weight: $martech-weight-semibold;
      }
    }
  }
}
</style>
