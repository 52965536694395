<template>
  <base-styles>
    <base-link v-if="isAbsoluteURL || (link.linkUrl && forceLink)" :link-url="link.linkUrl" :url-target="link.urlTarget"
      :class="[buttonSize, buttonColor, { 'full-width': fullWidth }]" class="martech-button" :data-testid="componentID()"
      :aria-expanded="menuOpen" @click="$emit('clicked')">
      {{ link.linkText }}
      <div v-if="iconClasses" class="icon" :class="iconClasses" />
    </base-link>
    <button v-else class="martech-button" :type="buttonType" :disabled="isDisabled"
      :class="[buttonSize, buttonColor, { 'is-disabled': isDisabled }, { 'full-width': fullWidth }]"
      :aria-expanded="menuOpen" @click="$emit('clicked')">
      <div v-if="iconClasses" class="icon" :class="iconClasses" />
      <slot />
    </button>
  </base-styles>
</template>

<script>
import buttons from '@/lib/buttons';
import link from '@/lib/link';
import BaseStyles from '@/components/BaseStyles.vue';
import BaseLink from '@/components/elements/BaseLink.vue';
import componentId from '@/mixins/componentId';

export default {
  name: 'base-button',
  components: {
    BaseStyles,
    BaseLink,
  },
  props: {
    btnStyle: {
      type: String,
      default: 'martech-primary',
      required: false,
    },
    buttonType: {
      type: String,
      default: 'button',
      required: false,
    },
    iconClasses: {
      type: String,
      default: '',
      required: false,
    },
    btnSize: {
      type: String,
      default: 'martech-large',
      required: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Object,
      required: false,
      default: () => ({
        linkUrl: '',
        linkText: '',
        urlTarget: '',
      }),
    },
    forceLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    menuOpen: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  computed: {
    buttonSize() {
      return buttons.buttonSizes(this.btnSize);
    },
    buttonColor() {
      return buttons.buttonColors(this.btnStyle);
    },
    isAbsoluteURL() {
      return link.isAbsoluteURL(this.link.linkUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.martech-button {
  transition: all 300ms ease-in-out;
  font-weight: $martech-weight-semibold;
  text-decoration: none;
  border-radius: $martech-radius-medium;
  font-family: $martech-display-inter !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.3px;
  border: none;
  cursor: pointer;
  text-shadow: none;
  -webkit-font-smoothing: antialiased;
  font-synthesis: none;
  text-rendering: optimizeLegibility;

  &.full-width {
    width: 100%;
  }

  &:hover {
    text-decoration: none;
  }

  // Centers text & icons if its an external link or being forced to use an a tag instead of router-link
  :deep(a) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  &.martech-large {
    padding: 0 $martech-spacer-3;
    font-size: $martech-body !important;
    min-height: 48px;
  }

  &.martech-medium {
    padding: 0 $martech-spacer-3;
    font-size: $martech-detail !important;
    min-height: 40px;
  }

  &.martech-small {
    padding: 0 $martech-spacer-3;
    font-size: 13.33px !important;
    min-height: 36px;
  }

  &.martech-primary {
    background-color: $martech-blue;
    color: $martech-white;

    &:focus {
      background: $martech-blue;
      box-shadow: 0 0 0 3px $martech-blue-focus;
      border: 1px solid $martech-white;
    }

    &:hover {
      background: $martech-blue-hover;
      color: $martech-white;
    }

    :deep(a) {
      color: $martech-white;
      background-color: transparent;

      &:active {
        color: $martech-white;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  &.martech-black {
    background-color: $martech-black;
    color: $martech-white;

    &:focus {
      background: $martech-black;
      box-shadow: 0 0 0 3px $martech-blue-focus;
      border: 1px solid $martech-blue-focus;
    }

    &:hover {
      background: $martech-black-hover;
      color: $martech-white;
    }

    :deep(a) {
      color: $martech-white;

      &:hover {
        background: $martech-black-hover;
        color: $martech-white;
        text-decoration: none;
      }
    }
  }

  &.martech-black-outline {
    background-color: transparent;
    color: $martech-text-primary;
    border: 1px solid $martech-black;

    &:focus {
      background: $martech-black;
      box-shadow: 0 0 0 3px $martech-blue-focus;
      border: 1px solid $martech-blue-focus;
      color: $martech-white;
      
      .icon {
        filter: invert(1);
      }
    }

    &:hover {
      background: $martech-black;
      color: $martech-white;
    }

    :deep(a) {
      color: $martech-text-primary;

      &:hover {
        color: $martech-white;
        text-decoration: none;
      }
    }
  }

  &.martech-white-outline {
    background-color: transparent;
    color: $martech-white;
    border: 1px solid $martech-white;

    &:focus {
      background: $martech-white;
      box-shadow: 0 0 0 3px $martech-blue-focus;
      border: 1px solid $martech-blue-focus;
      color: $martech-text-primary;
    }

    &:hover {
      background: $martech-white;
      color: $martech-text-primary;
    }

    :deep(a) {
      background-color: transparent;
      color: $martech-white;

      &:focus {
        background: $martech-white;
        box-shadow: 0 0 0 3px $martech-blue-focus;
        border: 1px solid $martech-blue-focus;
        color: $martech-text-primary;
      }

      &:hover {
        background: $martech-white;
        color: $martech-text-primary;
      }
    }
  }

  &.martech-text-underline {
    background-color: transparent;
    color: $martech-text-primary;
    border: 1px solid $martech-white;
    text-decoration: underline;
    font-weight: $martech-weight-normal;

    &:focus {
      box-shadow: 0 0 0 3px transparent;
    }

    &:hover {
      color: $martech-text-subdued;
      color: $martech-text-primary;
    }

    :deep(a) {
      background-color: transparent;
      color: $martech-text-primary;
      border: 1px solid $martech-white;
      text-decoration: underline;
      font-weight: $martech-weight-normal;

      &:focus {
        box-shadow: 0 0 0 3px transparent;
      }

      &:hover {
        color: $martech-text-subdued;
        color: $martech-text-primary;
      }
    }
  }

  &.martech-danger {
    border: 1px solid $martech-system-danger-border;
    background: $martech-system-danger-subdued;
    color: $martech-system-danger-title;

    &:focus {
      box-shadow: 0 0 0 3px $martech-system-danger-subdued;
    }

    &:hover {
      color: $martech-system-danger;
    }

    :deep(a) {
      border: 1px solid $martech-system-danger-border;
      background: $martech-system-danger-subdued;
      color: $martech-system-danger-title;

      &:focus {
        box-shadow: 0 0 0 3px $martech-system-danger-subdued;
      }

      &:hover {
        color: $martech-system-danger;
      }
    }
  }

  &.martech-text {
    background-color: transparent;
    color: $martech-text-primary;
    border: 1px solid $martech-white;

    &:focus {
      box-shadow: 0 0 0 3px transparent;
    }

    &:hover {
      color: $martech-text-subdued;
    }

    :deep(a) {
      background-color: transparent;
      color: $martech-text-primary;
      border: 1px solid $martech-white;

      &:focus {
        box-shadow: 0 0 0 3px transparent;
      }

      &:hover {
        color: $martech-text-subdued;
      }
    }
  }

  &.martech-secondary {
    background-color: $martech-surface-alt;
    color: $martech-text-primary;
    border: 1px solid $martech-border;

    &:focus {
      background: $martech-surface-alt;
      box-shadow: 0 0 0 3px $martech-blue-focus;
      border: 1px solid $martech-blue-focus;
    }

    &:hover {
      background: $martech-surface;
    }

    &:active {
      color: $martech-text-primary;
    }

    :deep(a) {
      color: $martech-text-primary;
      text-decoration: none;

      &:active {
        color: $martech-text-primary;
      }
    }
  }

  &.martech-white {
    background-color: $martech-surface;
    color: $martech-text-primary;
    border: 1px solid $martech-border;

    &:focus {
      background-color: $martech-surface;
      box-shadow: 0 0 0 3px $martech-blue-focus;
      border: 1px solid $martech-blue-focus;
    }

    &:hover {
      background-color: $martech-surface;
      color: $martech-text-subdued;
    }

    &:active {
      color: $martech-text-primary;
    }

    :deep(a) {
      background-color: $martech-surface;
      color: $martech-text-primary;
      border: 1px solid $martech-border;

      &:focus {
        background-color: $martech-surface;
        box-shadow: 0 0 0 3px $martech-blue-focus;
        border: 1px solid $martech-blue-focus;
      }

      &:hover {
        background-color: $martech-surface;
        color: $martech-text-subdued;
      }

      &:active {
        color: $martech-text-primary;
      }
    }
  }

  &.martech-mint {
    background-color: $martech-mint-light;
    color: $martech-text-primary;
    border: 1px solid $martech-mint-light;

    &:focus {
      background: $martech-mint-dark;
      box-shadow: 0 0 0 3px $martech-blue-focus;
      border: 1px solid $martech-blue-focus;
    }

    &:hover {
      background: $martech-mint-hover;
      color: $martech-text-primary;
    }

    :deep(a) {
      background-color: $martech-mint-light;
      color: $martech-text-primary;
      border: 1px solid $martech-mint-light;

      &:focus {
        background: $martech-mint-dark;
        box-shadow: 0 0 0 3px $martech-blue-focus;
        border: 1px solid $martech-blue-focus;
      }

      &:hover {
        background: $martech-mint-hover;
        color: $martech-text-primary;
      }
    }
  }

  &.martech-cfb-orange-base {
    background-color: $martech-cfb-orange-base;
    color: $martech-black;
    border: 1px solid $martech-cfb-orange-base;

    &:focus {
      background: $martech-cfb-orange-light;
      box-shadow: 0 0 0 3px $martech-black;
      border: 1px solid $martech-cfb-orange-dark;
    }

    &:hover {
      background: $martech-cfb-orange-light;
      color: $martech-black;
      border: 1px solid $martech-cfb-orange-light;
    }

    :deep(a) {
      background-color: $martech-cfb-orange-light;
      color: $martech-black;
      border: 1px solid $martech-cfb-orange-light;

      &:focus {
        background: $martech-cfb-orange-dark;
        box-shadow: 0 0 0 3px $martech-black;
        border: 1px solid $martech-cfb-orange-dark;
      }

      &:hover {
        background: $martech-cfb-orange-base;
        color: $martech-black;
        border: 1px solid $martech-cfb-orange-light;
      }
    }
  }

  //disabled button
  &.is-disabled {
    background-color: $martech-surface-alt;
    border: 1px solid transparent;
    color: $martech-gray-40;
    pointer-events: none;
    cursor: default;

    :deep(a) {
      background-color: $martech-surface-alt;
      border: 1px solid transparent;
      color: $martech-gray-40;
      pointer-events: none;
      cursor: default;
    }
  }
}
</style>
