<template>
  <base-styles>
    <div class="third-party-spotlight" :data-testid="componentID()" :class="[{'minified' : minified}, {'is-card-minified' : isCardImage && minified}]">
      <!--This image is used only for phone and minified versions of the card-->
      <div class="image-wrapper" v-if="(isPhone || minified) && imageUrl" :class="[{'card-img' : isCardImage}, {'minified' : minified}]">
        <img :src="imageUrl" :alt="imageAltText || title">
      </div>
      <div class="header">
        <div class="group-wrapper">
          <h2>{{ title }}</h2>
          <p class="secondary-text">{{ secondaryText }}</p>
        </div>
        <!--This button is used only for the minified version of the card -->
        <base-button
              v-if="productUrl && minified"
              btn-style="martech-primary"
              btn-size="martech-medium"
              class="buy-button"
              icon-classes="martech-icon martech-right martech-external-link-icon martech-small martech-white"
              :link="{linkText: buttonText, linkUrl: productUrl, urlTarget: '_blank'}"/>
      </div>
      <!-- only displays on the non minified card -->
      <div class="body-group" v-if="!minified">
        <div class="image-wrapper" v-if="!isPhone && imageUrl" :class="{'card-img' : isCardImage}">
          <img :src="imageUrl" :alt="imageAltText || title">
        </div>
        <div class="body-text" v-if="description">
          <div class="group-wrapper">
            <p class="martech-text-bold">About this item</p>
            <p>{{ description }}</p>
          </div>

          <base-button
              v-if="productUrl"
              btn-style="martech-primary"
              btn-size="martech-medium"
              class="buy-button"
              icon-classes="martech-icon martech-right martech-external-link-icon martech-small martech-white"
              :link="{linkText: buttonText, linkUrl: productUrl, urlTarget: '_blank'}"/>
        </div>
      </div>
    </div>
  </base-styles>
</template>

<script setup>
import useComponentId from '@/use/useComponentId';
import useDeviceType from '@/use/deviceType';
import BaseButton from '@/components/elements/BaseButton.vue';

defineProps({
  title: {
    type: String,
    required: true,
  },
  secondaryText: {
    type: String,
    required: false,
  },
  imageUrl: {
    type: String,
    required: false,
  },
  imageAltText: {
    type: String,
    required: false,
  },
  description: {
    type: String,
    required: false,
  },
  productUrl: {
    type: String,
    required: false,
  },
  buttonText: {
    type: String,
    required: false,
    default: 'Find it on eBay',
  },
  minified: {
    type: Boolean,
    required: false,
  },
  isCardImage: {
    type: Boolean,
    required: false,
    default: false,
  }
})

const { componentID } = useComponentId();
const { isPhone } = useDeviceType();
</script>

<style lang="scss" scoped>
.third-party-spotlight {
  background: $martech-white;
  border-radius: $martech-radius-large;
  border: 1px solid $martech-border;
  padding: $martech-spacer-4; 
  width: 100%;
  -webkit-font-smoothing: antialiased;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  font-family: $martech-display-inter;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.20);
  margin: auto;

  @include breakpoint(1024) {
    padding: $martech-spacer-5;
  }

  @include breakpoint(1200) {
    max-width: 928px;

    &.is-card-minified {
      max-width: 367px;
    }
  }

  &.minified {
    display: flex;
    flex-direction: column;

    @include breakpoint(768) {
      flex-direction: row;

      &.is-card-minified {
        flex-direction: column;
      }
    }

    &.is-card-minified {
      :deep(.martech-button) {
        margin-bottom: 0;
        margin-top: $martech-spacer-4;
      }
    }

    .header {
      margin-bottom: 0;

      @include breakpoint(768) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      h2 {
        @include breakpoint(1024) {
          font-size: 27.65px;
          line-height: 41.5px;
        }
      }
    }

    .image-wrapper {
      @include breakpoint(768) {
        height: 200px;
        flex: 0 0 200px;
        margin-right: $martech-spacer-5;
        margin-bottom: 0;

        &.card-img {
          &.minified {
            margin-right: 0;
            margin-bottom: $martech-spacer-4;
          }
        }
      }
    }
  }

  .header {
    margin-bottom: $martech-spacer-4;

    h2 {
      font-size: 25.63px;
      line-height: 38.5px;
      font-weight: $martech-weight-bold;
      margin-bottom: $martech-spacer-1;



      @include breakpoint(1024) {
        font-size: 33.18px;
        line-height: 50px;
      }
    }

    p {
      font-size: $martech-type-13;
      line-height: 20px;
    }
  }

  .body-group {
    display: flex;
    grid-gap: 32px;

    .body-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include breakpoint(1024) {
        width: 50%;
      }

      p {
        &:first-of-type {
          margin-bottom: $martech-spacer-3;
          line-height: 24px;
        }

        &:last-of-type {
          line-height: 28px;
        }
      }
    }
  }

  .image-wrapper {
    height: 300px;
    flex: 0 0 300px;
    width: 300px;
    border-radius: $martech-radius-large;
    overflow: hidden;
    display: block;
    margin: 0 auto $martech-spacer-4 auto;
    position: relative;

    &.card-img {
      height: 440px;
      flex: 0 0 440px;

      @include breakpoint(768) {
        height: 440px;
        flex: 0 0 300px;

        &.minified {
          flex: 0 0 440px;
        }
      }
    }

    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: (1 / 1) * 100%;
    }

    img {
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    @include breakpoint(1024) {
      margin: 0;
    }
  }

  :deep(.martech-button) {
    width: 100%;
    margin-top: $martech-spacer-4;

    @include breakpoint(768) {
      width: 300px;
      margin-top: 0;
    }
  }
}
</style>