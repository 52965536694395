<template>
  <base-styles>
    <div class="accordion-menu" :data-testid="componentID()">
      <button
        class="accordion-menu__button"
        :aria-expanded="isOpen"
        :aria-controls="`collapse-${unique}`"
        @click="toggleAccordion()">
        <slot name="text"/>
        <chevron class="chevron" :class="{ 'is-active': isOpen}"/>
      </button>

      <div v-show="isOpen" :id="`collapse-${unique}`" class="accordion-dropdown">
        <slot name="content"/>
      </div>
    </div>
  </base-styles>
</template>

<script>
import Chevron from '@/components/iconography/Chevron.vue';
import componentId from '@/mixins/componentId';
import BaseStyles from '@/components/BaseStyles.vue';

export default {
  name: 'accordion-menu',
  components: {
    Chevron,
    BaseStyles,
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    unique() {
      return Date.now() + Math.random().toString().slice(2); // Not perfect but should suffice for this usage
    },
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
      this.$emit('toggle-accordion', this.isOpen);
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion-menu {
  width: 100%;
  background-color: $martech-white;
  border-bottom: 1px solid $martech-border;

  &__button {
    font-family: $martech-display-inter;
    color: $martech-text-primary;
    font-size: $martech-type-16;
    display: flex;
    align-items: center;
    background: transparent;
    padding: $martech-spacer-3 $martech-spacer-3;
    width: 100%;
    text-align: left;
    justify-content: space-evenly;

    @include breakpoint(1024) {
      padding: $martech-spacer-2 $martech-spacer-3;
    }

    .chevron {
      color: $martech-blue;
      width: 14px;
      margin: $martech-spacer-3 0 $martech-spacer-3 $martech-spacer-3;
      transition: transform 200ms ease-in-out;

      &.is-active {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
