<template>
<div v-if="!noNormalPrices || !noFoilPrices" class="price-points-table" :data-testid="componentID()">
  <p class="heading">Current Price Points</p>
  <div class="price-points__table">
    <table>
      <tr class="table-header">
        <th>Price Point</th>
        <th class="condition" v-if="!noNormalPrices">Normal</th>
        <th class="condition" v-if="!noFoilPrices">Foil</th>
      </tr>
      <tr class="row">
        <td>Market Price</td>
        <td class="price" v-if="!noNormalPrices">
          {{ formattedPrice(pricePoints?.Normal?.marketPrice) }}
        </td>
        <td class="price" v-if="!noFoilPrices">
          {{ formattedPrice(pricePoints?.Foil?.marketPrice) }}
        </td>
      </tr>
      <tr class="row">
        <td>Listed Median Price</td>
        <td class="price" v-if="!noNormalPrices">
          {{ formattedPrice(pricePoints?.Normal?.listedMedianPrice) }}
        </td>
         <td class="price" v-if="!noFoilPrices">
          {{ formattedPrice(pricePoints?.Foil?.listedMedianPrice) }}
        </td>
      </tr>
    </table>
  </div>
</div>
</template>

<script setup>
import { computed } from 'vue';
import useComponentId from '@/use/useComponentId';
import prices from '@/lib/prices';

const props = defineProps({
  pricePoints: {
    type: Object,
    required: true,
    default: () => ({
      pricePoints: {
        Normal: {},
        Foil: {},
      },
    }),
  },
})

const { componentID } = useComponentId();

if (!props.pricePoints.Normal) {
  props.pricePoints.Normal = {};
}

if (!props.pricePoints.Foil) {
  props.pricePoints.Foil = {};
}

const noFoilPrices = computed(() => {
  return Object.values(props.pricePoints.Foil).every(x => x === null);
});

const noNormalPrices = computed(() => {
  return Object.values(props.pricePoints.Normal).every(x => x === null);
});

function formattedPrice(price) {
  return price ? prices.marketPrice(price) : '-';
}
</script>

<style lang="scss" scoped>
.price-points-table {
  width: 100%;
  padding: $martech-spacer-3 0;

  .heading {
    font-weight: $martech-weight-semibold
  }

  .heading {
    font-size: $martech-type-16;
    margin-bottom: $martech-spacer-2;
  }

  table {
    font-weight: $martech-weight-normal;
    font-size: $martech-type-14;
    border: 1px solid $martech-border;
    border-radius: $martech-radius-medium;
    border-collapse: separate;

    .table-header {
      border-bottom: 1px solid $martech-border;

      th {
        text-align: left;
        padding: $martech-spacer-2 $martech-spacer-3;

        &.condition {
          text-align: right;
        }
      }
    }

    .row {
      border-bottom: 1px solid $martech-border;

      td {
        padding: $martech-spacer-2 $martech-spacer-3;
        border-bottom: 0;

        &.price {
          text-align: right;
        }
      }

      &:nth-child(even) {
        background-color: $martech-surface-alt;
      }

      &:last-of-type {
        border-bottom: 0px;

        td {
          border-bottom-left-radius: $martech-radius-medium;
          border-bottom-right-radius: $martech-radius-medium;
        }
      }
    }
  }
}
</style>