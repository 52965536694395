<template>
  <!--eslint-disable-next-line-->
  <svg v-if="outline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31" :data-testid="componentID()"><g data-name="Layer 2"><path d="M31 15.5A15.5 15.5 0 1115.5 0 15.5 15.5 0 0131 15.5zm-29 0A13.5 13.5 0 1015.5 2 13.5 13.5 0 002 15.5zm12 6V14h-.75a.76.76 0 01-.75-.75v-.5a.76.76 0 01.75-.75h3a.76.76 0 01.75.75v8.75h.75a.76.76 0 01.75.75v.5a.76.76 0 01-.75.75h-4.5a.76.76 0 01-.75-.75v-.5a.76.76 0 01.75-.75zm3.5-13a2 2 0 11-2-2 2 2 0 012 2z" data-name="Layer 1" fill="currentColor"/></g></svg>

  <!--eslint-disable-next-line-->
  <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31" class="martech-info" :data-testid="componentID()"><path d="M31 15.5A15.5 15.5 0 1 1 15.5 0 15.5 15.5 0 0 1 31 15.5Zm-12 5.8a.8.8 0 0 0-.8-.8h-.7v-6.3a.8.8 0 0 0-.8-.7h-4a.8.8 0 0 0-.7.8v1.4a.8.8 0 0 0 .8.8h.7v4h-.8a.8.8 0 0 0-.7.8v1.4a.8.8 0 0 0 .8.8h5.4a.8.8 0 0 0 .8-.8ZM12.9 9.4a2.6 2.6 0 1 0 2.6-2.6 2.6 2.6 0 0 0-2.6 2.6Z" data-name="Layer 1" fill="currentColor"/>
  </svg>
</template>]

<script>
import componentId from '@/mixins/componentId';

export default {
  name: 'info-icon',
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  props: {
    outline: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
