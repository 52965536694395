<template>
  <div ref="headerRef" class="charts-header" :data-testid="componentID()">
    <template v-for="(variantData, variantName) in data">
      <template v-for="(type, typeName) in variantData">
        <div v-if="type && type.checked" :key="`${variantName}-${typeName}`" class="charts-row">
          <div class="charts-color" :style="`background-color: ${type.colorBackground}`">
            <div class="charts-group" :style="{color: type.color}">
              <graph-icon class="graph-icon"/>
              <div class="charts-title">
                {{ isDetailed ? type.name : variantName }}
              </div>
            </div>
            <div class="charts-price">
              {{ getValue(type.value) }}
            </div>
          </div>
          <span
            v-if="showPriceChange && type.change"
            :class="{ 'charts-positive': type.change > 0, 'charts-negative': type.change < 0 }"
            class="charts-change">
            ({{ `${type.change < 0 ? '' : '+'}${type.change.toFixed(2)}` }}%)
          </span>
        </div>
      </template>
    </template>
    <div v-if="timeFrame" ref="container" class="charts-container">
      <div v-show="linePosition >= 0" ref="dateDisplayRef" class="charts-date" :style="{ left: `${datePosition(linePosition)}px` }">
        {{ dateDisplay }}
      </div>
      <div v-show="linePosition < 0" class="charts-timeframe">
        {{ timeFrameDisplay }}
      </div>
    </div>
  </div>
</template>

<script setup>
import delve from 'dlv';
import { ref, onMounted, computed, getCurrentInstance, nextTick } from 'vue';
import { get } from '@vueuse/core';
import GraphIcon from '@/components/iconography/GraphIcon.vue';
import useComponentId from '@/use/useComponentId';

const { componentID } = useComponentId();

const headerRef = ref(null);
const container = ref(null);
const dateDisplayRef = ref(null);

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const dateFormatter = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric', });

let lastLinePosition = -5;

const props = defineProps({
    isDetailed: {
      type: Boolean,
      required: false,
      default: false,
    },
    variant: {
      type: String,
      required: false,
      default: 'Normal',
    },
    data: {
      type: Object,
      required: true,
    },
    change: {
      type: Number,
      required: false,
      default: 0,
    },
    timeFrame: {
      type: String,
      required: true,
    },
    linePosition: {
      type: Number,
      required: true,
    },
    showPriceChange: {
      type: Boolean,
      required: false,
      default: false,
    },
});

const instance = getCurrentInstance();

onMounted(() => {
  // For some reason stuff gets cut off if min-height doesn't exist, and we don't want the extra space
  // above the new changes
  if (get(headerRef) && props.timeFrame === null) {
    get(headerRef).style['min-height'] = '23px';
  }
});

const dateDisplay = computed(() => {
  for (const [_, data] of Object.entries(props.data)) {
    for (const [_, typeData] of Object.entries(data)) {
      if (typeData.date) {
        // Isn't a range, just return it
        if (props.timeFrame === 'month') return dateFormatter.format(new Date(typeData.date));

        // Anything else is a range but we just have a date so we need to calculate it
        let range; // Days

        switch (props.timeFrame) {
          case 'annual':
          case 'semi-annual':
            range = 6;
            break;
          case 'quarter':
            range = 2;
            break;
          default:
            return typeData.date;
        }

        const endDate = new Date(typeData.date);
        const startDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24 * range);

        return `${dateFormatter.format(startDate)} — ${dateFormatter.format(endDate)}`;
      }
    }
  }

  return '';
});

const timeFrameDisplay = computed(() => {
  switch (props.timeFrame) {
    case 'month': return 'Past Month';
    case 'quarter': return 'Past 3 Months';
    case 'semi-annual': return 'Past 6 Months';
    case 'annual': return 'Past Year';
    case 'hide': return null;
    default: return 'Everything';
  }
});

const getValue = (val) => {
  return isNaN(val) || val === 'N/A' ? 'N/A' : formatter.format(val);
};

const forceUpdate = async () => {
  await nextTick();
  instance?.proxy?.$forceUpdate();
};

const datePosition = (position) => {
  const containerWidth = delve(get(container), 'clientWidth', 0);
  const dateDisplayWidth = delve(get(dateDisplayRef), 'clientWidth', 0);

  // Make sure we get the proper date display width but don't get ourselves into an infinite loop
  if (lastLinePosition !== props.linePosition) {
    forceUpdate();
  }

  lastLinePosition = props.linePosition;

  // Don't let it run off the right side
  if (position > containerWidth - dateDisplayWidth) {
    return containerWidth - dateDisplayWidth - 5;
  }

  // Don't let it run off the left side
  if (position - (dateDisplayWidth / 2) < 5) {
    return 5;
  }

  // Try to center over the line
  return position - (dateDisplayWidth / 2);
};
</script>

<style lang="scss" scoped>
.charts-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: $martech-display-inter;
  font-size: $martech-type-14;
  font-weight: $martech-weight-normal;
  min-height: 73px;

  .charts-row {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
    align-items: flex-start;
    line-height: 1rem;

    .charts-group {
      display: flex;
      align-items: center;
    }

    .graph-icon {
      width: 15px;
      margin-right: $martech-spacer-2;
    }

    .charts-color {
      display: flex;
      margin-left: 6px;
      border-radius: 10px;
      padding: 0 $martech-spacer-2;
    }

    .charts-price {
      margin-left: 6px;
      text-align: right;
      display: flex;
      line-height: $martech-type-20;
      font-size: $martech-type-13;
      font-weight: $martech-weight-semibold;
    }

    .charts-change {
      margin-left: $martech-spacer-2;
      font-size: $martech-type-13;
      line-height: $martech-type-20;

      &.charts-positive {
        color: $martech-charts-positive-change;
        font-weight: normal;
      }
      &.charts-negative {
        color: $martech-charts-negative-change;
        font-weight: normal;
      }
    }
  }

  .charts-container {
    height:25px;
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;

    .charts-date {
      position: absolute;
      left: 0;
      white-space: nowrap;
      font-size: $martech-type-13;
      font-weight: $martech-weight-normal;
      letter-spacing: 0.2px;
      margin-top: $martech-spacer-2;
      color: $martech-text-subdued;
    }

    .charts-timeframe {
      text-align: right;
      color: $martech-text-subdued;
      font-size: $martech-type-13;
      margin-top: $martech-spacer-2;
    }
  }

  .charts-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: $martech-weight-normal;
    font-size: $martech-type-13;
    text-align: right;
    line-height: $martech-type-20;
    color: $martech-text-primary;
    letter-spacing: 0.2px;
  }
}
</style>
