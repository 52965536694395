<template>
  <svg id="copy-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32">
    <!--eslint-disable-next-line max-len-->
    <path d="M28 6.24V23c0 1.66-1.34 3-3 3h-5v3c0 1.66-1.34 3-3 3H3c-1.66 0-3-1.34-3-3V9c0-1.66 1.34-3 3-3h5V3c0-1.66 1.34-3 3-3h10.76c.69 0 1.64.39 2.12.88l3.24 3.24c.48.49.88 1.44.88 2.12ZM17 28.62V26h-6c-1.66 0-3-1.34-3-3V9H3.38a.38.38 0 0 0-.38.38v19.24c0 .21.17.38.38.38h13.25c.21 0 .38-.17.38-.38Zm8-6V10h-5.5c-.83 0-1.5-.67-1.5-1.5V3h-6.62a.38.38 0 0 0-.38.38v19.24c0 .21.17.38.38.38h13.25c.21 0 .38-.17.38-.38Zm0-16.23c0-.09-.05-.2-.11-.27L21.87 3.1a.386.386 0 0 0-.27-.11H21V7h4v-.6Z" style="fill:#231f20"/></svg>
</template>
<script>
export default {
  name: 'close-icon',
};
</script>
<style lang="scss" scoped>
#copy-icon {
  width: 14px;
}
</style>
