<template>
  <base-styles>
    <label tabindex="0" class="martech-radio" :data-testid="componentID()" @keypress.space.prevent.stop="clickRadio">
      <span :class="[{'disabled' : disabled}]" class="martech-radio-input">
        <input
          tabindex="-1"
          :id="id"
          :disabled="disabled"
          :class="{ 'checked': selected}"
          type="radio"
          name="radio"
          :value="radioValue"
          @change.passive="$emit('on-change', $event)"
          :data-testid="componentID(label)">
        <span class="martech-radio-control"/>
      </span>
      <span v-if="customLabelAsSlot" class="martech-label-group" :class="[{'disabled' : disabled }]">
        <slot/>
      </span>
      <span v-else class="martech-label-group" :class="[{'disabled' : disabled }]">
        <span>{{ label }}</span>
        <span class="martech-count">{{ count }}</span>
      </span>
    </label>
  </base-styles>
</template>

<script>
import BaseStyles from '@/components/BaseStyles.vue';
import componentId from '@/mixins/componentId';

export default {
  name: 'default-radio',
  components: {
    BaseStyles,
  },
  model: {
    event: 'change',
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    radioValue: {
      type: [ String, Boolean ],
      default: '',
    },
    selected: {
      type: [ Boolean, String ],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    customLabelAsSlot: {
      type: Boolean,
      default: false,
    },
    count: {
      type: String,
      default: '',
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  methods: {
    clickRadio() {
      const radio = document.getElementById(this.id);

      if (radio) {
        radio.click();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.martech-radio {
  margin-bottom: 0.65rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:last-child {
    padding-bottom: 0;
  }

  .martech-label-group {
    padding-left: $martech-spacer-2;
    letter-spacing: 0.2px;
    line-height: 20px;
    color: $martech-text-primary;
    font-size: $martech-type-14;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .martech-count {
      color: $martech-text-subdued;
      font-size: $martech-type-12;
    }

    &.disabled {
      color: $martech-text-subdued;
    }
  }

  &-input {
    display: flex;

    input {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
      margin: 0;

      &.checked + .martech-radio-control {
        background: radial-gradient($martech-blue 35%, rgba(255, 0, 0, 0) 45%);
        border: 2px solid $martech-blue;
      }
    }

    &.disabled {
      .martech-radio-control {
        border: 2px solid $martech-border;
      }

      .martech-radio-label {
        color: $martech-text-subdued;
      }
    }
  }

  &.is-active {
    .radio-control {
      background: radial-gradient($martech-blue 35%, rgba(255, 0, 0, 0) 45%);
      border: 2px solid $martech-blue;
    }
  }

  &-control {
    display: block;
    transition: all 400ms ease-in;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid $martech-black;
    transform: translateY(-0.05em);
    cursor: pointer;
  }
}

.martech-radio:last-of-type() {
  padding-bottom: 0;
}
</style>
