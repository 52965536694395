<template>
  <div class="charts-settings-display" :data-testid="componentID()">
    <div class="charts-overlay-wrapper">
      <div class="charts-parent-container">
        <div class="charts-inner-container">
          <div class="charts-title">
            Price Points
          </div>
          <ul class="charts-options">
            <template v-for="(variantData, variant) in availableVariants">
              <li v-for="type in Object.keys(variantData)" :key="`${variant}-${type}`">
                <checkbox
                  :id="`${variant}-${type}`"
                  :key="`${variant}-${type}`"
                  :checked="variantData[type].checked"
                  :color="variantData[type].color"
                  :label="getLabel(variant, variantData[type].name)"
                  :checkbox-value="`${variant}-${type}-${variantData[type].color}`"
                  @on-change="checkboxChange"/>
              </li>
            </template>
          </ul>
        </div>
        <div class="charts-button-container">
          <div v-if="!checkedCount" class="charts-selectone">
            Select at least one price point.
          </div>
          <base-button ref="viewGraphBtn" class="charts-button" :class="{ disabled: !checkedCount, }" btn-style="martech-black" btn-size="martech-medium" @clicked="viewGraph">
            View Graph
          </base-button>
        </div>
      </div>
      <div class="charts-overlay" @click="viewGraph"/>
    </div>
  </div>
</template>

<script>
import delve from 'dlv';
import BaseButton from '@/components/elements/BaseButton.vue';
import Checkbox from '@/components/elements/Checkbox.vue';
import componentId from '@/mixins/componentId';

export default {
  name: 'settings-display',
  components: {
    BaseButton,
    Checkbox,
  },
  props: {
    availableVariants: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  mounted() {
    if (!delve(this, '$refs.viewGraphBtn')) return;

    const buttons = this.$refs.viewGraphBtn.$el.getElementsByTagName('button');
    if (!buttons || !buttons[0]) return;
    buttons[0].focus();
  },
  computed: {
    checkedCount() {
      let count = 0;
      Object.keys(this.availableVariants).forEach((variant) => {
        Object.keys(this.availableVariants[variant]).forEach((type) => {
          if (delve(this, `availableVariants.${variant}.${type}.checked`)) count++;
        });
      });

      return count;
    },
  },
  methods: {
    viewGraph() {
      if (this.checkedCount) this.$emit('settings');
    },
    getLabel(variant, type) {
      if (variant === 'Normal') return type;

      return `${variant} ${type}`;
    },
    checkboxChange(value) {
      const [ variant, type ] = value.split('-');
      this.$emit('change', variant, type);
    },
  },
};
</script>

<style lang="scss" scoped>
.charts-settings-display {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  font-family: $martech-display-inter;
  font-weight: $martech-weight-normal;

  .charts-container-wrapper {
    position: relative;
  }

  .charts-overlay {
      position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 6;
  }

  .charts-parent-container {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    width: auto;
    height: auto;
    margin: 1rem;
    border-radius: 8px;
    z-index: 7;
    padding: 1rem;

    .charts-inner-container {
      width: 100%;

      .charts-title {
        font-size: $martech-type-18;
        font-weight: $martech-weight-bold;
        margin-bottom: 1rem;
        width: 100%;
        line-height: $martech-type-20;
      }

      .charts-options {
        list-style-type: none;
        height: 100%;
        margin: 0;
        padding: 0;

        li {
          &:first-of-type {
            padding-top: 0.5rem;
          }
          padding-top: 1rem;
        }
      }
    }

    .charts-button-container {
      width: 100%;
      margin: 1rem 0 0;
      box-sizing: border-box;

      .charts-selectone {
        text-align: center;
        margin-bottom: $martech-spacer-2;
      }

      :deep(.martech-button) {
        width: 100%;
        border-radius: 8px;
        color: #fff;
        background-color: #000;
        border: none;
        font-weight: 500;
        font-size: 1rem;
        transition: all 300ms ease-in-out;
        cursor: pointer;

        &.disabled {
          background-color: $martech-gray-5;
          color: $martech-gray-30;
          border: 1px solid $martech-gray-30;
          pointer-events: none;
          cursor: default;
        }

        &:hover {
          background-color: #1E1E1E;
        }
      }
    }
  }
}
</style>
