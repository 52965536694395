<template>
  <base-styles>
    <div class="mobile-product-details" :data-testid="componentID()">
      <div v-show="show" class="mobile-product-details__drawer" :class="{ 'is-active': show }">
        <div class="mobile-product-details__header">
          <div class="group">
            <div class="heading-group">
              <h2 class="martech-sub-heading-sm">
                {{ data.name }}
              </h2>
              <p class="set-rarity">{{ data.setName }}<span v-if="data.rarity" class="martech-text-capitalize">, {{ data.rarity }}</span></p>
            </div>
            <button class="close-toggle" aria-label="close drawer" @click="$emit('close')">
              <close-icon aria-label="close drawer icon"/>
            </button>
          </div>
          <base-button
            btn-style="martech-black"
            btn-size="martech-medium"
            class="buy-button"
            icon-classes="martech-icon martech-right martech-external-link-icon martech-small martech-white"
            :link="{linkText: 'Buy on TCGplayer', linkUrl: productURL, urlTarget: '_blank'}"
            @clicked="$emit('amplitude-event')"/>
        </div>
        <div class="mobile-product-details__image">
          <image-card
            v-if="isProduct"
            :loaded="loaded"
            :card-data="data"
            :card-dimensions="{width: cardWidth, height: 'auto'}"
            :is-product="true"/>
          <image-card
            v-else
            :loaded="loaded"
            :card-data="data"
            :allow-flip="false"
            :card-dimensions="{width: imageDimensions.width, height: imageDimensions.height}"
            :is-flipped="isFlipped"
            @flip="$emit('flip')"/>
          <div class="flip-button" v-if="showFlipButton" @click="$emit('flip')">
            <flip-icon class="flip-icon"/>
          </div>
        </div>
        <div class="mobile-product-details__columns">
          <tabs :tabs="tabs" :on-change="onTabChange" :default-tab="defaultTab" :large-tabs="false"/>
          <div v-if="activeTab === 'details'" class="mobile-product-details__column">
            <div class="martech-card-spotlight__details">
              <p class="details-header martech-text-semibold">Product Details</p>
              <div v-if="data.game && data.game.toLowerCase() === 'magic' && !isProduct" class="mana-type">
                <mana-cost v-if="data.manaCost" :mana-cost="data.manaCost"/>
              </div>
              <p v-if=" data.cardType || data.typeLine" class="details-header">
                Card Type: <span>{{ data.cardType || data.typeLine }}</span>
              </p>
              <card-description :vertical="data.game" :description="data.description"/>
              <price-points :price-points="data.pricePoints" v-if="!isProduct"/>
            </div>
          </div>
          <div v-else class="martech-card-spotlight__column">
            <div class="martech-card-spotlight__graph">
              <price-history 
              :disable-settings="disablePriceHistorySettings"
              :disable-timeframe="disablePriceHistoryTimeframe" 
              :product-id="getCardId"
              :condition="condition"
              :language="language"
              :printing="printing"
              :display-volume="true"
              display-card-title
              :card-name="data.name"
              :set-name="data.setName"
              :allow-skip-days="false"/>
            </div>
          </div>
        </div>
      </div>
      <screen-overlay v-if="show" @close-overlay="$emit('close')"/>
    </div>
  </base-styles>
</template>

<script setup>
import { computed, ref } from 'vue';
import { get, set } from '@vueuse/core';
import BaseStyles from '@/components/BaseStyles.vue';
import Tabs from '@/components/elements/Tabs.vue';
import BaseButton from '@/components/elements/BaseButton.vue';
import PriceHistory from '@/components/price-history/History.vue';
import ImageCard from '@/components/image/Card.vue';
import CardDescription from '@/components/product-details/parts/CardDescription.vue';
import CloseIcon from '@/components/iconography/CloseIcon.vue';
import ScreenOverlay from '@/components/elements/ScreenOverlay.vue';
import ManaCost from '@/components/product-details/parts/ManaCost.vue';
import useComponentId from '@/use/useComponentId';
import useDeviceType from '@/use/deviceType';
import prices from '@/lib/prices';
import FlipIcon from '@/components/iconography/FlipIcon.vue';
import PricePoints from '@/components/product-details/parts/PricePoints.vue';

const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: () => ({
      name: '',
      flipImageURL: '',
      imageURL: '',
      layout: '',
      marketPrice: '',
      pricePoints: {
        Normal: {},
        Foil: {},
      },
      rarity: '',
      setName: '',
      description: '',
      productUrl: '',
      tcgPlayerID: '',
      typeLine: '',
      convertedManaCost: '',
    }),
  },
  isProduct: {
    type: Boolean,
    required: false,
    default: false,
  },
  show: {
    type: Boolean,
    required: false,
    default: false,
  },
  loaded: {
    type: Boolean,
    required: false,
    default: false,
  },
  isFlipped: {
    type: Boolean,
    required: false,
    default: false,
  },
  cardProductUrl: {
    type: String,
    default: '',
    required: false,
  },
  cardId: {
    type: [ Number, String, ],
    default: '',
    required: false,
  },
  defaultTab: {
    type: String,
    default: 'details',
    required: false,
  },
  imageDimensions: {
    type: Object,
    required: false,
    default: () => ({
      width: '200px',
      height: '280px',
    }),
  },
  variantId: {
    type: String,
    required: false,
    default: '',
  },
  disablePriceHistorySettings: {
    type: Boolean,
    default: false,
  },
  disablePriceHistoryTimeframe: {
    type: Boolean,
    default: false,
  },
  printing: {
    type: Array,
    default: null,
  },
  condition: {
    type: Array,
    default: null,
  },
  language: {
    type: Array,
    default: null,
  },
});

const { componentID } = useComponentId();
const { windowWidth } = useDeviceType();

let activeTab = ref(props.defaultTab);
let tabs = [
  { title: 'details', },
  { title: 'price graph', }
];


const formatPrice = computed(() => ( prices.marketPrice(props.data.marketPrice) ));
const productURL = computed(() => (
  props.cardProductUrl
  || props?.data?.productUrl
  || props?.data?.tcgProductUrl
  || ''
));
const getCardId = computed(() => ( props?.data?.tcgPlayerID || props.cardId ));
const cardWidth = computed(() => ( get(windowWidth) >= 768 && get(windowWidth) <= 1024 ? '320px' : '200px' ));

const showFlipButton = computed(() => (props?.data?.layout || '').match(/transform|modal_dfc/));

function onTabChange(tab) {
  set(activeTab, tab.toLowerCase());
}
</script>

<style lang="scss" scoped>
.mobile-product-details {
  position: fixed;
  z-index: 2147483641;
  display: flex;

  &__drawer {
    display: inherit !important;
    position: fixed;
    background: $martech-white;
    top: 0;
    bottom: 0;
    left: -1000px;
    width: 0;
    overflow-y: scroll;
    z-index: 999;
    height: 100vh;
    transition: all 300ms ease-in-out;
    color: $martech-text-primary;
    display: flex;
    flex-direction: column;
    font-weight: $martech-weight-normal;
    font-family: $martech-display-inter;

    &.is-active {
      left: 0;
      width: 95vw;
    }
  }

  &__header {
    padding: $martech-spacer-3;

    .group {
      display: flex;
      justify-content: space-between;
    }

    h2 {
      margin-bottom: 0;
      font-family: $martech-display-inter;
    }
  }

  .set-rarity {
    color: $martech-text-subdued;
    font-size: $martech-type-13;
  }

  :deep(.martech-tabs) {
    margin-bottom: $martech-spacer-3;
  }

  :deep(.martech-button) {
    margin-top: $martech-spacer-3;
    width: 100%;
  }

  &__columns {
    padding: 0 $martech-spacer-3;
  }

  &__image {
    position: relative;
    margin: 0 auto;
    padding: $martech-spacer-2 0 $martech-spacer-4 0;


    :deep(.martech-image) {
      display: flex;

      img {
        max-width: unset;
      }
    }

    .flip-button {
      position: absolute;
      right: 0.5rem;
      bottom: 0.5rem;
      box-shadow: 0px 12px 32px 2px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
      height: 36px;
      width: 36px;
      display: flex;
      align-items: center;
      z-index: 10;
      border-radius: $martech-radius-default;

      .flip-icon {
        width: 12px;
        height: 12px;
        margin: auto;
      }
    }
  }

  .details-header {
    font-weight: $martech-weight-semibold;
    font-size: $martech-type-14;
    margin-bottom: $martech-spacer-3;

    span {
      font-weight: $martech-weight-normal;
    }
  }

  &__graph {
    height: 290px;
    width: 100%;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: $martech-spacer-4;
    padding: $martech-spacer-3;

    p {
      margin: 0;
      padding-bottom: $martech-spacer-2;
      font-weight: $martech-weight-bold;
      letter-spacing: 0.25px;

      span {
        color: $martech-mint-dark;
      }
    }

    :deep(.martech-button) {
      width: 100%;
    }
  }
}

.close-toggle {
  align-self: flex-start;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  color: $martech-text-primary;

  #close-icon {
    height: 14px;
    width: 14px;
  }
}
</style>
