<template>
  <!--eslint-disable-next-line max-len-->
  <svg id="magnify-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" :data-testid="componentID()"><path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h7.75a.76.76 0 0 1 .75.75v1.5a.76.76 0 0 1-.75.75H3v6.25a.76.76 0 0 1-.75.75H.75A.76.76 0 0 1 0 9.25ZM9.25 28H1.5A1.5 1.5 0 0 1 0 26.5v-7.75A.76.76 0 0 1 .75 18h1.5a.76.76 0 0 1 .75.75V25h6.25a.76.76 0 0 1 .75.75v1.5a.76.76 0 0 1-.75.75Zm9.5-28h7.75A1.5 1.5 0 0 1 28 1.5v7.75a.76.76 0 0 1-.75.75h-1.5a.76.76 0 0 1-.75-.75V3h-6.25a.76.76 0 0 1-.75-.75V.75a.76.76 0 0 1 .75-.75ZM28 18.75v7.75a1.5 1.5 0 0 1-1.5 1.5h-7.75a.76.76 0 0 1-.75-.75v-1.5a.76.76 0 0 1 .75-.75H25v-6.25a.76.76 0 0 1 .75-.75h1.5a.76.76 0 0 1 .75.75Z" fill="currentColor" data-name="Layer 1"/></svg>
</template>

<script>
import componentId from '@/mixins/componentId';

export default {
  name: 'magnify-icon',
  setup() {
    const componentID = componentId;
    return { componentID };
  },
};
</script>

<style lang="scss" scoped>
#magnify-icon {
    width: 16px;
    height: 16px;
}
</style>
