<template>
  <base-styles v-if="open">
    <div class="martech-video-player" :data-testid="componentID()">
      <div class="header">
        <p class="aside"></p>
        <p class="title martech-text-md martech-text-semibold">{{ currentTitle }}</p>
        <p class="aside"><close-button name="Video Player" :dark="darkMode" @click.stop.prevent="close"/></p>
      </div>
      <div class="player">
        <video-player-embed
          :key="currentUrl"
          :mp4Url="currentUrl"
          :poster="currentPoster"
          :autoplay="autoplay"
          @play="played"
          @progress="progressed"
          @ended="ended"/>
      </div>
      <div v-if="playlist.length > 1" class="footer martech-text-sm martech-text-semibold" :class="{'dark-mode' : darkMode}">
        <p class="episodes"><layer-group-icon class="episode-icon"/> <multi-option-button :options="episodes" button-label="Episodes" @optionClicked="episodeChange"/></p>
        <p class="next">
          <button
            :class="{'is-disabled' : currentVideo <= 0}"
            :disabled="currentVideo <= 0"
            class="martech-text-sm martech-text-semibold"
            @click.stop.prevent="previousVideo">
            <step-backward-icon class="step-icon"/> Previous Episode
          </button>
        </p>
        <p class="next">
          <button
            :class="{'is-disabled' : currentVideo == (maxVideo-1)}"
            :disabled="currentVideo == (maxVideo-1)"
            class="martech-text-sm martech-text-semibold"
            @click.stop.prevent="nextVideo">
            <step-forward-icon class="step-icon "/> Next Episode
          </button>
        </p>
      </div>
    </div>
  </base-styles>
</template>

<script setup>
  import { computed, ref, onBeforeUnmount } from 'vue';
  import BaseStyles from '@/components/BaseStyles.vue';
  import useComponentId from '@/use/useComponentId';
  import VideoPlayerEmbed from '@/components/embeds/VideoPlayerEmbed.vue';
  import CloseButton from '@/components/elements/CloseButton.vue';
  import MultiOptionButton from '@/components/elements/MultiOptionButton.vue';
  import LayerGroupIcon from '@/components/iconography/LayerGroupIcon.vue';
  import StepForwardIcon from '@/components/iconography/StepForwardIcon.vue';
  import StepBackwardIcon from '@/components/iconography/StepBackwardIcon.vue';
  import { get, set, useEventListener } from '@vueuse/core';
  import amplitudeEvent from '@tcgplayer/amplitude';
  import { onBeforeRouteLeave } from 'vue-router';

  const emit = defineEmits(['close', 'ended', 'played']);
  const { componentID } = useComponentId();
  const props = defineProps({
    playlist: {
      type: Object,
      required: true,
    },
    selectedVideo: {
      type: Number,
      required: false,
    },
    darkMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false,
    },
    enableTracking: {
      type: Boolean,
      required: false,
      default: false,
    },
  });


  let endedCalled = false;
  let currentPosition = 0;
  let totalDuration = 0;

  const reset = () => {
    endedCalled = false;
    currentPosition = 0;
    totalDuration = 0;
  };

  const open = ref(true);
  function close() {
    emit('close');
    set(open, false);
  }

  const currentVideo = ref(props.selectedVideo || 0);
  const maxVideo = props.playlist.length;

  function episodeChange(val) {
    if (val >= 0 && val < maxVideo) {
      set(currentVideo, parseInt(val));
    }
  }

  const currentTitle = computed(() => {
    return `S${props.playlist[get(currentVideo)]?.season}:E${props.playlist[get(currentVideo)]?.position} "${props.playlist[get(currentVideo)]?.title}"`
  });

  const currentUrl = computed(() => {
    return props.playlist[get(currentVideo)]?.url;
  });

  const currentPoster = computed(() => {
    return props.playlist[get(currentVideo)]?.thumbnail;
  });

  const episodes = Object.assign({}, props.playlist.sort((a, b) => a.position < b.position ? -1 : a.position === b.position ? 0 : 1).map((video) => video.title))

  Object.keys(episodes).forEach(idx => {
    episodes[idx] = `Episode ${parseInt(idx)+1}: ${episodes[idx]}`;
  })

  const played = (data) => {
    emit('played', currentPosition === 0, get(currentTitle));

    if (data.duration && !totalDuration) {
      totalDuration = Math.floor(data.duration);
    }

    if (!currentPosition && props.enableTracking) {
      amplitudeEvent('general', 'videoStarted', {
        title: get(currentTitle),
      });
    }
  };

  const progressed = (data) => {
    currentPosition = Math.floor(data.currentTime);

    if (data.duration && !totalDuration) {
      totalDuration = Math.floor(data.duration);
    }
  };

  const ended = (data) => {
    if (endedCalled) return;
    endedCalled = true;

    if (data?.duration) {
      currentPosition = Math.floor(data.duration);
    }

    emit('ended', currentPosition, get(currentTitle));

    if (props.enableTracking) {
      amplitudeEvent('general', 'videoStopped', {
        title: get(currentTitle),
        currentTime: currentPosition,
        duration: totalDuration,
      });
    }

    reset();
  };

  function nextVideo() {
    ended();
    set(currentVideo, (currentVideo.value+1));
  }

  function previousVideo() {
    ended();
    set(currentVideo, (currentVideo.value-1));
  }

  // Leaving the page via navigation needs to track the end
  onBeforeRouteLeave(() => {
    ended();
  })

  // Leaving the page via closing the window/tab needs to track the end
  useEventListener(window, 'beforeunload', () => {
    ended();
  });

  // Closing the modal, routed away
  onBeforeUnmount(() => {
    ended();
  });
</script>

<style lang="scss" scoped>
  .martech-video-player {
    font-family: $martech-display-inter;
    height: 100%;
    margin: 0 auto;
    padding: $martech-spacer-3 0;

    width: 100%;
    z-index: 0;

    .header {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 35px;
      margin-bottom: $martech-spacer-3;

      .title {
        justify-self:center;
        flex-grow: 18;
        text-align: center;
      }
      .aside {
        align-self: flex-start;
        justify-self:center;
        flex-grow: 1;
        position: relative;
      }
    }
    .player {
      height: 80%;
    }
    .footer {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-top: $martech-spacer-3;

      &.dark-mode {
        color: $martech-white;

        .episodes {
          :deep(.martech-button) {
            color: $martech-white;
          }
        }

        .next {
          button {
            color: $martech-white;
          }
          .is-disabled {
            color: $martech-gray-100;
          }
        }
      }

      .episodes {
        display: flex;
        align-items: center;

        .episode-icon {
          width: 20px;
        }
        
        :deep(.martech-button) {
          border: none !important;
          font-weight: $martech-weight-semibold;
          font-family: $martech-display-inter;
          font-size: $martech-type-12;
          line-height: 20px;
          letter-spacing: 0.5px;
          margin: 0;
          padding: 0 $martech-spacer-2;
        }

        :deep(.multi-opt-button__options) {
          min-width: 300px;

          @include breakpoint(768) {
            min-width: 300px;
          }
        }
      }

      .next {
        height: 100%;
        position: relative;

        button {
          background: none;
          border: none;
          display: flex;
          align-items: center;
          padding: 0 $martech-spacer-2;
        }

        .is-disabled {
          color: $martech-gray-100;
        }
        
        .step-icon {
          width: 15px;
          margin-right: $martech-spacer-2;
        }
      }
    }
  }
</style>
