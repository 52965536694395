<template>
  <img
    src="https://tcgplayer-cdn.tcgplayer.com/channel-fireball/images/cfb-pro-logo-icon-linked.svg"
    alt="Pro"
    class="pro-icon"
    :data-testid="componentID()">
</template>

<script setup>
import useComponentId from '@/use/useComponentId';
const { componentID } = useComponentId();
</script>

<style lang="scss" scoped>
.pro-icon {
  width: 40px;
  position: absolute;
  z-index: 2;
  left: $martech-spacer-2;
  top: $martech-spacer-2;
  filter: drop-shadow(0 0 2.5px $martech-black);
}
</style>
