<template>
  <base-styles>
    <div v-clickOutside="hideOptions"
         class="martech-default-select"
         :data-testid="componentID()"
         @keydown.tab="tabKeyPressed = true"
         @mouseenter="cancelCloseSelect"
         @keydown.esc.exact="hideOptions"
         @keypress.enter.prevent="showOptions">
      <label v-if="hideLabel" :for="id" class="sr-only">{{ label }}</label>
      <div v-if="!hideLabel && label || required" class="martech-input-label" :class="{'is-hovered' : hovered}">
        <label :for="id" class="martech-detail" :class="{'label--is-error' : error}">{{ label }}</label>
        <span class="asterisk">
          <asterisk-icon v-if="required" class="required-marker"/>
        </span>
      </div>
      <div ref="selectButton"
           class="t-select"
           :class="[{'select--is-disabled': disabled},
                    {'select--is-focused': focused && !disabled},
                    {'select--is-active' : dropdownVisible && !disabled},
                    {'select--is-required' : inputIsRequired},
                    {'select--is-error' : error}]"
           tabindex="0"
           @mouseenter="hovered = true"
           @mouseleave="hovered = false"
           @click="showOptions">
        <div class="select-control">
          <div
            class="select-slot"
            :aria-expanded="dropdownVisible"
            aria-controls="owned_listbox"
            aria-haspopup="listbox"
            :class="[{'search' : search}, inputSize]">
            <!--multiselect or search w/ multiselect-->
            <div v-if="search" class="select-wrap">
              <div class="chips">
                <chip v-for="chip in appliedChips.slice().reverse()" :key="chip" :chip-text="chip" closeable @on-close="closeChip(chip)"/>
                <input
                  :id="id"
                  ref="select"
                  :data-testid="componentID(id)"
                  type="text"
                  :value="inputValue"
                  tabindex="0"
                  autocomplete="off"
                  @blur="onBlur"
                  @focus="inputFocus()"
                  @keypress.enter.prevent.stop="onEnter(ev)"
                  @input="inputValue = $event.target.value">
              </div>
              <div class="icon-group">
                <button v-show="(appliedChips && appliedChips.length > 0) || inputValue" class="clear-tags" @click="clearAll">
                  <icon-base
                    icon-name="clear all tags"
                    icon-color="#000000"
                    view-box="0 0 32 32"
                    width="10"
                    height="10">
                    <close-icon/>
                  </icon-base>
                </button>
                <chevron direction="down" class="icon"/>
              </div>
            </div>
            <div v-else class="select-wrap">
              <input
                :id="id"
                ref="select"
                :data-testid="componentID(id)"
                :value="Array.isArray(optionLabel(modelValue)) ? optionLabel(modelValue).join(', ') : optionLabel(modelValue)"
                type="text"
                readonly
                @focus="inputFocus()"
                @blur="onBlur"
                @input="$emit('change', $event.target.value)">
              <chevron direction="down" class="icon"/>
            </div>
          </div>
        </div>
      </div>
      <transition name="select-slide">
        <ul v-show="!disabled && dropdownVisible"
            ref="options"
            class="dropdown-menu shadow"
            role="listbox"
            tabindex="0"
            :aria-labelledby="id"
            :aria-activedescendant="activeDescendant"
            @focus="setOptionFocus">
          <!--multiselect & search options-->
          <template v-if="multiselect || search">
            <li
              v-for="(option, key) in filteredOptions"
              :key="key"
              tabindex="-1"
              :class="{'is-active' : initial === key || initial === option}"
              class="dropdown-option"
              @keydown.esc.exact="hideOptions"
              @click="labelClick">
              <label class="checkbox checkbox-target capitalize"
                     tabindex="0"
                     @keypress.space.enter.prevent.stop="clickCheckbox(option)">
                <input
                  :id="`select-${option}`"
                  :ref="`checkbox-${option}`"
                  v-model="checked"
                  tabindex="-1"
                  :value="option"
                  type="checkbox"
                  @change="updateValue">
                <span @click="labelClick">
                  <span class="checkmark"/>
                  {{ optionLabel(option) }}
                </span>
              </label>
            </li>
          </template>
          <template v-else>
            <li
              v-for="[key, option] in optionsMap"
              :id="`${id}-option-${key}`"
              :key="option.id"
              :aria-selected="activeOptionIndex === key"
              :class="{'is-active' : initial === key || initial === option}"
              class="dropdown-option"
              role="option"
              tabindex="0"
              :value="key"
              @keypress.space.exact.prevent="handleOptionClick(key, option)"
              @keydown.esc.exact="hideOptions"
              @keydown.enter.exact.prevent="handleOptionClick(key, option)"
              @click="handleOptionClick(key, option)">
              <div class="option-title martech-text-capitalize">
                {{ optionLabel(option) }}
              </div>
            </li>
          </template>
        </ul>
      </transition>
      <div v-show="message" class="select-message">
        <p>{{ message }}</p>
      </div>
    </div>
  </base-styles>
</template>

<script>
import delve from 'dlv';
import BaseStyles from '@/components/BaseStyles.vue';
import Chevron from '@/components/iconography/ChevronSolid.vue';
import Chip from '@/components/elements/Chip.vue';
import CloseIcon from '@/components/iconography/CloseIcon.vue';
import IconBase from '@/components/iconography/IconBase.vue';
import componentId from '@/mixins/componentId';
import AsteriskIcon from '@/components/iconography/AsteriskIcon.vue';
import clickOutside from '@/directives/clickOutside';

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false,
    MODE: 3,
  },
  name: 'martech-default-select',
  components: {
    Chevron,
    Chip,
    CloseIcon,
    IconBase,
    AsteriskIcon,
    BaseStyles,
  },
  directives: {
    clickOutside,
  },
  model: {
    event: 'change',
  },
  props: {
    id: {
      type: String,
      required: true,
      default: 'select',
    },
    onChange: {
      type: Function,
      required: true,
      default: (key, option) => {},
    },
    options: {
      type: [ Object, Array, Map ],
      required: true,
    },
    initial: {
      type: [ String, Boolean, Array ],
      required: false,
      default: 'key',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    message: {
      type: String,
      required: false,
      default: '',
    },
    multiselect: {
      type: Boolean,
      required: false,
      default: false,
    },
    search: {
      type: Boolean,
      required: false,
      default: false,
    },
    incomingChips: {
      type: Array,
      required: false,
      default: () => [],
    },
    // eslint-disable-next-line vue/require-default-prop
    modelValue: {
      type: [ String, Number, Array ],
      required: false,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    optionText: {
      type: String,
      required: false,
      default: '',
    },
    incomingChecked: {
      type: Array,
      required: false,
      default: () => [],
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowNewTags: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: 'martech-medium',
    },
    mapLabel: {
      type: [ Function, Object ],
      required: false,
      default: (map) => map,
    },
    hideLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  data() {
    return {
      focused: false,
      dropdownVisible: false,
      tabKeyPressed: false,
      closeSelectHandle: null,
      checked: [],
      inputValue: '',
      appliedChips: [],
      appliedValue: [],
      focusIndex: 0,
      inputIsRequired: false,
      hovered: false,
    };
  },
  computed: {
    inputSize() {
      return [ 'martech-large', 'martech-small' ].includes(this.size) ? this.size : 'martech-medium';
    },
    activeOptionIndex() {
      return this.optionValues.findIndex(
        x => x.value === this.modelValue || x === this.modelValue
      );
    },
    activeDescendant() {
      // returns active option to aria
      return `${this.id}-option-${this.activeOptionIndex}`;
    },
    optionValues() {
      if (this.options instanceof Map) return [...this.options.values()];
      return Object.values(this.options);
    },
    optionsMap() {
      if (this.options instanceof Map) return this.options;
      return new Map(Object.entries(this.options));
    },
    filteredOptions() {
      const filtered = {};
      const options = this.options instanceof Map ? Object.fromEntries(this.options) : this.options;
      const keys = Object.keys(options);

      keys.forEach((key, i) => {
        if (options[key] && options[key].toString().toLowerCase().includes(this.inputValue.toLowerCase())) {
          filtered[key] = options[key];
        }
      });

      return filtered;
    },
  },
  mounted() {
    if (!this.checked.length) {
      this.checked = this.incomingChecked || this.appliedChips;
    }
  },
  unmounted() {
    document.removeEventListener('keydown', this.onEnter);
  },
  beforeUpdate() {
    this.appliedChips = this.incomingChips;
    this.appliedValue = this.modelValue;
    this.checked = this.incomingChecked && this.appliedChips;
  },
  methods: {
    optionLabel(option) {
      if (typeof this.mapLabel === 'function') {
        return this.mapLabel(option);
      }
      
      const mapLabel = this.mapLabel instanceof Map ? Object.fromEntries(this.mapLabel) : this.mapLabel;

      return delve(mapLabel, `${option}`, option);
    },
    inputFocus() {
      this.focused = true;
      this.$refs.select?.focus();
    },
    onBlur() {
      this.focused = false;
      this.$emit('blur');

      // If input is empty on blur & required, highlight input
      if (this.required) {
        this.inputIsRequired = (!this.modelValue || !this.modelValue.length > 0);
      }
    },
    onEnter(ev) {
      if (ev.keyCode !== 13) return false;

      let val;
      const pos = Object.values(this.filteredOptions).map(v => v.toLowerCase()).indexOf(this.inputValue);

      if (!Object.values(this.filteredOptions)[pos]) {
        if (!this.allowNewTags) {
          return null;
        }

        val = this.inputValue;
      }

      if (pos > -1) {
        val = Object.values(this.filteredOptions)[pos];
      } else if (Object.keys(this.filteredOptions).includes(this.inputValue)) {
        val = this.filteredOptions[this.inputValue];
      }

      if (!val) {
        return false;
      }

      this.$emit('on-change', val);
      this.checked = this.appliedChips;
      this.inputValue = '';
      this.inputIsRequired = false;

      return true;
    },
    setOptionFocus() {
      if (this.modelValue) return;
      this.$emit('change', this.optionValues[0]);
    },
    handleOptionClick(key, option) {
      this.selectedIndex = key;
      this.inputIsRequired = false;
      this.onChange(key, option);
      this.resetOptions();
    },
    toggleDropdown() {
      return this.dropdownVisible ? this.hideOptions() : this.showOptions();
    },
    cancelCloseSelect() {
      if (this.closeSelectHandle) {
        window.clearTimeout(this.closeSelectHandle);
      }
    },
    closeSelect() {
      this.closeSelectHandle = window.setTimeout(() => {
        this.dropdownVisible = false;
        this.focused = false;
        this.closeSelectHandle = null;
      }, 300);
    },
    async showOptions() {
      this.dropdownVisible = true;
      await this.$nextTick();
      this.inputFocus();
    },
    hideOptions() {
      this.dropdownVisible = false;
    },
    async resetOptions() {
      this.hideOptions();
      await this.$nextTick();
      if (this.$refs.selectButton) this.$refs.selectButton.focus();
    },
    labelClick(ev) {
      const option = delve(ev, 'target.innerText').trim();
      if (!option) {
        return;
      }

      this.clickCheckbox(option);
    },
    updateValue(event) {
      this.inputValue = '';
      this.inputIsRequired = false;
      this.onChange(this.checked);
      this.$emit('change', this.checked);
      this.$emit('update:modelValue', this.checked);
    },
    clickCheckbox(option) {
      const checkbox = document.getElementById(`select-${option}`);
      this.inputValue = '';
      this.inputIsRequired = false;

      if (checkbox) {
        checkbox.click();
      }
    },
    clearAll() {
      // Clears all applied chips, checkboxes, and values and hides dropdown.
      this.checked = [];
      this.appliedChips.length = 0;
      this.appliedValue.length = 0;
      this.inputValue = '';
      this.hideOptions();
    },
    closeChip(chip) {
      // Closes chip and clears matching checkbox
      const cb = delve(this.$refs, `checkbox-${chip}.0`, null);
      if (cb) {
        cb.checked = false;
      }
      for (let i = 0; i < this.appliedChips.length; i++) {
        if (this.appliedChips[i] === chip) {
          this.appliedChips = this.appliedChips.splice(i, 1);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.martech-default-select {
  width: 100%;
  position: relative;

  .martech-input-label {
    color: $martech-text-subdued;
    font-family: $martech-display-inter;
    font-weight: $martech-weight-semibold;
    margin-bottom: $martech-spacer-1;
    transition: color 300ms ease-in-out;

    &.is-hovered {
      color: $martech-text-primary;
    }

    .label--is-error {
      color: $martech-system-danger-title;
    }

    .asterisk {
      color: $martech-system-danger;
      padding-left: $martech-spacer-1;
    }

    .required-marker {
      width: 12px;
      height: 12px;
    }
  }

  .select-message {
    padding: $martech-spacer-2 0 0 $martech-spacer-3;

    p {
      color: $martech-text-primary;
      font-size: $martech-detail;
      letter-spacing: 0.5px;
    }
  }

  .t-select {
    border: 1px solid $martech-border;
    display: flex;
    flex: 1 1 auto;
    font-size: $martech-body;
    text-align: left;
    position: relative;
    transition: border 100ms ease-in;
    border-radius: $martech-radius-medium;
    background-color: $martech-surface;
    height: 40px;

    &:hover {
      box-shadow: 0 0 2px 3px #eff0f1;
    }

    &.select--is-disabled {
      box-shadow: none;
      cursor: default;

      .select-control {
        .select-slot {
          cursor: default;

          .select-wrap {
            input {
              cursor: default;
            }
          }
        }
      }
    }

    &.select--is-focused {
      border: 1px solid $martech-blue-focus;
    }

    &.select--is-active {
      .icon {
        transform: rotate(180deg);
      }
    }

    &.select--is-required,
    &.select--is-error {
      border: 1px solid $martech-system-danger-border;
    }
  }

  .select-control {
    position: relative;
    color: inherit;
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: wrap;
    min-width: 0;
    width: 100%;

    .select-slot {
      padding: 0 $martech-spacer-3;
      cursor: pointer;
      background: transparent;
      align-items: stretch;
      border-radius: inherit;
      display: flex;
      color: inherit;
      position: relative;
      width: 100%;

      &.martech-large {
        height: 48px;
      }

      &.martech-medium {
        height: 40px;
      }

      &.martech-small {
        height: 32px;
      }

      &.search {
        padding: $martech-spacer-2;
      }
    }

    .select-wrap {
      position: relative;
      display: flex;
      flex: 1 1 auto;
      align-items: center;

      .default-select {
        width: 100%;
      }

      input {
        background: transparent;
        color: $martech-text-primary;
        flex: 1 1;
        position: relative;
        pointer-events: inherit;
        max-height: 48px;
        padding: 8px 0;
        line-height: 20px;
        border-radius: 0;
        border: transparent;
        width: 100%;
        text-transform: capitalize;
        cursor: pointer;
        z-index: 1;
        overflow: hidden;
        text-overflow: ellipsis;

        &:focus-visible {
          outline: none;
        }
      }

      .icon-group {
        display: flex;
        align-items: center;
        .icon {
          margin-right: $martech-spacer-2;
        }
      }

      .icon {
        margin-left: 15px;
        transition: transform 200ms ease-in-out;
        width: 8px;
      }
    }
  }

  .clear-tags {
    background-color: transparent;
  }

  .dropdown-menu {
    margin: 0;
    list-style-type: none;
    outline: none;
    background-color: $martech-surface;
    position: absolute;
    z-index: 120;
    width: 100%;
    margin-top: 5px;
    border-radius: 16px;
    overflow: hidden;
    -webkit-box-shadow: rgb(100 100 115 / 20%) 0 2px 15px 0;
    box-shadow: 0 2px 15px 0 rgb(100 100 115 / 20%);
    padding: $martech-spacer-2;
    min-width: 200px;

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
      border-radius: 8px;
      background-color: $martech-surface;
    }

    &::-webkit-scrollbar {
      width: 12px;
      background-color: $martech-surface;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
      background-color: $martech-surface-alt;
    }

    .dropdown-option {
      width: 100%;
      padding: $martech-spacer-2 $martech-spacer-3;
      margin-bottom: 0.25rem;
      cursor: pointer;
      color: #000;
      border-radius: $martech-radius-medium;
      border: 1px solid transparent;
      font-size: $martech-type-14;
      font-weight: 400;
      line-height: 22px;

      label {
        cursor: pointer;
        input {
          cursor: pointer;
        }
      }

      @include breakpoint(1024) {
        font-size: $martech-body;
        line-height: 24px;
      }

      &:hover {
        border: 1px solid $martech-blue-hover;
      }

      &.is-active {
        background: rgba(227, 227, 255, 0.3);
      }

      &.is-active {
        background-color: $martech-blue-5;
      }
    }
  }
}

.martech-large {
  .chips {
    height: 40px;
  }
}
.martech-medium {
  .chips {
    height: 32px;
  }
}
.martech-small {
  .chips {
    height: 24px;
  }
}
.chips {
  overflow: hidden;
  width: 100%;

  :deep(.chip-wrapper) {
    white-space: nowrap;
    float: left;
  }
}

.input-message {
  padding: $martech-spacer-2 0 0;

  p {
    color: $martech-text-primary;
    font-size: $martech-body;
    letter-spacing: 0.5px;

    &.is-error {
      color: $martech-system-danger-title;
    }
  }
}

.select-slide-enter-active {
  transition: all 100ms ease-in-out;
}
.select-slide-enter,
.select-slide-leave-to {
  transform: translateY(-30px);
  opacity: 0;
  max-height: 0;
}
</style>
