<template>
  <div class="overflow-menu-dropdown">
    <button class="more-button"
      :class="{'is-active' : dropdownOpen}" 
      @mouseenter="dropdownOpen = true"
      @click="dropdownOpen = !dropdownOpen">
      <ellipsis-icon/>
    </button>
    
    <transition name="slide">
      <div class="menu-dropdown"
        @mouseleave="dropdownOpen = false"
        v-show="dropdownOpen"
        ref="dropdown">
        <ul>
          <li v-for="(item, index) in items" :key="index">
            <base-link :link-url="item.url">
              {{ item.text }}
            </base-link>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import BaseLink from '@/components/elements/BaseLink.vue';
import EllipsisIcon from '@/components/iconography/EllipsisIcon.vue';

defineProps({
  items: {
    type: [Array, Object],
    required: true,
  },
});

const dropdownOpen = ref(false);
const dropdown = ref(null);

defineExpose({ 
  dropdown
});

onClickOutside(dropdown, () => {
  dropdownOpen.value = false;
});
</script>

<style lang="scss" scoped>
  .overflow-menu-dropdown {
  position: relative;

  button {
    background: $martech-white;
    padding: $martech-spacer-1 $martech-spacer-1;
    color: $martech-text-primary;
    border: 1px solid $martech-white;
    border-radius: $martech-radius-medium;
    display: flex;
    align-items: center;
    transition: all 100ms ease-in-out;

    &:hover {
      border: 1px solid $martech-border;
    }

    &.is-active {
      background: $martech-background;
      border: 1px solid $martech-border;
    }
  }

  .menu-dropdown {
    background-color: $martech-white;
    border-radius: $martech-radius-medium;
    position: absolute;
    top: 30px;
    left: -35px;
    z-index: 20;
    width: 262px;
    padding: $martech-spacer-2;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    ul {
      list-style: none;
      width: 100%;
      margin: 0;

      li {
        width: 100%;

        :deep(.martech-base-link) {
          display: block;
          font-size: $martech-type-14;
          padding: 0 $martech-spacer-2;
          display: flex;
          align-items: center;
          height: 34px;
          letter-spacing: 0.4px;
          color: $martech-gray-160;
          text-transform: capitalize;
          font-weight: $martech-weight-normal;
          width: 100%;

          &:hover {
            background: rgba(207, 229, 255, 0.5);
            border-radius: $martech-radius-default;
          }
        }
      }
    }
  }
}

.slide-enter-active {
  transition: all 100ms ease-in-out;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(-30px);
  opacity: 0;
  max-height: 0;
}
</style>