<template>
  <!--eslint-disable-next-line max-len-->
  <svg xmlns="http://www.w3.org/2000/svg" class="martech-gear" viewBox="0 0 29.68 31" :data-testid="componentID()"><path d="M29.65 20.11A15.61 15.61 0 0126.23 26a.75.75 0 01-.93.14l-2.66-1.53a12.32 12.32 0 01-3.8 2.19v3.07a.76.76 0 01-.59.74 15.84 15.84 0 01-6.82 0 .76.76 0 01-.59-.74v-3.05A11.93 11.93 0 017 24.63l-2.62 1.53a.75.75 0 01-.93-.16A15.77 15.77 0 010 20.11a.76.76 0 01.35-.88L3 17.69a11.94 11.94 0 010-4.38L.38 11.77a.76.76 0 01-.38-.88A15.61 15.61 0 013.45 5a.75.75 0 01.93-.14L7 6.38a12.38 12.38 0 013.8-2.2V1.11a.75.75 0 01.59-.73 15.58 15.58 0 016.83 0 .74.74 0 01.58.73v3.07a11.54 11.54 0 013.8 2.19l2.7-1.54a.75.75 0 01.92.15 15.36 15.36 0 013.42 5.91.75.75 0 01-.34.87l-2.67 1.54a12 12 0 010 4.39l2.67 1.54a.78.78 0 01.35.88zm-9.82-4.62a5 5 0 10-5 5 5 5 0 005-5z" fill="rgb(0,0,0)"/></svg>
</template>

<script>
import componentId from '@/mixins/componentId';

export default {
  name: 'gear-icon',
  setup() {
    const componentID = componentId;
    return { componentID };
  },
};
</script>

<style lang="scss" scoped>
.martech-gear {
    width: 15px;
    height: 16px;
}
</style>
