<template>
  <div class="screen-overlay" :data-testid="componentID()" @click="closeOverlay"/>
</template>

<script>
import componentId from '@/mixins/componentId';

export default {
  name: 'screen-overlay',
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  methods: {
    closeOverlay(close) {
      this.$emit('close-overlay');
    },
  },
};
</script>

<style lang="scss" scoped>
.screen-overlay {
    position: fixed;
    transition: all 1s ease-in-out;
    background: $martech-black;
    height: 100vh;
    opacity: 0.75;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
}
</style>
