<template>
  <base-styles>
    <div v-clickOutside="closeDropdown" class="multi-opt-button" :data-testid="componentID()">
      <div class="multi-opt-button__button martech-button" :class="[buttonSize, buttonColor, {'is-active' : show}]" @click="show = !show">
        {{ buttonLabel }}
      </div>
      <transition name="slide">
        <div v-show="show" class="multi-opt-button__options">
          <div v-for="(option, optKey) in options" :key="option" class="multi-opt-button__option" @click="$emit('option-clicked', optKey, option, show = false)">
            {{ option }}
          </div>
        </div>
      </transition>
    </div>
  </base-styles>
</template>

<script>
import BaseStyles from '@/components/BaseStyles.vue';
import buttons from '@/lib/buttons';
import componentId from '@/mixins/componentId';
import clickOutside from '@/directives/clickOutside';

export default {
  name: 'multi-option-button',
  components: {
    BaseStyles,
  },
  directives: {
    clickOutside,
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    buttonLabel: {
      type: String,
      required: true,
      default: 'Button',
    },
    btnStyle: {
      type: String,
      default: 'martech-black-outline',
      required: false,
    },
    btnSize: {
      type: String,
      default: 'martech-medium',
      required: false,
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    buttonSize() {
      return buttons.buttonSizes(this.btnSize);
    },
    buttonColor() {
      return buttons.buttonColors(this.btnStyle);
    },
  },
  methods: {
    closeDropdown() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.multi-opt-button {
  position: relative;
  &__button {
    .martech-chevron-solid {
      width: 8px;
      margin-left: $martech-spacer-2;
      transition: transform 200ms ease-in-out;
    }

    &.is-active {
      .martech-chevron-solid {
        transform: rotate(180deg);
      }
    }
  }

  &__options {
    margin: 0;
    list-style-type: none;
    outline: none;
    background-color: $martech-surface;
    position: absolute;
    z-index: 1000;
    margin-top: 5px;
    border-radius: 16px;
    overflow: hidden;
    -webkit-box-shadow: rgb(100 100 115 / 20%) 0 2px 15px 0;
    box-shadow: 0 2px 15px 0 rgb(100 100 115 / 20%);
    padding: $martech-spacer-2;
    font-family: $martech-display-inter;
    width: 100%;

    @include breakpoint(768) {
      min-width: 200px;
    }
  }

  &__option {
    padding: $martech-spacer-1 $martech-spacer-2;
    margin-bottom: 0.25rem;
    cursor: pointer;
    color: #000;
    border-radius: $martech-radius-medium;
    border: 1px solid transparent;
    font-size: $martech-type-14;
    font-weight: 400;
    line-height: 22px;

    @include breakpoint(1024) {
      font-size: $martech-type-12;
    }

    &:hover {
      border: 1px solid $martech-blue-hover;
    }

    &:active {
      background-color: $martech-blue-0;
    }
  }
}

.slide-enter-active {
  transition: all 100ms ease-in-out;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-30px);
  opacity: 0;
  max-height: 0;
}
</style>
