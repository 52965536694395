<template>
  <div class="social-share" :data-testid="componentID()">
    <a href="#" v-if="webShareAvailable" @click.prevent="webShare" class="web-share">
      <img :src="webShareImg" alt="Share" />
    </a>
    <base-link :link-url="facebookShareUrl + addUtm(siteUrl, 'facebook')" url-target="_blank" new-window
      @click="log('Facebook')">
      <img :src="facebook" class="fb-icon" :alt="`Share ${placement} on Facebook`">
    </base-link>
    <base-link :link-url="twitterShareUrl + getTitle() + '%0A' + addUtm(siteUrl, 'twitter')" url-target="_blank"
      new-window @click="log('Twitter')">
      <img :src="twitter" class="twitter-icon" :alt="`Share ${placement} on Twitter`">
    </base-link>
    <div class="copy-to-clipboard" tabindex="-1">
      <a class="martech-text-sm" tabindex="0" href="javascript:void(0)" @click="copyUrl" @keyup.enter="copyUrl">
        <img :src="copy" class="copy-link" :alt="`Copy ${placement} Link`"> Copy Link
      </a>
      <div class="copy-alerts">
        <span v-if="copied" class="copy-pill copy-pill-success">Link Copied</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { get, set } from '@vueuse/core';
import BaseLink from '@/components/elements/BaseLink.vue';
import clipboard from '@/lib/clipboard';
import useComponentId from '@/use/useComponentId';
import useDeviceType from '@/use/deviceType';
import amplitudeEvent from '@tcgplayer/amplitude';

const props = defineProps({
  title: {
    type: String,
    default: 'Check Out this Article from TCGplayer Infinite! ',
  },
  author: {
    type: String,
    default: '',
  },
  vertical: {
    type: String,
    default: 'no_vert',
  },
  placement: {
    type: String,
    default: 'Article',
  },
  canonicalUrl: {
    type: String,
    default: '',
  },
});

const { componentID } = useComponentId();
const { isMobile } = useDeviceType();

let siteUrl;
if (props.canonicalUrl.startsWith('http')) {
  siteUrl = props.canonicalUrl;
} else if (props.canonicalUrl) {
  siteUrl = `${process.env.VUE_APP_BASE_URL}${props.canonicalUrl}`
} else {
  siteUrl = window?.location?.href || '';
}

// This will strip off any query parameters, even internal.  We would currently only have parameters
// for utm_* and such which would get stripped off and then our share links would have new utm_* values
// appended
const url = new URL(siteUrl);
siteUrl = `${url.protocol}//${url.host}${url.pathname}`;

const webShareImg = 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/webshare-icon.svg';
const twitter = 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/twitter-gray.svg';
const twitterShareUrl = 'https://twitter.com/intent/tweet?text=';
const facebook = 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/facebook-gray.svg';
const facebookShareUrl = 'https://www.facebook.com/dialog/share?app_id=136270296395166&display=popup&href=';
const copy = 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/copy-link-gray.svg';
const copied = ref(null);

const webShareAvailable = computed(() => isMobile && !!navigator?.share);

const logged = ref([]);

function log(method) {
  if (get(logged).includes(method)) return;
  logged.value.push(method);

  amplitudeEvent('martech', 'socialShareClicked', {
    method,
    title: props.title || '',
    author: props.author || '',
    vertical: props.vertical || '',
    placement: props.placement || '',
    url: siteUrl,
  });
}

function addUtm(link, source, decode) {
  if (typeof link !== 'string') return link;

  const sep = link.includes('?') ? '&' : '?';
  const query = `${sep}utm_source=${source}&utm_campaign=infinite&utm_medium=social&utm_content=${props.vertical.toLowerCase()}`;

  return decode
    ? `${link}${query}`
    : `${link}${encodeURIComponent(query)}`;
}

function getTitle(encode = true) {
  if (!props.title) return '';

  let title = props.title;
  if (props.author) title += ` by ${props.author}`;

  return encode ? encodeURIComponent(title) : title;
}

function copyUrl() {
  clipboard.Copy(addUtm(siteUrl, 'clipboard', true));
  set(copied, true);
  setTimeout(() => { set(copied, false); }, 2000);
  log('Copy to Clipboard');
}

function webShare() {
  try {
    navigator.share({
      title: getTitle(false),
      text: '',
      url: siteUrl,
    });
    log('Web Share API');
  } catch (err) {
    /* Fail silently for now */
    console.log('Web Share Error', err);
  }
}

</script>

<style lang="scss" scoped>
.social-share {
  display: flex;
  align-items: center;

  :deep(a) {
    margin-right: $martech-spacer-3;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: $martech-gray-120;
    cursor: pointer;
  }

  .web-share {
    margin-right: $martech-spacer-3;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: $martech-gray-120;
    cursor: pointer;

    img {
      height: 16px;
    }
  }

  .fb-icon {
    height: 16px;
  }

  .twitter-icon {
    height: 14px;
  }

  .copy-link {
    height: 14px;
    margin-right: $martech-spacer-2;
  }

  .copy-to-clipboard {
    display: flex;
    align-items: center;
    position: relative;

    a {
      font-size: $martech-type-12;
      font-weight: $martech-weight-normal;
    }
  }

  .copy-alerts {
    position: absolute;
    right: -20px;
    min-width: 110px;
    margin-top: 60px;
  }

  .copy-pill-success {
    animation: seconds 1s forwards;
    animation-iteration-count: 1;
    animation-delay: 0.5s;
    color: $martech-text-primary;
    background: $martech-white;
    border-radius: $martech-radius-default;
    height: 32px;
    width: 100%;
    padding: $martech-spacer-2;
    position: relative;
    font-size: $martech-type-12;
    z-index: 100;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08), 0 5px 10px 0 rgba(0, 0, 0, 0.1);

    &::before {
      content: "\A";
      position: absolute;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid $martech-white;
      left: 35px;
      top: -6px;
    }
  }

  @keyframes seconds {
    0% {
      opacity: 1;
      height: 100%;
    }

    100% {
      opacity: 0;
      height: 0;
    }
  }
}
</style>
