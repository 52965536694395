<template>
  <div class="sub-navigation" ref="nav">
    <tcg-input-select
      v-if="isMobile"
      v-model="selectedNavItem"
      :input-id="id"
      :items="links"
      :label="label"
      :horizontal-label="label !== ''"
      :smart-position="true"
      :item-text="'text'"
      :item-value="'url'"
      :full-width="true"/>
    <template v-else>
      <ul>
        <li v-for="(link, index) in visibleLinks" :key="index" :class="{'is-active' : links.includes(link.url)}">
          <base-link :link-url="link.url">
            {{ link.text }}
          </base-link>
        </li>
      </ul>

      <overflow-menu-dropdown v-if="truncate" :items="dropdownLinks" ref="dropdownParent"/>
    </template>

  </div>
</template>

<script setup>
import { computed, ref, defineEmits, watch, onMounted} from 'vue';
import { useResizeObserver, get } from '@vueuse/core';
import useDeviceType from '@/use/deviceType';
import BaseLink from '@/components/elements/BaseLink.vue';
import OverflowMenuDropdown from '@/components/elements/OverflowMenuDropdown.vue';
import TcgInputSelect from '@tcgplayer/design-system/src/components/InputSelect/InputSelect.vue';

const props = defineProps({
  links: { 
    type: Array, 
    required: true,
    default: () => [],
  },
  truncate: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    required: false,
    default: '',
  },
  selected: {
    type: String,
    required: false,
    default: '',
  },
  label: {
    type: String,
    reqired: false,
    default: '',
  }
});

const emit = defineEmits([
  'push', 'selected' ]);

const { isMobile, isDesktop } = useDeviceType();

const nav = ref();
const navWidth = ref();
const selectedNavItem = ref(props.selected);

const dropdownParent = ref(null);

useResizeObserver(nav, (entries) => {
  const entry = entries[0]
  const { width } = entry.contentRect

  navWidth.value = width;
});

const truncateCount = computed(() => {
  if (navWidth.value > 1400) {;
    return 10;
  } else if(navWidth.value > 1024) {
    return 7;
  } else if (navWidth.value < 1024) {
    return 5;
  }

  return 8;
});

onMounted(() => {
  useResizeObserver(nav, (entries) => {
    if(entries[0] && isDesktop) {
      if (get(navWidth) >= 2000) {
        get(dropdownParent).dropdown.style.left = '-35px';
        get(dropdownParent).dropdown.style.right = 'auto';
      } else if (get(navWidth) <= 1999) {
        get(dropdownParent).dropdown.style.right = '-35px';
        get(dropdownParent).dropdown.style.left = 'auto';
      }
    }
  });
})

const visibleLinks = computed(() => {
  return props.truncate ? props.links.slice(0, truncateCount.value) : props.links;
});

const dropdownLinks = computed(() => {
  return props.links.slice(truncateCount.value);
});

watch(selectedNavItem, () => {
  if(get(isMobile)) {
    emit('update:selected', get(selectedNavItem));
  }
});

watch(
  () => props.selected,
  (newValue) => {
    if (newValue) {
      selectedNavItem.value = newValue;
    }
  }
);
</script>

<style lang="scss" scoped>  
.sub-navigation {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 10;

  :deep(.tcg-input) {
    width: 100%;

    ul {
      width: 100%;

      li {
        text-transform: capitalize;
      }
    }

    .tcg-input-select__trigger {
      span {
        text-transform: capitalize;
      }
    }
  }

  ul {
    list-style: none;
    display: inline-flex;
    flex-wrap: wrap;

    li {
      padding-right: $martech-spacer-3;
      text-transform: capitalize;

      :deep(.martech-base-link) {
        color: $martech-text-primary;
        font-size: $martech-type-14;

        &.router-link-exact-active,
        &.is-active {
          color: $martech-blue;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>