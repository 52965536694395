<template>
  <!--eslint-disable-next-line-->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" class="martech-success" :data-testid="componentID()"><path d="M3 28a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h22a3 3 0 0 1 3 3v22a3 3 0 0 1-3 3Zm20.7-17.6a1 1 0 0 0 0-1.4l-1.4-1.5a1 1 0 0 0-1.4 0L11.5 17l-4.4-4.4a1 1 0 0 0-1.4 0L4.3 14a1 1 0 0 0 0 1.4l6.5 6.5a1 1 0 0 0 1.4 0Z" data-name="Layer 1" fill="currentColor"/>
  </svg>
</template>]

<script>
import componentId from '@/mixins/componentId';

export default {
  name: 'success-icon',
  setup() {
    const componentID = componentId;
    return { componentID };
  },
};
</script>
